/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Controller } from 'react-hook-form';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface RadioGroupProps {
  options: { value: string; label: string }[];
  name: string;
  control: any;
  defaultValue?: string[];
}

const CustomRadioGroup: FC<RadioGroupProps> = ({ options, name, control, defaultValue }) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup {...field} className="mt-2">
            <div className="grid grid-cols-3 gap-3 sm:grid-cols-7">
              {options.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option.value}
                  className={({ active, checked }) =>
                    classNames(
                      'cursor-pointer focus:outline-none',
                      active ? 'ring-2 ring-primaryDark ring-offset-2' : '',
                      checked
                        ? 'bg-primaryDark text-white hover:bg-primary'
                        : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                      'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1',
                    )
                  }
                >
                  <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        )}
      />
    </div>
  );
};

export default CustomRadioGroup;

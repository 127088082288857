import api from '../../../config/api/api.config';

const createBookingScheduleApi = {
  getMerchantResources: () => api.get('merchant_resources'),
  createBookingSchedule: (data: any) => api.post('merchant_booking_schedule', data),
  getBookingSchedules: () => api.get(`merchant_booking_schedules`),
  getMerchantBookingSchedule: (merchantId: number) => api.get(`merchant_booking_schedule/${merchantId}`),
  updateMerchantBookingSchedule: (data: any) => api.put(`merchant_booking_schedule`, data),
  bulkAddBookingScheduleSchedules: (scheduleId: number, payload: any) =>
    api.post(`merchant_booking_schedule/${scheduleId}/schedules`, payload),
  bulkDeleteBookingScheduleSchedules: (payload: any) =>
    api.delete(`merchant_booking_schedule_schedule_map`, { data: payload }),
};

export default createBookingScheduleApi;

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import session from '../session/session.config';
import { serverUrl } from '../variables/system.variable';

const BaseUrl = serverUrl;

let apiOptions: AxiosRequestConfig<unknown> = {};

function updateOptions(options: AxiosRequestConfig<unknown>) {
  return {
    ...apiOptions,
    ...options,
    headers: {
      ...apiOptions.headers,
      ...options.headers,
      // 'x-access-token': xAccessToken,
      // 'x-user-id': xUserId,
      // 'x-username': xUsername,
      // ts: new Date().toISOString(),
    },
  } as AxiosRequestConfig;
}

function getApiUrl(url: string, apiUrl: string | boolean = false) {
  if (apiUrl) return `${apiUrl}/${url}`;
  return `${BaseUrl}/api/${url}`;
}

function errorHandler(error: { response: AxiosResponse }) {
  if (error.response.status === 401) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    api.removeAuthenticationHeader();
    session.destroySession();
    window.location.href = '/404';
  }
  if (error.response) return error.response;
  return error;
}

const api = {
  get: (url: string, options: any = {}, apiUrl: string | boolean = false) =>
    axios.get(getApiUrl(url, apiUrl), updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  post: (url: string, payload: unknown = {}, options: any = {}, apiUrl: string | boolean = false) =>
    axios.post(getApiUrl(url, apiUrl), payload, updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  put: (url: string, payload = {}, options: any = {}, apiUrl: string | boolean = false) =>
    axios.put(getApiUrl(url, apiUrl), payload, updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  delete: (url: string, options: any = {}, apiUrl: string | boolean = false) =>
    axios.delete(getApiUrl(url, apiUrl), updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  setAuthenticationHeader: (tokenData: { token: string; id: string; apiKey: string }) => {
    apiOptions = {
      headers: {
        'x-access-token': tokenData.token,
        'x-user-id': tokenData.id,
        'x-api-key': tokenData.apiKey || process.env.REACT_APP_X_API_KEY || '',
        // 'x-username': tokenData.username,
      },
    };
  },
  removeAuthenticationHeader: () => {
    apiOptions = {};
  },
};

export type TApiResponse<T = undefined> = Promise<
  AxiosResponse<{
    data: T;
    action: string;
    status: 'successful' | 'failed';
    message?: Partial<string>;
  }>
>;

export type TApiCustomResponse<T = undefined> = Promise<AxiosResponse<T>>;

export default api;

import api, { TApiResponse } from '../../config/api/api.config';
import { TCustomerAddress, TMenuCategory } from '../common/types';

export type TAddAddressPayload = {
  customer_id: number | undefined;
  address_line_1: string;
  address_line_2: string;
  address_type_id: number;
  city_id: number;
  latitude: string;
  longitude: string;
  delivery_instructions: string;
};

const orderTypeApi = {
  getBranchMenu: (branchId: number): TApiResponse<TMenuCategory[]> => api.get(`branch/${branchId}/menu`),
  getAddresses: (
    customerId: number,
  ): TApiResponse<{
    customer_address_list: TCustomerAddress[];
  }> => api.get(`customer/${customerId}/customer_addresses`),
  deleteAddress: (customerAddressId: number) => api.delete(`customer_address/${customerAddressId}`),
  addAddress: (payload: TAddAddressPayload) => api.post(`customer_address`, payload),
  updateAddress: (customerAddressId: any, payload: TAddAddressPayload) =>
    api.put(`customer_address/${customerAddressId}`, payload),
};

export default orderTypeApi;

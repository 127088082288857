import api, { TApiCustomResponse } from '../../config/api/api.config';
import { TOrderTicket, TPaymentMethod } from '../common/types';

export type TGeneratePaymentLink = {
  version: string;
  test_p: number | 1 | 0;
  direct_p: number | 1 | 0;
  external_reference_code: string | null;
  remote_transaction_id: string | null;
  merchant_code: string;
  settings: {
    redirect_url_success: string | null;
    redirect_url_error: string | null;
    payment_link_expiry_duration: null;
    expiry_duration_measurement_name: string | null;
  };
  payment_details: {
    payable_amount: number;
    tax_amount: number;
    tip_amount: number;
    discount_amount: number;
    currency_name: string;
    payment_method_name: string;
  };
  customer_details: {
    customer_id?: string | number;
    external_customer_id: null;
    first_name_en: string | null;
    last_name_en: string | null;
    email: string | null;
    phone_nr: string | null;
    customer_contact_method: 'link' | null;
  };
  customer_address_details?: {
    customer_address_id: string;
  };
  employee_details: {
    external_employee_id: string | null;
    first_name_en: string | null;
    last_name_en: string | null;
    email: string | null;
    phone_nr: string | null;
  };
  item_list: any[];
  offer_list: any[];
};

type TGeneratePaymentLinkResponse = {
  merchant_transaction_id: number;
  merchant_transaction_code: string;
  service_provider_name: string;
  service_provider_url: string | null;
  stripe_intent_id: number | null;
  b2binpay_invoice_id: number | null;
  telr_transaction_request_id: number | null;
  payment_url: string;
  status: 'successful' | 'failed';
  action: 'create_generic_transaction_request';
};

const checkoutApi = {
  getPaymentMethods: (branchId: number): TApiCustomResponse<{ payload: TPaymentMethod[] }> =>
    api.get(`payment_methods`, { params: { branch_id: branchId, order_placement_channel_id: 4 } }),
  generatePaymentLink: (payload: TGeneratePaymentLink): TApiCustomResponse<TGeneratePaymentLinkResponse> =>
    api.post('transaction-request', payload),
  validateOrder: (orderTicket: TOrderTicket) => api.post(`calculate-order`, orderTicket),
  placeOrder: (orderTicket: TOrderTicket) => api.post(`customer-order`, orderTicket),
};

export default checkoutApi;

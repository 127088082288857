import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../../modules';
import { RecommendationModal } from '../../../modules/brand-main/components';
import { ThemeContext } from '../../../contexts/theme.context';

const IBlinkMarketplaceRoutes = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Header />
      <div
        className="h-[90vh] sm:h-[93vh] overflow-y-auto"
        style={{
          color: theme?.general?.textColor || '',
          backgroundColor: theme?.general?.backgroundColor || '',
        }}
      >
        <RecommendationModal />
        <Outlet />
      </div>
    </>
  );
};

export default IBlinkMarketplaceRoutes;

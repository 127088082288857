/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Modal, message } from 'antd';
import { TMerchantBookingSchedule } from '../merchant.type';
import bookingScheduleListingApi from './booking-schedule-listing.api';
import { DataLoadingOverlay } from '../../common/components';

const BookingScheduleListing = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [bookingSchedules, setBookingSchedules] = useState<TMerchantBookingSchedule[]>([]);

  const fetchBookingSchedules = useQuery(
    'fetchBookingSchedules',
    () => bookingScheduleListingApi.getBookingSchedules(),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200 && data.data) {
          setBookingSchedules(data.data);
        }
      },
    },
  );

  useEffect(() => {
    fetchBookingSchedules.refetch();
  }, []);

  return (
    <div className="h-fill overflow-y-auto p-5 flex flex-col gap-2">
      <DataLoadingOverlay isLoading={fetchBookingSchedules.isFetching || isLoading} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-4xl font-bold leading-tight tracking-tight text-white">Booking Schedule Listing</h1>
          {/* <p className="mt-2 text-sm text-gray-100"></p> */}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-primaryDark px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryDark"
            onClick={() => navigate('/merchant/create-booking-schedule')}
          >
            Add New
          </button>
        </div>
      </div>
      <div className="h-fill overflow-y-auto">
        <table className="mt-6 w-full whitespace-nowrap text-left">
          <colgroup>
            <col className="" />
            <col className="" />
            <col className="" />
            <col className="" />
            <col className="" />
          </colgroup>
          <thead className="border-b border-white/10 text-sm leading-6 text-white">
            <tr>
              <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                Id
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                Name
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                Status
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8 text-right">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-white/5">
            {bookingSchedules.map((item) => (
              <tr key={item.merchant_booking_schedule_id}>
                <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                  <div className="flex gap-x-3">
                    <div className="font-mono text-sm leading-6 text-gray-400">{item.merchant_booking_schedule_id}</div>
                  </div>
                </td>
                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-x-3">
                    <div className="font-mono text-sm leading-6 text-gray-400">
                      {item.merchant_booking_schedule_name}
                    </div>
                  </div>
                </td>
                {/* TODO: Add booking status */}
                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-x-3">
                    <div className="font-mono text-sm leading-6 text-gray-400">Created</div>
                  </div>
                </td>
                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-1 justify-end">
                    {/* <button
                      type="button"
                      className="inline-flex items-center rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-300 hover:scale-110 shadow-sm disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      onClick={() =>
                        navigate(`/merchant/modify-resource?merchantResourceId=${item.merchant_resource_id}`)
                      }
                    >
                      <PencilSquareIcon width={24} />
                    </button> */}
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-300 shadow-sm hover:scale-110 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      onClick={() =>
                        Modal.confirm({
                          title: 'Booking Schedule',
                          content: 'Are you sure you want to delete booking schedule?',
                          okText: 'Delete',
                          okButtonProps: {
                            danger: true,
                          },
                          onOk: async () => {
                            setIsLoading(true);
                            const { data, status } = await bookingScheduleListingApi.deleteBookingSchedule(
                              item.merchant_booking_schedule_id,
                            );

                            if (status === 200 && data.status === 'successful') {
                              message.success('Booking Schedule deleted successfully');
                              setBookingSchedules((prev) =>
                                prev.filter(
                                  (schedule) =>
                                    schedule.merchant_booking_schedule_id !== item.merchant_booking_schedule_id,
                                ),
                              );
                            } else
                              message.error(
                                data.message ||
                                  'Something went wrong. Please try again after sometime or contact support for help.',
                              );
                            setIsLoading(false);
                          },
                        })
                      }
                    >
                      <TrashIcon width={24} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingScheduleListing;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isEqual,
  isPast,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  startOfDay,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { useQuery } from 'react-query';
import { FieldValues, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { TBookedTimeSlot, TBookingAvailability } from '../../create-booking.type';
import createBookingApi from '../../create-booking.api';
import { TimeSlotRadioGroup } from './components';
import { LoadingOverlayContext } from '../../../../contexts/loading-overlay.context';

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

type TimeSlotRadioGroupProps = {
  control: any;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
};

const TimeSlotCalendar: FC<TimeSlotRadioGroupProps> = ({ control, setValue, watch, trigger }) => {
  const { setLoading } = useContext(LoadingOverlayContext);
  const today = startOfToday();
  const endDate = add(today, { months: 3 });
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
  const [bookingAvailability, setBookAvailability] = useState<TBookingAvailability[]>([]);
  const [bookedTimeSlots, setBookedTimeSlots] = useState<TBookedTimeSlot[]>([]);

  const fetchBookingAvailability = useQuery(
    'bookingAvailability',
    () =>
      createBookingApi.getBookingAvailability({
        merchant_id: 1,
        merchant_resource_id_list: [],
        menu_item_id_list: [],
        start_date: '2023-06-01',
        end_date: format(endDate, 'yyyy-MM-dd'),
        start_time: '00:00',
        end_time: '23:59',
      }),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          if (data.available_booking_list)
            setBookAvailability(
              (data.available_booking_list as TBookingAvailability[]).sort((a, b) => {
                const aTime = new Date(`1970-01-01T${a.schedule.from_time.padStart(8, '0')}Z`);
                const bTime = new Date(`1970-01-01T${b.schedule.from_time.padStart(8, '0')}Z`);
                return aTime.getTime() - bTime.getTime();
              }),
            );
          if (data.already_booked_list) setBookedTimeSlots(data.already_booked_list);
        }
      },
    },
  );

  const days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
  });

  function previousMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  function nextMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  const selectedBookingAvailability =
    selectedDay && endDate > selectedDay
      ? bookingAvailability
          .filter((booking) =>
            booking.schedule.days.length > 1
              ? booking.schedule.days.split(',').includes(`${Number(format(selectedDay, 'i')) - 1}`)
              : booking.schedule.days === `${Number(format(selectedDay, 'i')) - 1}`,
          )
          .map((booking) => ({
            ...booking,
            booked: bookedTimeSlots.some(
              (booked) =>
                isSameDay(new Date(booked.booking_date), selectedDay) &&
                booked.booking_start_time === booking.schedule.from_time,
            ),
          }))
      : [];

  useEffect(() => {
    fetchBookingAvailability.refetch();
    if (watch('selectedDay')) setSelectedDay(startOfDay(new Date(watch('selectedDay'))));
    else setSelectedDay(today);
  }, []);

  useEffect(() => {
    if (selectedDay) {
      setValue('selectedDay', format(selectedDay, 'yyyy-MM-dd'));
      setValue('merchantBookingScheduleId', null);
      setValue('bookingStartTime', null);
      setValue('bookingEndTime', null);
      setValue('scheduleId', null);
      trigger();
    }
  }, [selectedDay]);

  useEffect(() => {
    if (fetchBookingAvailability.isFetching) setLoading(true);
    else setLoading(false);
  }, [fetchBookingAvailability.isFetching]);

  return (
    <div className="">
      <div className="max-w-md px-4 mx-auto sm:px-7 md:max-w-4xl md:px-6 bg-white p-4 rounded-xl">
        {selectedDay && (
          <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200 h-[45vh]">
            <div className="md:pr-14">
              <div className="flex items-center">
                <h2 className="flex-auto font-semibold text-gray-900">{format(firstDayCurrentMonth, 'MMMM yyyy')}</h2>
                <button
                  type="button"
                  onClick={previousMonth}
                  className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <button
                  onClick={nextMonth}
                  type="button"
                  className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
              <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
              <div className="grid grid-cols-7 mt-2 text-sm">
                {days.map((day, dayIdx) => (
                  <div
                    key={day.toString()}
                    className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5')}
                  >
                    <button
                      type="button"
                      onClick={() => setSelectedDay(day)}
                      className={classNames(
                        isEqual(day, selectedDay) && 'text-white',
                        !isEqual(day, selectedDay) && isToday(day) && 'text-primary',
                        !isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          isSameMonth(day, firstDayCurrentMonth) &&
                          'text-gray-900',
                        !isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          !isSameMonth(day, firstDayCurrentMonth) &&
                          'text-gray-400',
                        isEqual(day, selectedDay) && isToday(day) && 'bg-primary',
                        isEqual(day, selectedDay) && !isToday(day) && 'bg-gray-900',
                        !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                        (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                        'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                      )}
                      disabled={isPast(day) && !isToday(day)}
                    >
                      <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                    </button>

                    <div className="flex justify-center mt-1">
                      {bookingAvailability.some(
                        (booking) =>
                          (!isPast(day) || isToday(day)) &&
                          endDate > day &&
                          booking.schedule.days === `${Number(format(day, 'i')) - 1}` &&
                          !bookedTimeSlots.find(
                            (bookedSlot) =>
                              isSameDay(new Date(bookedSlot.booking_date), day) &&
                              bookedSlot.booking_start_time === booking.schedule.from_time,
                          ),
                      ) && <div className="w-1/2 h-1 rounded bg-primary" />}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <section className="mt-12 md:mt-0 md:pl-14 flex flex-col">
              <h2 className="font-semibold text-gray-900 text-center">
                Booking Timeslot for{' '}
                <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>{format(selectedDay, 'MMM dd, yyy')}</time>
              </h2>
              <TimeSlotRadioGroup
                options={selectedBookingAvailability}
                name="scheduleId"
                control={control}
                setValue={setValue}
              />
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

let colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];

export default TimeSlotCalendar;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { TMerchantOrder } from '../../../merchant.type';
import utils from '../../../../common/services/utils.service';
import dashboardApi from '../../dashboard.api';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const OrderListing = () => {
  const [orders, setOrders] = useState<TMerchantOrder[]>([]);
  const statuses = {
    paid: 'text-green-400 bg-green-400/10',
    unpaid: 'text-rose-400 bg-rose-400/10',
    partially_paid: 'text-yellow-400 bg-yellow-400/10',
  };

  const fetchOrders = useQuery('orders', () => dashboardApi.getOrders(1), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        setOrders(data.data);
      }
    },
  });

  useEffect(() => {
    fetchOrders.refetch();
  }, []);

  return (
    <div className="h-1/2 overflow-y-auto">
      <h2 className="px-4 text-lg font-semibold leading-7 text-white sm:px-6 lg:px-8">Latest orders</h2>
      <table className="mt-2 w-full whitespace-nowrap text-left">
        <colgroup>
          <col className="w-full sm:w-4/12" />
          <col className="lg:w-4/12" />
          <col className="lg:w-2/12" />
          <col className="lg:w-1/12" />
          <col className="lg:w-1/12" />
        </colgroup>
        <thead className="border-b border-white/10 text-sm leading-6 text-white">
          <tr>
            <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
              Order Id
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
              External Reference #
            </th>
            <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Amount
            </th>
            <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Payment Status
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Created At
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
              Order Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-white/5">
          {orders.map((item) => (
            <tr key={item.customer_order_id}>
              <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">{item.customer_order_id}</div>
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">{item.external_order_reference_nr}</div>
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">
                    {utils.formatCurrency(item.actual_payable_amount_total, item.currency_name)}
                  </div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                  <div className={classNames(statuses[item.payment_status], 'flex-none rounded-full p-1')}>
                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                  </div>
                  <div className="hidden text-white sm:block">{item.payment_status}</div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                <time dateTime={item.order_creation_timestamp}>{item.order_creation_timestamp}</time>
              </td>
              {/* TODO: Add order status */}
              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">Routed</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderListing;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Modal, message } from 'antd';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import merchantResourceListingApi from './merchant-resource-listing.api';
import { TMerchantResource } from '../merchant.type';
import { DataLoadingOverlay } from '../../common/components';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const MerchantResourceListing = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [merchantResources, setMerchantResources] = useState<TMerchantResource[]>([]);
  const [selectedTab, setSelectedTab] = useState('Tables');
  const [tabs, setTabs] = useState([
    { name: 'Sections', count: 0 },
    { name: 'Tables', count: 0 },
  ]);

  const filteredResources = merchantResources.filter((resource) =>
    selectedTab === 'Tables'
      ? resource.parent_merchant_resource_id !== null
      : resource.parent_merchant_resource_id === null,
  );

  const fetchResources = useQuery('fetchResources', () => merchantResourceListingApi.getMerchantResourceListing(), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200 && data.result_list) {
        setMerchantResources(data.result_list);
        setTabs([
          {
            name: 'Sections',
            count: data.result_list.filter(
              (item: { parent_merchant_resource_id: null }) => item.parent_merchant_resource_id === null,
            ).length,
          },
          {
            name: 'Tables',
            count: data.result_list.filter(
              (item: { parent_merchant_resource_id: null }) => item.parent_merchant_resource_id !== null,
            ).length,
          },
        ]);
      }
    },
  });

  useEffect(() => {
    fetchResources.refetch();
  }, []);

  return (
    <div className="h-fill overflow-y-auto p-5 flex flex-col gap-2">
      <DataLoadingOverlay isLoading={isLoading || fetchResources.isFetching} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-bold text-text mb-2">Resources</h1>
          <p className="text-gray-600 w-full">Manage all your resources here.</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex gap-2">
          {selectedTab === 'Tables' && (
            <button
              type="button"
              className="block rounded-md bg-primaryDark px-3 py-2 text-center text-sm font-semibold text-textLight shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryDark"
              onClick={() => navigate('/merchant/modify-resource?type=table')}
            >
              Add New Table
            </button>
          )}
          {selectedTab === 'Sections' && (
            <button
              type="button"
              className="block rounded-md bg-primaryDark px-3 py-2 text-center text-sm font-semibold text-textLight shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryDark"
              onClick={() => navigate('/merchant/modify-resource?type=section')}
            >
              Add New Section
            </button>
          )}
        </div>
      </div>
      <div className="bg-bgSidebar rounded p-2 px-4 h-fill">
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  className={classNames(
                    selectedTab === tab.name
                      ? 'border-gray-100 text-primary'
                      : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-300',
                    'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
                  )}
                  onClick={() => setSelectedTab(tab.name)}
                >
                  {tab.name}
                  {tab.count ? (
                    <span
                      className={classNames(
                        selectedTab === tab.name ? 'bg-primaryLight text-textLight' : 'bg-gray-100 text-gray-900',
                        'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
                      )}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </div>
              ))}
            </nav>
          </div>
        </div>
        <div className="h-fill overflow-y-auto">
          <table className="mt-6 w-full whitespace-nowrap text-left">
            <colgroup>
              <col className="" />
              <col className="" />
              <col className="" />
              <col className="" />
              <col className="" />
            </colgroup>
            <thead className="border-b border-black/10 text-sm leading-6 text-text">
              <tr>
                <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                  #
                </th>
                <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                  Name
                </th>
                {selectedTab === 'Tables' && (
                  <th scope="col" className="py-2 pl-0 pr-4 font-semibold sm:pr-8 sm:text-left lg:pr-8">
                    Section
                  </th>
                )}
                <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-white/5">
              {filteredResources.map((item, index) => (
                <tr key={item.merchant_resource_id}>
                  <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                    <div className="flex gap-x-3">
                      <div className="font-mono text-sm leading-6 text-textListing">{index + 1}</div>
                    </div>
                  </td>
                  <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div className="flex gap-x-3">
                      <div className="font-mono text-sm leading-6 text-textListing">{item.merchant_resource_name}</div>
                    </div>
                  </td>
                  {selectedTab === 'Tables' && (
                    <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-8">
                      <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                        <div className="hidden text-text sm:block">{item.parent_merchant_resource_name}</div>
                      </div>
                    </td>
                  )}
                  <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div className="flex gap-x-3">
                      <div className="font-mono text-sm leading-6 text-textListing">
                        {item.merchant_resource_status}
                      </div>
                    </div>
                  </td>
                  <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div className="flex gap-1 justify-end">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md px-2.5 py-1.5 text-sm font-semibold text-textListing hover:scale-110 shadow-sm disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                        onClick={() =>
                          navigate(
                            `/merchant/modify-resource?type=${
                              item.parent_merchant_resource_id ? 'table' : 'section'
                            }&merchantResourceId=${item.merchant_resource_id}`,
                          )
                        }
                      >
                        <PencilSquareIcon width={24} />
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md px-2.5 py-1.5 text-sm font-semibold text-textListing shadow-sm hover:scale-110 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                        onClick={() =>
                          Modal.confirm({
                            title: 'Merchant Resource',
                            content: 'Are you sure you want to delete merchant resource?',
                            okText: 'Delete',
                            okButtonProps: {
                              danger: true,
                            },
                            onOk: async () => {
                              setIsLoading(true);
                              const { data, status } = await merchantResourceListingApi.deleteMerchantResource(
                                item.merchant_resource_id,
                              );

                              if (status === 200 && data.status === 'successful') {
                                message.success('Merchant resource deleted successfully');
                                setMerchantResources((prev) =>
                                  prev.filter((i) => i.merchant_resource_id !== item.merchant_resource_id),
                                );
                              } else
                                message.error(
                                  data.message ||
                                    'Something went wrong. Please try again after sometime or contact support for help.',
                                );
                              setIsLoading(false);
                            },
                          })
                        }
                      >
                        <TrashIcon width={24} />
                      </button>
                      {/* {planIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" /> : null} */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MerchantResourceListing;

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { TBookingResource } from '../../create-booking.type';
import createBookingApi from '../../create-booking.api';
import { ResourceRadioGroup } from './components';
import { LoadingOverlayContext } from '../../../../contexts/loading-overlay.context';

type TBookingResourceProps = {
  control: any;
};

const BookingResource: FC<TBookingResourceProps> = ({ control }) => {
  const { setLoading } = useContext(LoadingOverlayContext);
  const [bookingResources, setBookingResources] = useState<TBookingResource[]>([]);

  const fetchBookingResources = useQuery('bookingResources', () => createBookingApi.getBookingResources(1), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200 && data.merchant_resource_list) {
        setBookingResources([
          {
            merchant_resource_id: -1,
            merchant_resource_name: 'Default',
            children: [
              {
                merchant_resource_id: 0,
                merchant_resource_name: 'No Preference',
                merchant_resource_image_list: [],
              },
            ],
            merchant_resource_image_list: [],
          },
          ...data.merchant_resource_list.filter((resource: TBookingResource) => resource.children.length > 0),
        ]);
      }
    },
  });

  useEffect(() => {
    fetchBookingResources.refetch();
  }, []);

  useEffect(() => {
    if (fetchBookingResources.isFetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fetchBookingResources.isFetching]);

  return (
    <div className="overflow-y-auto p-5">
      <ResourceRadioGroup options={bookingResources} name="bookingResourceId" control={control} />
    </div>
  );
};

export default BookingResource;

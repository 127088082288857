import React from 'react';
import { Location, Navigate, NavigateFunction, Outlet, useLocation, useNavigate } from 'react-router-dom';
import session from '../../session/session.config';

const useAuth = (navigate: NavigateFunction, location: Location) => {
  const user = localStorage.getItem('currentUser');
  if (user) {
    const isRouteAuthenticated = session.authenticateRoute(window.location.pathname);
    if (typeof isRouteAuthenticated === 'boolean' && !isRouteAuthenticated) {
      navigate('/', { replace: true, state: { from: location } });
    } else if (typeof isRouteAuthenticated === 'string') return <Navigate to={isRouteAuthenticated} />;
    return <Outlet />;
  }
  return <Navigate to="/" state={{ from: location }} replace />;
};

const ProtectedRoutes: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useAuth(navigate, location);

  return isAuthenticated;
};

export default ProtectedRoutes;

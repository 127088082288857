/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { TBookingDetail } from '../merchant.type';
import bookingDetailApi from './booking-detail.api';
import { LoadingOverlayContext } from '../../../contexts/loading-overlay.context';
import { BookingTimeline } from './components';

const BookingDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { setLoading } = useContext(LoadingOverlayContext);
  const [booking, setBooking] = useState<TBookingDetail>();

  const fetchBooking = useQuery('fetchBooking', () => bookingDetailApi.getBookingDetail(Number(id) || 0), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200 && data?.data) {
        setBooking(data.data);
      }
    },
  });

  useEffect(() => {
    if (Number(id) > 0) fetchBooking.refetch();
  }, []);

  useEffect(() => {
    setLoading(fetchBooking.isFetching);
  }, [fetchBooking.isFetching]);

  return (
    <div className="h-fill p-5 flex justify-center items-center">
      {booking && (
        <main className="w-auto pb-24 pt-8 sm:px-6 sm:pt-16 lg:max-w-7xl lg:px-8 bg-white rounded shadow-md">
          <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
            <div className="flex sm:items-baseline sm:space-x-4">
              <h1 className="text-2xl font-bold tracking-tight text-gray-800 sm:text-3xl">
                Booking # {booking.booking_id}
              </h1>
            </div>
            <p className="text-sm text-gray-600">
              Booking date{' '}
              <time
                dateTime={format(parseISO(booking?.booking_date), 'yyyy-M-d')}
                className="font-medium text-gray-700"
              >
                {format(parseISO(booking?.booking_date), 'MMMM d, yyyy')}
              </time>
            </p>
          </div>

          {/* Products */}
          <section aria-labelledby="products-heading" className="mt-6">
            <h2 id="products-heading" className="sr-only">
              Products purchased
            </h2>

            <div className="space-y-8">
              {booking && (
                <div className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
                  <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                    <div className="sm:flex lg:col-span-4">
                      {/* <div className="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                          <img
                            src={product.imageSrc}
                            alt={product.imageAlt}
                            className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                          />
                        </div> */}

                      <div className="mt-6 sm:ml-6 sm:mt-0">
                        <h3 className="text-base font-medium text-gray-900">
                          <a href="#">
                            {booking.customer.customer_first_names} {booking.customer.customer_last_name}
                          </a>
                        </h3>
                        <p className="mt-3 text-sm text-gray-500">{booking.customer.customer_phone_nr}</p>
                        <p className="mt-3 text-sm text-gray-500">{booking.customer.customer_email}</p>
                        <p className="mt-2 text-sm font-medium text-gray-900">
                          {booking.booking_start_time.padStart(8, '0')} - {booking.booking_end_time.padStart(8, '0')}
                        </p>
                        <p className="mt-2 text-sm font-medium text-gray-900 capitalize">
                          Status: {booking.booking_status}
                        </p>
                      </div>
                    </div>

                    <div className="mt-6 col-span-8 lg:mt-0 flex gap-8">
                      <dl className="gap-x-6 text-sm">
                        <div className="">
                          <dt className="font-medium text-gray-900">Booking Table</dt>
                          <dd className="mt-3 text-gray-500 capitalize">
                            {booking.merchant_resource_list.length > 0 ? (
                              <span className="block">
                                {booking.merchant_resource_list[0].merchant_resource_name} (
                                {booking.merchant_resource_list[0].merchant_resource_type.merchant_resource_type_name})
                              </span>
                            ) : (
                              <span className="block">No Preference</span>
                            )}
                          </dd>
                        </div>
                        {/* <div>
                          <dt className="font-medium text-gray-900">Booking updates</dt>
                          <dd className="mt-3 space-y-3 text-gray-500">
                            <p>
                            Confirmed At:{' '}
                            {format(parseISO(booking?.timestamps.booking_confirmation_timestamp), 'MMMM d, yyyy')}
                            </p>
                            {booking?.timestamps.booking_availed_timestamp && (
                              <p>
                              Availed At:{' '}
                              {format(parseISO(booking?.timestamps.booking_availed_timestamp), 'MMMM d, yyyy')}
                              </p>
                              )}
                              </dd>
                            </div> */}
                      </dl>
                      <BookingTimeline booking={booking} />
                    </div>
                  </div>

                  {/* <div className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                    <h4 className="sr-only">Status</h4>
                    <p className="text-sm font-medium text-gray-900">
                      Preparing to serve on{' '}
                      <time dateTime={booking.booking_date}>
                        {format(parseISO(booking?.booking_date), 'MMMM d, yyyy')}
                      </time>
                    </p>
                    <div className="mt-6" aria-hidden="true">
                      <div className="overflow-hidden rounded-full bg-gray-200">
                        <div
                          className="h-2 rounded-full bg-primaryDark"
                          style={{ width: `calc((${currentStep} * 2 + 1) / 8 * 100%)` }}
                        />
                      </div>
                      <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                        <div className="text-primaryDark">Booking Order placed</div>
                        <div className={classNames(currentStep > 0 ? 'text-primaryDark' : '', 'text-center')}>
                          Approved
                        </div>
                        <div className={classNames(currentStep > 1 ? 'text-primaryDark' : '', 'text-center')}>
                          Availed
                        </div>
                        <div className={classNames(currentStep > 2 ? 'text-primaryDark' : '', 'text-right')}>Closed</div>
                      </div>
                    </div>
                  </div> */}
                </div>
              )}
            </div>
          </section>

          {/* Billing */}
          {/* <section aria-labelledby="summary-heading" className="mt-16">
            <h2 id="summary-heading" className="sr-only">
              Billing Summary
            </h2>

            <div className="bg-gray-100 px-4 py-6 sm:rounded-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
              <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                <div>
                  <dt className="font-medium text-gray-900">Billing address</dt>
                  <dd className="mt-3 text-gray-500">
                    <span className="block">Floyd Miles</span>
                    <span className="block">7363 Cynthia Pass</span>
                    <span className="block">Toronto, ON N3Y 4H8</span>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">Payment information</dt>
                  <dd className="-ml-4 -mt-1 flex flex-wrap">
                    <div className="ml-4 mt-4 flex-shrink-0">
                      <svg aria-hidden="true" width={36} height={24} viewBox="0 0 36 24" className="h-6 w-auto">
                        <rect width={36} height={24} rx={4} fill="#224DBA" />
                        <path
                          d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                          fill="#fff"
                        />
                      </svg>
                      <p className="sr-only">Visa</p>
                    </div>
                    <div className="ml-4 mt-4">
                      <p className="text-gray-900">Ending with 4242</p>
                      <p className="text-gray-600">Expires 02 / 24</p>
                    </div>
                  </dd>
                </div>
              </dl>

              <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-5 lg:mt-0">
                <div className="flex items-center justify-between pb-4">
                  <dt className="text-gray-600">Subtotal</dt>
                  <dd className="font-medium text-gray-900">$72</dd>
                </div>
                <div className="flex items-center justify-between py-4">
                  <dt className="text-gray-600">Shipping</dt>
                  <dd className="font-medium text-gray-900">$5</dd>
                </div>
                <div className="flex items-center justify-between py-4">
                  <dt className="text-gray-600">Tax</dt>
                  <dd className="font-medium text-gray-900">$6.16</dd>
                </div>
                <div className="flex items-center justify-between pt-4">
                  <dt className="font-medium text-gray-900">Order total</dt>
                  <dd className="font-medium text-primaryDark">$83.16</dd>
                </div>
              </dl>
            </div>
          </section> */}
        </main>
      )}
    </div>
  );
};

export default BookingDetail;

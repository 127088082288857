/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom';
import './brands-grid.css';
import { Modal } from 'antd';
import { useContext } from 'react';
import { TBrandDetails, TMerchantBranch } from '../../../common/types';
import { useIsLoading } from '../../../common/hooks';
import headerApi from '../../../header/header.api';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import { ThemeContext } from '../../../../contexts/theme.context';

type Props = {
  brandsMargin?: string;
  title: string;
  titleSize?: string;
  spacing?: string;
  imageContainerSize?: string;
  imageSize?: string;
  gridData: Array<TMerchantBranch>;
};

const BrandsGrid = ({ brandsMargin, title, titleSize, spacing, imageContainerSize, imageSize, gridData }: Props) => {
  const { setIsLoading } = useIsLoading();
  const { orderCreationStore, setOrderCreationStore, reset } = useOrderCreationStore();
  const { theme, setThemeObject } = useContext(ThemeContext);
  const brandDetails: TBrandDetails | null = JSON.parse(localStorage.getItem('brandDetails') || 'null');
  const navigate = useNavigate();

  const fetchMenu = async (branch: TMerchantBranch) => {
    setIsLoading(true);
    const { data, status } = await headerApi.getMenu(branch.branch_id);
    if (status === 200) {
      const updatedMenu = data.data?.map((category) => ({
        ...category,
        menu_items: category.menu_items?.map((item) => ({
          ...item,
          item_category_id: category.item_category_id,
        })),
      }));
      const updatedStore: any = reset();
      setTimeout(() => {
        setOrderCreationStore({
          ...updatedStore,
          orderTicket: {
            ...updatedStore.orderTicket,
            integration_details: {
              ...updatedStore.orderTicket.integration_details,
              facility_id: branch.facility.facility_id,
              branch_id: branch.branch_id,
              brand_id: branch.brand.brand_id,
              marketplace_id: branch.marketplace.marketplace_id,
              fulfillment_type: 'restaurant-delivery',
            },
          },
          menu: updatedMenu,
          userConfig: undefined,
          availableFulfillmentTypes: branch.facility.fulfillment_types,
        });
        window.location.href = '/menu';
      }, 100);
    } else {
      Modal.info({
        title: 'Location not supported',
        content: (
          <div>
            <p>We are not available on the selected location.</p>
          </div>
        ),
        className: 'rounded-md',
      });
    }
    setIsLoading(false);
  };

  const fetchBrandDetails = async (branch: TMerchantBranch) => {
    setIsLoading(true);
    const { data, status } = await headerApi.getBrandDetailsById(branch.brand.brand_id);
    if (status === 200) {
      const logoImage = data.data.brand_image_list.find((image) => image.image_type === 'logo')?.image_url || null;
      const coverImage = data.data.brand_image_list.find((image) => image.image_type === 'cover')?.image_url || null;
      const bannerImage = data.data.brand_image_list.find((image) => image.image_type === 'banner')?.image_url || null;

      localStorage.setItem(
        'brandDetails',
        JSON.stringify({
          ...data.data,
          logo_image: logoImage,
          cover_image: coverImage,
          banner_image: bannerImage,
        }),
      );

      localStorage.removeItem('iBlinkMarketplaceUser');
      localStorage.removeItem('diningAreas');
      localStorage.removeItem('customerDetails');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('merchantCode');
      localStorage.removeItem('savedLocations');
      localStorage.removeItem('selectedPickupLocation');
      localStorage.removeItem('selectedDeliveryLocation'); // ? remove
      localStorage.removeItem('orderType'); // ? remove
      reset();
      // TODO: get dining area from table id
      // const diningArea: TDiningArea | null = null;
      setTimeout(async () => {
        // if (tableId) {
        //   const { data: dinningAreasData, status: dinningAreasStatus } = await sharedApi.getFacilityDiningAreas(
        //     data.data.facility.facility_id,
        //   );
        //   if (dinningAreasStatus === 200) {
        //     diningArea =
        //       dinningAreasData.data.find((area: TDiningArea) =>
        //         area.dining_table_list.find((table: TDiningTable) => table.dining_table_id === Number(tableId)),
        //       ) || null;
        //   }
        // }
        if (data.data.brand_facility_web_element_list) {
          const logoElement = data.data.brand_facility_web_element_list.header?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'brand_logo',
          )?.brand_facility_web_element_image_map_list;
          const headerElements = {
            backgroundColor: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'background_color',
            )?.web_element_value,
            textColor: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'text_color',
            )?.web_element_value,
            textColorSecondary: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'text_color_secondary',
            )?.web_element_value,
            logoUrl: logoElement ? logoElement[0]?.brand_facility_web_element_image_map_url : undefined,
          };
          const generalElements = {
            backgroundColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'background_color',
            )?.web_element_value,
            buttonColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'button_color',
            )?.web_element_value,
          };
          setThemeObject({ ...theme, header: headerElements, general: generalElements });
          fetchMenu(branch);
        }
        // window.location.reload();
      }, 100);
      setIsLoading(false);
    } else navigate('/404', { state: { message: 'Please scan a valid QR code to continue' } });
  };

  const selectBrand = (branch: TMerchantBranch) => {
    if (brandDetails?.brand_id === branch.brand.brand_id) navigate('/menu');
    else if (
      orderCreationStore.orderTicket.item_list.length > 0 ||
      orderCreationStore.orderTicket.offer_list.length > 0
    ) {
      Modal.confirm({
        title: 'Are you sure you want to change the restaurant?',
        content: 'You will lose your current items in bucket if you continue.',
        onOk: () => fetchBrandDetails(branch),
      });
    } else fetchBrandDetails(branch);
  };

  return (
    <div className={brandsMargin}>
      <h1 className={`text-black font-bold ${titleSize} mb-2`}>{title}</h1>
      <div className={`grid !gap-4 ${spacing} overflow-x-auto p-1 brands-grid`}>
        {gridData.map((data) => (
          <a key={data.brand.brand_id} onClick={() => selectBrand(data)}>
            <div
              className={`${imageContainerSize} bg-white shadow-lg rounded-md overflow-hidden cursor-pointer border`}
            >
              <div className="m-2">
                <div className="relative flex justify-center">
                  <img
                    src={data.brand.logo_image || `${process.env.PUBLIC_URL}/assets/brand.png`}
                    alt="restaurant"
                    className={`${imageSize} h-48 object-cover rounded-md scale-100 hover:scale-105 ease-in duration-100`}
                  />
                  <div className="bg-white shadow-lg rounded-3xl absolute -bottom-4 right-4 md:right-0 w-20">
                    {/* <p className="text-black text-center m-0 font-semibold pt-1">15 - 20</p>
                    <p className="text-black text-center text-xs mb-1">min</p> */}
                  </div>
                </div>
                <div className="mt-1">
                  <span className="text-black font-semibold text-sm capitalize">{data.brand.brand_name}</span>
                  <br />
                  {/* <span className="text-green-500 text-xs">{data.rating} </span>
                  <span className="text-black text-xs">({data.reviews}) </span> */}
                  {data.brand.brand_cuisine_list.length > 0 ? (
                    <span className="text-black text-xs capitalize">
                      &#8226; {data.brand.brand_cuisine_list.map((cuisine) => cuisine.cuisine_name).join(', ')}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

BrandsGrid.defaultProps = {
  brandsMargin: 'mt-4 md:mt-4',
  titleSize: 'text-lg md:text-2xl',
  spacing: 'grid-cols-[repeat(auto-fill,minmax(220px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(230px,1fr))]',
  imageContainerSize: 'h-full w-full md:h-full',
  imageSize: 'xl:h-36 xl:w-60 md:h-32 md:w-48 sm:h-20 sm:w-20',
};

export default BrandsGrid;

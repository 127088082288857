import React, { useContext, useEffect, useRef, useState } from 'react';
import { Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import ProductsGrid from './components/products-grid/products-grid.component';
import { ThemeContext } from '../../contexts/theme.context';
import { TBrandDetails } from '../common/types';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';
import SideMenu, { DrawerHandle } from '../sidemenu/sidemenu.component';

const Brand: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { orderCreationStore } = useOrderCreationStore();
  const { orderTicket } = orderCreationStore;
  const [basketItems, setBasketItems] = useState(0);
  const sideMenuRef = useRef<DrawerHandle>(null);

  const brandDetails: TBrandDetails | undefined =
    JSON.parse(localStorage.getItem('brandDetails') || 'null') || undefined;

  const [expanded, setExpanded] = useState(false);

  const showSidebar = () => sideMenuRef.current?.toggleDrawer();
  const basketPressed = () => {
    if (window.location.pathname === '/basket' && isMobile) {
      navigate(-1);
    } else if (isTablet) {
      showSidebar();
    } else if (isMobile) {
      navigate('/basket');
    } else {
      showSidebar();
    }
  };

  useEffect(() => {
    setBasketItems(orderTicket.item_list.length);
  }, [orderTicket.item_list]);

  return (
    <div className="relative bg-gray-100 pb-10" style={{ backgroundColor: theme?.general?.backgroundColor || '' }}>
      <div
        className="bg-white pb-4 rounded-b-2xl"
        style={{
          backgroundColor: theme?.general?.cardBackgroundColor || '',
        }}
      >
        <img
          src={brandDetails?.banner_image || `${process.env.PUBLIC_URL}/assets/brand.png`}
          alt={brandDetails?.brand_name}
          className="h-60 w-full object-cover"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/brand.png`;
          }}
        />
        <div className="mx-4 md:ml-10 mt-4">
          <span className="text-2xl capitalize font-bold mb-2">{brandDetails?.brand_name}</span>
          <br />
          {/* <StarIcon className="h-4 w-4 -mt-1 mr-1 inline text-green-500" aria-hidden="true" />
          <span className="text-green-500 text-xs">4.6 Excellent </span>
          <span className="text-xs">(500+) </span>
          <span className="text-xs">&#8226; Lebanese</span> */}
          <p className="first-letter:capitalize">
            {expanded ? brandDetails?.brand_description : brandDetails?.brand_description?.slice(0, 150)}
          </p>
          {(brandDetails && brandDetails?.brand_description?.length) ||
            (0 > 150 &&
              (expanded ? (
                <button type="button" onClick={() => setExpanded(false)} className="text-blue-500">
                  See Less
                </button>
              ) : (
                <button type="button" onClick={() => setExpanded(true)} className="text-blue-500">
                  See More
                </button>
              )))}
          {/* <div className="relative cursor-pointer">
            <p>Restaurant Info</p>
            <span className="text-xs text-gray-500">Allergens and more</span>
            <ChevronRightIcon className="h-6 w-6 mr-10 absolute right-0 top-0 bottom-0 my-auto" aria-hidden="true" />
          </div> */}
        </div>
      </div>
      <div className="px-4">
        <ProductsGrid />
      </div>
      <button
        type="button"
        onClick={basketPressed}
        className="fixed bottom-2 right-4 h-16 w-16 bg-black/30 rounded-full"
      >
        <Badge
          count={basketItems}
          style={{
            backgroundColor: theme?.general?.buttonColor || '',
            borderColor: theme?.general?.buttonColor || '',
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/shopping_basket.png`} alt="basket" className="w-8 text-white" />
        </Badge>
      </button>
      <SideMenu ref={sideMenuRef} />
    </div>
  );
};

export default Brand;

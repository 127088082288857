import api from '../../../config/api/api.config';

const modifyMerchantResourceApi = {
  getMerchantResource: (id: number) => api.get(`merchant_resource/${id}`),
  getMerchantResourceTypes: () => api.get(`merchant_resource_types`),
  addMerchantResource: (data: FormData) =>
    api.post(`merchant_resource`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
  getMerchantResources: (params: any = {}) => api.get(`merchant_resources`, { params }),
  updateMerchantResource: (id: number, data: any) => api.put(`merchant_resource/${id}`, data),
  updateMerchantResourceImage: (id: number, data: FormData) =>
    api.put(`merchant_resource/${id}/image`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
  updateMerchantResourceSeatingCapacity: (id: number, seatingCapacity: number) =>
    api.put(`merchant_resource_attribute/${id}`, {
      merchant_resource_attribute_name: 'seating_capacity',
      merchant_resource_attribute_value: String(seatingCapacity),
    }),
};

export default modifyMerchantResourceApi;

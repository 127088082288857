/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataLoadingOverlay } from '../../common/components';
import api from '../../../config/api/api.config';
import { xApiKey } from '../../../config/variables/system.variable';

const MerchantLogin = () => {
  const URLParams = new URLSearchParams(window.location.search);
  const xUserId = URLParams.get('xUserId');
  const xAccessToken = URLParams.get('xAccessToken');
  const xUsername = URLParams.get('xUsername');
  const navigate = useNavigate();

  useEffect(() => {
    if (xUserId && xAccessToken && xUsername) {
      const iBlinkMarketplaceCustomer = {
        token: xAccessToken,
        id: xUserId,
        apiKey: xApiKey || '',
        role: 'merchant',
      };
      localStorage.setItem('iBlinkMarketplaceUser', JSON.stringify(iBlinkMarketplaceCustomer));
      api.setAuthenticationHeader(iBlinkMarketplaceCustomer);
      navigate('/merchant/bookings');
    }
  }, []);

  return (
    <div>
      <DataLoadingOverlay isLoading />
    </div>
  );
};

export default MerchantLogin;

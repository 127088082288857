/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { create } from 'zustand';
// import { message } from 'antd';
import {
  TAvailableMenu,
  TDiningArea,
  TDiscount,
  TMenuCategory,
  TOrderCreationConfig,
  TOrderLineItem,
  TOrderTicket,
} from '../common/types';
import sharedApi from '../common/shared.api';

interface OrderCreationStore {
  currentPage: 'new-order' | 'edit-order';
  orderTicket: TOrderTicket;
  originalOrderTicket?: TOrderTicket;
  menu: TMenuCategory[];
  availableMenus: TAvailableMenu[];
  availableDiscounts: TDiscount[];
  diningAreas: TDiningArea[];
  userConfig?: TOrderCreationConfig;
  selectedEditMenuItem?: TOrderLineItem;
  editTable?: boolean;
  address?: {
    order_address: string;
  };
  availableFulfillmentTypes?: {
    fulfillment_type_id: number;
    fulfillment_type_name: string;
  }[];
}

type TResetSkip = {
  branch?: boolean;
  marketplace?: boolean;
  brand?: boolean;
  facility?: boolean;
};

type TSkipUserConfig = {
  customerDetails?: boolean;
  customerAddressDetails?: boolean;
};

interface OrderCreationState {
  orderCreationStore: OrderCreationStore;
  reset: (skip?: TResetSkip) => void;
  setOrderCreationStore: (orderCreationStore: OrderCreationStore) => void;
  addLineItemToTicket: (lineItem: TOrderLineItem) => Promise<boolean>;
  changeLineItemQuantity: (lineItem: TOrderLineItem, quantityChange: number | 'remove') => Promise<boolean>;
  updateLineItemModifiers: (orderCreationStore: OrderCreationStore, lineItem: TOrderLineItem) => void;
  updateDiscounts: (discounts: TOrderTicket['discount_list']) => void;
  updateDeliveryAmount: (orderCreationStore: OrderCreationStore, deliveryAmount: number) => void;
  updateOrderFulfilmentType: (fulfilmentTypeId: number) => void;
  updateUserConfig: (userConfig: TOrderCreationConfig, skip?: TSkipUserConfig) => void;
  initializeEditOrder: (orderTicket: TOrderTicket) => void;
  updateAvailableMenus: (availableMenus: TAvailableMenu[]) => void;
  updateSelectedMenu: (marketplaceId: number | null) => boolean;
  addNotes: (notes: string, orderLineItemId?: string) => void;
}

const calculateMenuItemsPrice = (
  menuItems: TOrderLineItem[],
  integrationDetails: TOrderTicket['integration_details'],
) => {
  return menuItems.map((item) => {
    const modifierPrice = item.modifier_section_list
      ? item.modifier_section_list
          .flatMap((section) =>
            section.modifier_choice_list.map((choice) => (choice.price ? Number(choice.price) * choice.quantity : 0)),
          )
          .reduce((a, b) => a + b, 0)
      : 0;
    const itemPrice =
      (item.item_price_list?.length > 0 &&
        (
          item.item_price_list.find((price) => price.fulfillment_type_id === integrationDetails.fulfillment_type_id) ||
          item.item_price_list.find((price) => price.marketplace_id === integrationDetails.marketplace_id)
        )?.specific_price) ||
      item.item_price;
    return {
      ...item,
      price: Number(itemPrice) || 0,
      priceToShow: (Number(itemPrice) || 0) * item.quantity,
      modifierSubtotal: modifierPrice * item.quantity,
      subTotal: (modifierPrice + (Number(itemPrice) || 0)) * item.quantity,
    };
  });
};

const calculateTicketPaymentDetails = (orderTicket: TOrderTicket) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { payment_details } = orderTicket;
  payment_details.payable_amount = 0;
  payment_details.discount_amount = 0;
  payment_details.service_charge_amount = 0;
  payment_details.tip_amount = 0;
  payment_details.currency_name = orderTicket.item_list[0]?.currency_alpha_3 || null;
  orderTicket.item_list.forEach((item) => {
    payment_details.payable_amount += item.subTotal || 0;
  });
  orderTicket.offer_list.forEach((offer) => {
    payment_details.payable_amount += offer.subTotal || 0;
  });
  orderTicket.discount_list = orderTicket.discount_list.map((discount) => {
    const applicableItems =
      discount.item_id_list.length > 0 || discount.item_category_id_list.length > 0
        ? orderTicket.item_list.filter(
            (item) =>
              (discount.item_id_list.length > 0 &&
                discount.item_id_list.find((dItem) => dItem.item_id === item.item_id)) ||
              (discount.item_category_id_list.length > 0 &&
                discount.item_category_id_list.find((dItem) => dItem.item_category_id === item.item_category_id)),
          )
        : null;
    let payableAmount = 0;
    if (applicableItems) {
      applicableItems.forEach((item) => {
        payableAmount += item.subTotal || 0;
      });
    } else {
      payableAmount = payment_details.payable_amount;
    }
    const actualDiscountedAmount = discount.percentage_p
      ? payableAmount * (discount.discount_value / 100)
      : discount.discount_value;
    payment_details.discount_amount += actualDiscountedAmount;
    return { ...discount, actualDiscountedAmount };
  });
  payment_details.payable_amount -= payment_details.discount_amount;
  payment_details.payable_amount += payment_details.delivery_amount;
  payment_details.tax_amount = payment_details.payable_amount - payment_details.payable_amount / 1.05;
  return payment_details;
};

const combineMenuItems = (menuItems: TOrderLineItem[]) => {
  const combinedMenuItems: TOrderLineItem[] = [];
  menuItems.forEach((item) => {
    const existingItem = combinedMenuItems.find(
      (combinedItem) =>
        combinedItem.external_item_id === item.external_item_id &&
        combinedItem.modifier_section_list.every((section) => {
          const itemSection = item.modifier_section_list.find(
            (iSection) => iSection.external_modifier_section_id === section.external_modifier_section_id,
          );
          if (!itemSection) return false;
          return (
            section.modifier_choice_list.every((choice) =>
              itemSection.modifier_choice_list.find(
                (itemChoice) =>
                  itemChoice.external_modifier_choice_id === choice.external_modifier_choice_id &&
                  itemChoice.quantity === choice.quantity,
              ),
            ) &&
            itemSection.modifier_choice_list.every((itemChoice) =>
              section.modifier_choice_list.find(
                (choice) =>
                  choice.external_modifier_choice_id === itemChoice.external_modifier_choice_id &&
                  itemChoice.quantity === choice.quantity,
              ),
            )
          );
        }),
    );
    if (existingItem) {
      existingItem.quantity += item.quantity;
      existingItem.order_line_item_id_list = [
        ...(existingItem.order_line_item_id_list || []),
        ...(item.order_line_item_id_list || []),
      ];
      existingItem.modifier_section_list = existingItem.modifier_section_list.map((section) => {
        const itemSection = item.modifier_section_list.find(
          (iSection) => iSection.external_modifier_section_id === section.external_modifier_section_id,
        );
        if (!itemSection) return section;

        const modifierItemSectionIdList = itemSection.order_line_item_modifier_section_id
          ? [itemSection.order_line_item_modifier_section_id]
          : [];

        return {
          ...section,
          order_line_item_modifier_section_id_list: [
            ...(section.order_line_item_modifier_section_id_list || []),
            ...modifierItemSectionIdList,
          ].filter((iSection) => iSection !== null && iSection !== undefined),
          modifier_choice_list: section.modifier_choice_list.map((choice) => {
            const itemChoice = itemSection.modifier_choice_list.find(
              (iChoice) => iChoice.external_modifier_choice_id === choice.external_modifier_choice_id,
            );
            if (!itemChoice) return choice;
            const modifierItemChoiceIdList = itemChoice.order_line_item_modifier_choice_id
              ? [itemChoice.order_line_item_modifier_choice_id]
              : [];
            return {
              ...choice,
              order_line_item_modifier_choice_id_list: [
                ...(choice.order_line_item_modifier_choice_id_list || []),
                ...modifierItemChoiceIdList,
              ].filter((iChoice) => iChoice !== null && iChoice !== undefined),
            };
          }),
        };
      });
    } else {
      const updatedItem = {
        ...item,
        modifier_section_list: item.modifier_section_list.map((section) => ({
          ...section,
          order_line_item_modifier_section_id_list: section.order_line_item_modifier_section_id
            ? [section.order_line_item_modifier_section_id]
            : [],
          modifier_choice_list: section.modifier_choice_list.map((choice) => ({
            ...choice,
            order_line_item_modifier_choice_id_list: choice.order_line_item_modifier_choice_id
              ? [choice.order_line_item_modifier_choice_id]
              : [],
          })),
        })),
      };
      combinedMenuItems.push(updatedItem);
    }
  });
  return combinedMenuItems;
};

const verifyOrderTicket = async (orderTicket: TOrderTicket) => {
  const { status } = await sharedApi.validateOrder(orderTicket);
  return status === 200;
};

const useOrderCreationStore = create<OrderCreationState>((set, get) => {
  const creationStore = JSON.parse(localStorage.getItem('orderCreationStore') || 'null');
  return {
    orderCreationStore: creationStore || {
      currentPage: 'new-order',
      orderTicket: {
        version: '0.1',
        test_p: 0,
        external_order_reference_nr: null,
        order_placement_channel_id: 4,
        order_notes: null,
        dining_table_id: null,
        payment_point_id: null,
        payment_details: {
          payable_amount: 0,
          tip_amount: 0,
          discount_amount: 0,
          tax_amount: 0,
          delivery_amount: 0,
          currency_name: null,
          payment_method_name: null,
          service_charge_amount: 0,
          paid_p: 0,
        },
        integration_details: {
          branch_id: 0,
          brand_id: 0,
          facility_id: 0,
          marketplace_id: 0,
          fulfillment_type_id: 0,
          fulfillment_type: 'dine-in',
        },
        customer_details: {
          external_customer_id: null,
          first_name_en: null,
          last_name_en: null,
          email: null,
          phone_nr: null,
          customer_contact_method: null,
        },
        employee_details: {
          external_employee_id: null,
          first_name_en: null,
          last_name_en: null,
          email: null,
          phone_nr: null,
        },
        item_list: [],
        offer_list: [],
        discount_list: [],
      },
      menu: [] as TMenuCategory[],
      availableMenus: [] as TAvailableMenu[],
      availableDiscounts: [] as TDiscount[],
      diningAreas: [] as TDiningArea[],
      userConfig: undefined,
    },
    reset: (skip) => {
      const { orderCreationStore } = get();
      const { userConfig, orderTicket: originalOrder, availableDiscounts /* availableMenus */ } = orderCreationStore;
      const orderTicket = {
        version: '0.1',
        test_p: 0,
        external_order_reference_nr: null,
        order_placement_channel_id: 4,
        order_notes: null,
        dining_table_id: null,
        payment_point_id: null,
        payment_details: {
          payable_amount: 0,
          tip_amount: 0,
          discount_amount: 0,
          tax_amount: 0,
          delivery_amount: [3].includes(userConfig?.fulfillment_type_id || 0)
            ? userConfig?.default_delivery_charges || 0
            : 0,
          currency_name: null as string | null,
          payment_method_name: null as string | null,
          service_charge_amount: 0,
          paid_p: 0,
        },
        integration_details: {
          branch_id: skip?.branch ? originalOrder.integration_details.branch_id : userConfig?.branch_id || 0,
          brand_id: skip?.brand ? originalOrder.integration_details.brand_id : userConfig?.brand_id || 0,
          facility_id: skip?.facility ? originalOrder.integration_details.facility_id : userConfig?.facility_id || 0,
          marketplace_id: skip?.marketplace
            ? originalOrder.integration_details.marketplace_id
            : userConfig?.marketplace_id,
          fulfillment_type_id: userConfig?.fulfillment_type_id || 0,
          fulfillment_type: userConfig?.fulfillment_type_name || '',
        },
        customer_details: {
          external_customer_id: null,
          first_name_en: null,
          last_name_en: null,
          email: null,
          phone_nr: null,
          customer_contact_method: null,
        },
        customer_address_details: {
          customer_address_id: originalOrder.customer_address_details?.customer_address_id,
          customer_address: originalOrder.customer_address_details?.customer_address,
        },
        employee_details: {
          external_employee_id: null,
          first_name_en: null,
          last_name_en: null,
          email: null,
          phone_nr: null,
        },
        item_list: [],
        offer_list: [],
        discount_list: availableDiscounts
          .filter((discount) => discount.auto_apply_p)
          .map((discount) => ({
            discount_id: discount.discount_id,
            discount_name: discount.discount_name,
            item_id: null,
            item_id_list: discount.item_id_list,
            item_category_id_list: discount.item_category_id_list,
            percentage_p: discount.percentage_p,
            discount_value: Number(discount.discount_value),
            discount_cap_value: Number(discount.discount_cap_value),
            minimum_order_value: Number(discount.minimum_order_value),
          })),
      };
      // const resetMenu = availableMenus.find(
      //   (menu) =>
      //     menu.branchId === orderTicket.integration_details.branch_id &&
      //     menu.marketplaceId === orderTicket.integration_details.marketplace_id,
      // );
      orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket as TOrderTicket);

      // orderCreationStore.menu = resetMenu?.menu || [];
      set(() => ({
        orderCreationStore: {
          ...orderCreationStore,
          currentPage: 'new-order',
          orderTicket: orderTicket as TOrderTicket,
          originalOrderTicket: undefined,
        },
      }));
      return {
        ...orderCreationStore,
        currentPage: 'new-order',
        orderTicket: orderTicket as TOrderTicket,
        originalOrderTicket: undefined,
      };
    },
    setOrderCreationStore: (orderCreationStore: OrderCreationStore) => {
      // localStorage.setItem('OrderCreationStore', JSON.stringify({ ...orderCreationStore, menu: null }));
      return set(() => ({ orderCreationStore }));
    },
    addLineItemToTicket: async (lineItem: TOrderLineItem) => {
      const { orderCreationStore } = get();
      const { orderTicket, currentPage, availableDiscounts } = orderCreationStore;
      const tempLineItems = JSON.parse(JSON.stringify(orderTicket.item_list));
      const existingItem = orderTicket.item_list.find(
        (item) =>
          item.external_item_id === lineItem.external_item_id &&
          item.order_line_item_notes === lineItem.order_line_item_notes &&
          item.modifier_section_list.length === lineItem.modifier_section_list.length &&
          item.modifier_section_list.every((section) => {
            const lineItemSection = lineItem.modifier_section_list.find(
              (LISection) => LISection.external_modifier_section_id === section.external_modifier_section_id,
            );
            if (!lineItemSection) return false;
            return (
              section.modifier_choice_list.length === lineItemSection.modifier_choice_list.length &&
              section.modifier_choice_list.every((choice) =>
                lineItemSection.modifier_choice_list.find(
                  (lineItemChoice) =>
                    lineItemChoice.external_modifier_choice_id === choice.external_modifier_choice_id &&
                    lineItemChoice.quantity === choice.quantity,
                ),
              ) &&
              lineItemSection.modifier_choice_list.every((lineItemChoice) =>
                section.modifier_choice_list.find(
                  (choice) =>
                    choice.external_modifier_choice_id === lineItemChoice.external_modifier_choice_id &&
                    lineItemChoice.quantity === choice.quantity,
                ),
              )
            );
          }),
      );

      if (existingItem) {
        existingItem.quantity += lineItem.quantity;
        if (currentPage === 'edit-order') {
          const existingItemOriginal = orderCreationStore.originalOrderTicket?.item_list.find(
            (item) => item.id === existingItem.id,
          );
          if (existingItemOriginal && existingItemOriginal.quantity !== existingItem.quantity) {
            existingItem.edit_status = 'updated';
          } else if (existingItemOriginal && existingItemOriginal.quantity === existingItem.quantity) {
            existingItem.edit_status = null;
          } else {
            existingItem.edit_status = 'inserted';
            existingItem.order_line_item_id_list = [];
          }
        }
      } else {
        if (currentPage === 'edit-order') {
          lineItem.edit_status = 'inserted';
          lineItem.order_line_item_id_list = [];
        }
        if (availableDiscounts.length > 0) {
          const autoApplyDiscounts = availableDiscounts.filter(
            (discount) =>
              discount.auto_apply_p &&
              ((discount.item_category_id_list.length === 0 && discount.item_id_list.length === 0) ||
                (discount.item_category_id_list.length > 0 &&
                  discount.item_category_id_list.find(
                    (category) => lineItem.item_category_id === category.item_category_id,
                  )) ||
                (discount.item_id_list.length > 0 &&
                  discount.item_id_list.find((dItem) => lineItem.item_id === dItem.item_id))),
          );

          const updatedAutoApplyDiscounts = autoApplyDiscounts.map((discount) => ({
            discount_id: discount.discount_id,
            discount_name: discount.discount_name,
            item_id: null,
            item_id_list: discount.item_id_list,
            item_category_id_list: discount.item_category_id_list,
            item_category_id: null,
            percentage_p: discount.percentage_p,
            discount_value: Number(discount.discount_value),
            discount_cap_value: Number(discount.discount_cap_value),
            minimum_order_value: Number(discount.minimum_order_value),
          }));

          orderTicket.discount_list =
            autoApplyDiscounts.length === 0
              ? orderTicket.discount_list
              : [...orderTicket.discount_list, ...updatedAutoApplyDiscounts].reduce((unique, discount) => {
                  return unique.find((item) => item.discount_id === discount.discount_id)
                    ? unique
                    : [...unique, discount];
                }, [] as TOrderTicket['discount_list']);
        }

        orderTicket.item_list.push(lineItem);
      }
      orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
      orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
      set(() => ({ orderCreationStore }));
      if (!(await verifyOrderTicket(orderTicket))) {
        // message.error(
        //   'Something went wrong adding menu item, please try again later. If the error persists, please contact support.',
        // );
        orderTicket.item_list = tempLineItems;
        orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
        orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
        set(() => ({ orderCreationStore }));
        return false;
      }
      return true;
    },
    changeLineItemQuantity: async (lineItem: TOrderLineItem, quantityChange: number | 'remove') => {
      const { orderCreationStore } = get();
      const { orderTicket, currentPage, selectedEditMenuItem, availableDiscounts } = orderCreationStore;
      const tempLineItems = JSON.parse(JSON.stringify(orderTicket.item_list));
      const existingItem = orderTicket.item_list.find(
        (item) =>
          JSON.stringify({
            ...item,
            quantity: 0,
            modifierSubtotal: undefined,
            priceToShow: undefined,
            subTotal: undefined,
          }) ===
          JSON.stringify({
            ...lineItem,
            quantity: 0,
            modifierSubtotal: undefined,
            priceToShow: undefined,
            subTotal: undefined,
          }),
      );
      if (existingItem) {
        let removeMenuItem = false;
        if (quantityChange === 'remove') removeMenuItem = true;
        else {
          existingItem.quantity += quantityChange;
          if (existingItem?.quantity === 0) removeMenuItem = true;
        }
        if (removeMenuItem) {
          orderTicket.item_list = orderTicket.item_list.filter(
            (item) =>
              JSON.stringify({
                ...item,
                quantity: 0,
                modifierSubtotal: undefined,
                priceToShow: undefined,
                subTotal: undefined,
              }) !==
              JSON.stringify({
                ...lineItem,
                quantity: 0,
                modifierSubtotal: undefined,
                priceToShow: undefined,
                subTotal: undefined,
              }),
          );
          if (selectedEditMenuItem?.id === existingItem.id) orderCreationStore.selectedEditMenuItem = undefined;

          if (availableDiscounts.length > 0) {
            const allowedAutoApplyDiscounts = availableDiscounts.filter(
              (discount) =>
                discount.auto_apply_p &&
                ((discount.item_category_id_list.length === 0 && discount.item_id_list.length === 0) ||
                  (discount.item_category_id_list.length > 0 &&
                    discount.item_category_id_list.find((category) =>
                      orderTicket.item_list.find((item) => item.item_category_id === category.item_category_id),
                    )) ||
                  (discount.item_id_list.length > 0 &&
                    discount.item_id_list.find((dItem) =>
                      orderTicket.item_list.find((item) => item.item_id === dItem.item_id),
                    ))),
            );
            const extraAutoApplyDiscounts = availableDiscounts.filter(
              (discount) =>
                !allowedAutoApplyDiscounts.find((aDiscount) => aDiscount.discount_id === discount.discount_id),
            );

            orderTicket.discount_list = orderTicket.discount_list.filter(
              (discount) =>
                !extraAutoApplyDiscounts.find((aDiscount) => aDiscount.discount_id === discount.discount_id),
            );
          }
        } else if (currentPage === 'edit-order') {
          const existingItemOriginal = orderCreationStore.originalOrderTicket?.item_list.find(
            (item) => item.id === existingItem.id,
          );
          if (existingItemOriginal && existingItemOriginal.quantity !== existingItem.quantity) {
            existingItem.edit_status = 'updated';
          } else if (existingItemOriginal && existingItemOriginal.quantity === existingItem.quantity) {
            existingItem.edit_status = null;
          } else {
            existingItem.edit_status = 'inserted';
            existingItem.order_line_item_id_list = [];
          }
        }
      }
      orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
      orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
      set(() => ({ orderCreationStore }));
      if (!(await verifyOrderTicket(orderTicket))) {
        // message.error(
        //   'Something went wrong updating menu item quantity, please try again later. If the error persists, please contact support.',
        // );
        orderTicket.item_list = tempLineItems;
        orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
        orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
        set(() => ({ orderCreationStore }));
        return false;
      }
      return true;
    },
    updateLineItemModifiers: async (orderCreationStore: OrderCreationStore, lineItem: TOrderLineItem) => {
      const { orderTicket } = orderCreationStore;
      const tempLineItems = JSON.parse(JSON.stringify(orderTicket.item_list));
      const existingItem = orderTicket.item_list.find((item) => item.id === lineItem.id);

      if (existingItem) {
        existingItem.modifier_section_list = lineItem.modifier_section_list;
        orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
        orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
        set(() => ({ orderCreationStore }));
        if (!(await verifyOrderTicket(orderTicket))) {
          // message.error(
          //   'Something went wrong updating menu item modifiers, please try again later. If the error persists, please contact support.',
          // );
          orderTicket.item_list = tempLineItems;
          orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
          orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
          set(() => ({ orderCreationStore }));
        }
      }
    },
    updateDiscounts: async (discounts: TOrderTicket['discount_list']) => {
      const { orderCreationStore } = get();
      const { orderTicket } = orderCreationStore;
      const tempDiscounts = JSON.parse(JSON.stringify(orderTicket.discount_list));
      orderTicket.discount_list =
        discounts.length === 0
          ? []
          : [...orderTicket.discount_list, ...discounts].reduce((unique, discount) => {
              return unique.find((item) => item.discount_id === discount.discount_id) ? unique : [...unique, discount];
            }, [] as TOrderTicket['discount_list']);

      orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
      set(() => ({ orderCreationStore }));
      if (!(await verifyOrderTicket(orderTicket))) {
        // message.error(
        //   `Something went wrong ${
        //     discounts.length > tempDiscounts.length ? 'adding' : 'removing'
        //   } discount, please try again later. If the error persists, please contact support.`,
        // );
        orderTicket.discount_list = tempDiscounts;
        orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
        set(() => ({ orderCreationStore }));
      }
    },
    updateDeliveryAmount: async (orderCreationStore: OrderCreationStore, deliveryAmount: number) => {
      const { orderTicket } = orderCreationStore;
      const tempDeliveryAmount = JSON.parse(JSON.stringify(orderTicket.payment_details.delivery_amount));
      orderTicket.payment_details.delivery_amount = deliveryAmount;
      orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
      set(() => ({ orderCreationStore }));
      if (!(await verifyOrderTicket(orderTicket))) {
        // message.error(
        //   'Something went wrong updating delivery amount, please try again later. If the error persists, please contact support.',
        // );
        orderTicket.payment_details.delivery_amount = tempDeliveryAmount;
        orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
        set(() => ({ orderCreationStore }));
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateOrderFulfilmentType: async (fulfilmentTypeId: number) => {
      const { orderCreationStore } = get();
      const { orderTicket, userConfig } = orderCreationStore;
      const tempOrderTicket = JSON.parse(
        JSON.stringify({
          ...orderTicket,
          integration_details: { ...orderTicket.integration_details },
        }),
      );
      orderTicket.integration_details.fulfillment_type_id = fulfilmentTypeId;
      orderTicket.integration_details.fulfillment_type =
        orderCreationStore.userConfig?.allowed_fulfillment_type_list?.find(
          (fulfilment) => fulfilment.fulfillment_type_id === fulfilmentTypeId,
        )?.fulfillment_type_name || 'dine-in';
      orderTicket.dining_table_id = null;
      orderTicket.dining_area_id = undefined;
      orderTicket.dining_area_name = undefined;
      orderTicket.dining_table_name = undefined;
      orderTicket.discount_list = [];
      orderTicket.payment_details.delivery_amount = [3].includes(fulfilmentTypeId)
        ? userConfig?.default_delivery_charges || 0
        : 0;
      orderTicket.item_list = orderTicket.item_list.filter(
        (item) =>
          item.item_price_list.length === 0 ||
          item.item_price_list.find((price) => price.fulfillment_type_id === fulfilmentTypeId),
      );
      if (orderTicket.item_list.length !== tempOrderTicket.item_list.length) {
        // message.warning('Removing menu items not available for this fulfilment type, please check updated order.');
      }
      orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
      orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
      orderCreationStore.availableDiscounts = [];

      set(() => ({ orderCreationStore }));
      if (!(await verifyOrderTicket(orderTicket))) {
        // message.error(
        //   'Something went wrong changing fulfilment type, please try again later. If the error persists, please contact support.',
        // );
        orderTicket.integration_details = tempOrderTicket.integration_details;
        orderTicket.dining_table_id = tempOrderTicket.dining_table_id;
        orderTicket.dining_area_id = tempOrderTicket.dining_area_id;
        orderTicket.dining_area_name = tempOrderTicket.dining_area_name;
        orderTicket.dining_table_name = tempOrderTicket.dining_table_name;
        orderTicket.item_list = tempOrderTicket.item_list;
        orderTicket.item_list = calculateMenuItemsPrice(orderTicket.item_list, orderTicket.integration_details);
        orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket);
        set(() => ({ orderCreationStore }));
      }
    },
    updateUserConfig: (userConfig: TOrderCreationConfig, skip?: TSkipUserConfig) => {
      const { orderCreationStore } = get();
      // eslint-disable-next-line no-param-reassign
      orderCreationStore.userConfig = userConfig;
      const orderTicket = {
        version: '0.1',
        test_p: 0,
        external_order_reference_nr: null,
        order_placement_channel_id: 4,
        order_notes: null,
        dining_table_id: null,
        payment_point_id: null,
        payment_details: {
          payable_amount: 0,
          tip_amount: 0,
          discount_amount: 0,
          tax_amount: 0,
          delivery_amount: [3].includes(userConfig?.fulfillment_type_id || 0)
            ? userConfig?.default_delivery_charges || 0
            : 0,
          currency_name: null as string | null,
          payment_method_name: null as string | null,
          service_charge_amount: 0,
          paid_p: 0,
        },
        integration_details: {
          branch_id: userConfig?.branch_id || 0,
          brand_id: userConfig?.brand_id || 0,
          facility_id: userConfig?.facility_id || 0,
          marketplace_id: userConfig?.marketplace_id,
          fulfillment_type_id: userConfig?.fulfillment_type_id || 0,
          fulfillment_type: userConfig?.fulfillment_type_name || '',
        },
        customer_address_details: skip?.customerAddressDetails
          ? orderCreationStore.orderTicket.customer_address_details
          : undefined,
        customer_details: skip?.customerDetails
          ? orderCreationStore.orderTicket.customer_details
          : {
              external_customer_id: null,
              first_name_en: null,
              last_name_en: null,
              email: null,
              phone_nr: null,
              customer_contact_method: null,
            },
        employee_details: {
          external_employee_id: null,
          first_name_en: null,
          last_name_en: null,
          email: null,
          phone_nr: null,
        },
        item_list: orderCreationStore.orderTicket.item_list,
        offer_list: [],
        discount_list: [],
      };
      orderTicket.payment_details = calculateTicketPaymentDetails(orderTicket as TOrderTicket);
      return set(() => ({
        orderCreationStore: {
          ...orderCreationStore,
          currentPage: 'new-order',
          orderTicket: orderTicket as TOrderTicket,
        },
      }));
    },
    initializeEditOrder: (orderTicket: TOrderTicket) => {
      const { orderCreationStore } = get();
      const itemList = combineMenuItems(orderTicket.item_list);

      const updatedItemList = calculateMenuItemsPrice(itemList, orderTicket.integration_details);
      const paymentDetails = calculateTicketPaymentDetails({
        ...orderTicket,
        item_list: updatedItemList,
      });
      return set(() => ({
        orderCreationStore: {
          ...orderCreationStore,
          currentPage: 'edit-order',
          orderTicket: {
            ...orderTicket,
            item_list: updatedItemList,
            payment_details: paymentDetails,
          },
          originalOrderTicket: JSON.parse(JSON.stringify({ ...orderTicket, item_list: updatedItemList })),
        },
      }));
    },
    updateAvailableMenus: (availableMenus: TAvailableMenu[]) => {
      const { orderCreationStore } = get();
      return set(() => ({
        orderCreationStore: {
          ...orderCreationStore,
          availableMenus: [...orderCreationStore.availableMenus, ...availableMenus],
        },
      }));
    },
    updateSelectedMenu: (marketplaceId: number | null) => {
      const { orderCreationStore } = get();
      const { orderTicket } = orderCreationStore;
      const newMenu = orderCreationStore.availableMenus.find((menu) =>
        marketplaceId ? menu.marketplaceId === marketplaceId : !marketplaceId,
      );

      if (orderTicket.integration_details.branch_id !== newMenu?.branchId) {
        set(() => ({
          orderCreationStore: {
            ...orderCreationStore,
            menu:
              orderCreationStore.availableMenus.find((menu) =>
                marketplaceId ? menu.marketplaceId === marketplaceId : !marketplaceId,
              )?.menu || [],
            orderTicket: {
              ...orderTicket,
              integration_details: {
                ...orderTicket.integration_details,
                branch_id: newMenu?.branchId || 0,
                marketplace_id: newMenu?.marketplaceId || null,
              },
            },
          },
        }));
        return true;
      }
      return false;
    },
    addNotes: (notes: string, orderLineItemId?: string) => {
      const { orderCreationStore } = get();
      const { orderTicket } = orderCreationStore;
      if (orderLineItemId) {
        const existingItem = orderTicket.item_list.find((item) => item.id === orderLineItemId);
        if (existingItem) {
          existingItem.order_line_item_notes = notes;
        }
      } else {
        orderTicket.order_notes = notes;
      }
      set(() => ({ orderCreationStore }));
    },
  };
});

useOrderCreationStore.subscribe((orderCreationStore) => {
  // Store the latest value to localStorage
  localStorage.setItem('orderCreationStore', JSON.stringify(orderCreationStore.orderCreationStore));
});

export default useOrderCreationStore;

import { Outlet } from 'react-router-dom';
import { SideBar } from '../../../modules/merchant';

const IBlinkMarketplaceRoutes = () => {
  return (
    <div className="h-[100vh] overflow-y-auto">
      <SideBar>
        <Outlet />
      </SideBar>
    </div>
  );
};

export default IBlinkMarketplaceRoutes;

import { create } from 'zustand';
import { TManualCombinationRecommendation, TManualPushRecommendation } from '../../shared.api';

type TRecommendationStore = {
  open: boolean;
  recommendations: TManualCombinationRecommendation[] | TManualPushRecommendation[];
  recommendedItemIds: number[];
};

interface TRecommendationState {
  recommendationStore: TRecommendationStore;
  setOpen: (open: boolean) => void;
  setRecommendationsStore: (recommendationStore: TRecommendationStore) => void;
}

const useRecommendationModal = create<TRecommendationState>((set, get) => {
  return {
    recommendationStore: {
      open: false,
      recommendations: [],
      recommendedItemIds: [],
    },
    setOpen: (open: boolean) => set({ recommendationStore: { ...get().recommendationStore, open } }),
    setRecommendationsStore: (recommendationStore: TRecommendationStore) => set({ recommendationStore }),
  };
});

export default useRecommendationModal;

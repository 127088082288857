/* eslint-disable no-param-reassign */
import { Checkbox } from 'antd';
import { FC, useEffect, useState } from 'react';
import { QuantityPicker } from '../../../common/components';
import { OfferMenuItem, OfferMenuItemSection, OfferMenuItemSectionChoice } from '../../product-detail.type';

type OfferProps = {
  offerMenuItem: OfferMenuItem | undefined;
  setRequiredOfferSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOfferMenuItemIds: React.Dispatch<React.SetStateAction<number[]>>;
};

const Offer: FC<OfferProps> = ({ offerMenuItem, setRequiredOfferSelected, setSelectedOfferMenuItemIds }) => {
  const [offerMenuItemSections, setOfferMenuItemSections] = useState<OfferMenuItemSection[]>([]);

  const handleCheckboxChange = (
    checked: boolean,
    modifierSection: OfferMenuItemSection,
    modifierChoice: OfferMenuItemSectionChoice,
  ) => {
    if (checked) {
      const existingModifierSection = offerMenuItemSections.find(
        (ms) => ms.offer_section_id === modifierSection.offer_section_id,
      );
      if (existingModifierSection) {
        const existingModifierChoice = existingModifierSection.offer_section_line_items_list.find(
          (choice) => choice.menu_item_id === modifierChoice.menu_item_id,
        );
        if (existingModifierChoice) existingModifierChoice.choiceCount = (existingModifierChoice.choiceCount || 0) + 1;
        modifierSection.totalChoiceCount = existingModifierSection.offer_section_line_items_list
          .map((mc) => mc.choiceCount || 0)
          .reduce((a, b) => a + b, 0);
      }
      setOfferMenuItemSections((prevModifierSections: OfferMenuItemSection[]) =>
        prevModifierSections.map((ms) =>
          ms.offer_section_id === modifierSection.offer_section_id ? modifierSection : ms,
        ),
      );
    } else {
      const existingModifierSection = offerMenuItemSections.find(
        (ms) => ms.offer_section_id === modifierSection.offer_section_id,
      );
      if (existingModifierSection) {
        const existingModifierChoice = existingModifierSection.offer_section_line_items_list.find(
          (choice) => choice.menu_item_id === modifierChoice.menu_item_id,
        );
        if (existingModifierChoice) existingModifierChoice.choiceCount = 0;
        modifierSection.totalChoiceCount = existingModifierSection.offer_section_line_items_list
          .map((mc) => mc.choiceCount || 0)
          .reduce((a, b) => a + b, 0);
      }
      setOfferMenuItemSections((prevModifierSections: OfferMenuItemSection[]) =>
        prevModifierSections.map((ms) =>
          ms.offer_section_id === modifierSection.offer_section_id ? modifierSection : ms,
        ),
      );
    }
  };

  const updateQuantity = (
    modifierSection: OfferMenuItemSection,
    modifierChoice: OfferMenuItemSectionChoice,
    quantity: number,
  ) => {
    const existingModifierSection = offerMenuItemSections.find(
      (ms) => ms.offer_section_id === modifierSection.offer_section_id,
    );
    if (existingModifierSection) {
      const existingModifierChoice = existingModifierSection.offer_section_line_items_list.find(
        (choice) => choice.menu_item_id === modifierChoice.menu_item_id,
      );
      if (existingModifierChoice) existingModifierChoice.choiceCount = quantity;
      modifierSection.totalChoiceCount = existingModifierSection.offer_section_line_items_list
        .map((mc) => mc.choiceCount || 0)
        .reduce((a, b) => a + b, 0);
    }
    setOfferMenuItemSections((prevModifierSections: OfferMenuItemSection[]) =>
      prevModifierSections.map((ms) =>
        ms.offer_section_id === modifierSection.offer_section_id ? modifierSection : ms,
      ),
    );
  };

  const quantitySelectorMaxValue = (
    section: OfferMenuItemSection,
    modifierChoice: OfferMenuItemSectionChoice,
    choiceMax: number,
  ) => {
    const choiceSelectedCount = modifierChoice?.choiceCount || 0;
    const totalChoices =
      section.offer_section_line_items_list
        .filter((modifier) => modifier?.choiceCount && modifier?.choiceCount > 0)
        .map((modifier) => modifier.choiceCount)
        .reduce((a, b) => Number(a) + Number(b), 0) || 0;
    const choicesLeft =
      section.choice_count_type === 'range'
        ? (section.max_choice_count || 0) - totalChoices
        : (section?.equal_to_choice_count || 0) - totalChoices;
    return choiceMax < choicesLeft || choicesLeft + choiceSelectedCount > choiceMax
      ? choiceMax
      : choicesLeft + choiceSelectedCount;
  };

  useEffect(() => {
    if (offerMenuItem) setOfferMenuItemSections(offerMenuItem.offer_section_and_choices_list);
  }, [offerMenuItem]);

  // setRequiredOfferSelected
  useEffect(() => {
    if (offerMenuItemSections.length > 0) {
      const modifiersRequired = offerMenuItemSections.find(
        (section) =>
          (section.totalChoiceCount || 0) < Number(section.min_choice_count || section.equal_to_choice_count),
      );

      if (modifiersRequired) setRequiredOfferSelected(true);
      else {
        const selectedOfferMenuItemIds: number[] = [];
        offerMenuItemSections.forEach((section) => {
          if (section.totalChoiceCount && section.totalChoiceCount > 0)
            section.offer_section_line_items_list.forEach((choice) => {
              if (choice?.choiceCount && choice?.choiceCount > 0)
                selectedOfferMenuItemIds.push(...Array(choice.choiceCount).fill(choice.menu_item_id));
            });
        });
        setSelectedOfferMenuItemIds(selectedOfferMenuItemIds);
        setRequiredOfferSelected(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerMenuItemSections]);

  return (
    <div>
      {offerMenuItemSections.map((section) => (
        <div key={section.offer_section_id}>
          <div className="py-4 font-bold text-lg capitalize">
            {section.offer_section_display_name_en}{' '}
            {section.choice_count_type === 'range' ? (
              <span>
                (select range from {section.min_choice_count} to {section.max_choice_count})
              </span>
            ) : (
              <span>(select {section.equal_to_choice_count})</span>
            )}{' '}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {section.offer_section_line_items_list.map((lineItemChoice) => (
              <div
                key={lineItemChoice.menu_item_id}
                className="bg-white rounded-lg cursor-pointer text-left w-full flex relative"
              >
                <button
                  type="button"
                  className="text-left w-full p-4"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCheckboxChange(
                      lineItemChoice.choiceCount ? !lineItemChoice?.choiceCount : true,
                      section,
                      lineItemChoice,
                    );
                  }}
                  disabled={
                    (section.totalChoiceCount || 0) >=
                      Number(section.max_choice_count || section.equal_to_choice_count) && !lineItemChoice.choiceCount
                  }
                >
                  <Checkbox
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // handleCheckboxChange(e.target.checked, section, lineItemChoice);
                    }}
                    checked={!!lineItemChoice.choiceCount}
                    disabled={
                      (section.totalChoiceCount || 0) >=
                        Number(section.max_choice_count || section.equal_to_choice_count) && !lineItemChoice.choiceCount
                    }
                  >
                    <div>
                      <div className="">
                        <span className="capitalize">{lineItemChoice.display_choice_name}</span>
                        <span className="text-gray-500 text-sm">
                          {' '}
                          {/* TODO: verify if we need to show price {lineItemChoice.menu_item_price > 0 && <>+ {lineItemChoice.menu_item_price}</>} */}
                        </span>
                      </div>
                    </div>
                  </Checkbox>
                </button>
                {lineItemChoice?.choiceCount &&
                lineItemChoice?.choiceCount > 0 &&
                lineItemChoice.max_choice_count !== 1 ? (
                  <div className="absolute right-2 top-1 bg-gray-100 p-1 rounded-full">
                    <QuantityPicker
                      min={1}
                      max={quantitySelectorMaxValue(section, lineItemChoice, lineItemChoice.max_choice_count || 0)}
                      onChange={(quantity) => {
                        updateQuantity(section, lineItemChoice, quantity);
                      }}
                    />
                  </div>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Offer;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { Select } from 'antd';
import { Controller } from 'react-hook-form';

const { Option } = Select;

interface SelectProps {
  options: { value: number; label: string }[];
  name: string;
  control: any;
  defaultValue?: string[];
}

const CustomSelect: React.FC<SelectProps> = ({ options, name, control, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          {...field}
          mode="multiple"
          allowClear
          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 [&_*]:text-black"
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      )}
    />
  );
};

export default CustomSelect;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { TBookingDetail } from '../../../merchant.type';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type BookingTimelineProps = {
  booking: TBookingDetail;
};

type TTimestampKey =
  | 'booking_creation_timestamp'
  | 'booking_advance_payment_timestamp'
  | 'booking_confirmation_timestamp'
  | 'booking_payment_timestamp'
  | 'booking_availed_timestamp'
  | 'booking_expiry_timestamp'
  | 'booking_cancellation_timestamp';

const BookingTimeline: FC<BookingTimelineProps> = ({ booking }) => {
  const [timeline, setTimeline] = useState<any[]>([]);

  const getTimeline = (timestampKey: TTimestampKey) => {
    const timelineItem = {
      target: '',
      href: '#',
      date: booking.timestamps[timestampKey] ? format(new Date(booking.timestamps[timestampKey] || ''), 'MMM dd') : '',
      datetime: booking.timestamps[timestampKey]
        ? format(new Date(booking.timestamps[timestampKey] || ''), 'yyyy-MM-dd')
        : '',
      icon: CheckIcon,
      iconBackground: 'bg-green-300',
    };
    switch (timestampKey) {
      case 'booking_creation_timestamp':
        return {
          ...timelineItem,
          id: 1,
          content: 'Created at',
        };
      case 'booking_advance_payment_timestamp':
        return {
          ...timelineItem,
          id: 2,
          content: 'Advanced payment at',
        };
      case 'booking_confirmation_timestamp':
        return {
          ...timelineItem,
          id: 3,
          content: 'Confirmed at',
        };
      case 'booking_payment_timestamp':
        return {
          ...timelineItem,
          id: 4,
          content: 'Paid at',
        };
      case 'booking_availed_timestamp':
        return {
          ...timelineItem,
          id: 5,
          content: 'Availed at',
        };
      case 'booking_expiry_timestamp':
        return {
          ...timelineItem,
          id: 6,
          content: 'Expired at',
          iconBackground: 'bg-rose-300',
          icon: XCircleIcon,
        };
      case 'booking_cancellation_timestamp':
        return {
          ...timelineItem,
          id: 7,
          content: 'Cancelled at',
          iconBackground: 'bg-rose-300',
          icon: XCircleIcon,
        };
      default:
        return {
          ...timelineItem,
          id: 1,
          content: 'Created at',
        };
    }
  };

  useEffect(() => {
    setTimeline(
      Object.keys(booking.timestamps)
        .filter((key) => booking.timestamps[key as unknown as never] !== null)
        .map((key) => getTimeline(key as TTimestampKey)),
    );
  }, [booking]);

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                    )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5 gap-8">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}{' '}
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    <time dateTime={event.datetime}>{event.date}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BookingTimeline;

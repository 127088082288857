/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMClient from 'react-dom/client';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { cn } from '../../services/utils.service';

type ModalProps = {
  title: string;
  children: React.ReactNode;
  content?: React.ReactNode;
  open: boolean;
  className?: string;
  onClose?: (open: boolean) => void;
  footer?: React.ReactNode | null;
  closable?: boolean;
  hideCloseBtn?: boolean;
};

type ModalType = {
  success: (props: Omit<ModalProps, 'open'>) => void;
  error: (props: Omit<ModalProps, 'open'>) => void;
  warning: (props: Omit<ModalProps, 'open'>) => void;
};

const Modal: React.FC<ModalProps> & ModalType = ({
  children,
  content,
  title,
  open,
  onClose,
  footer = null,
  className,
  closable = true,
  hideCloseBtn,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="purple-theme relative z-[2147483601]" onClose={(closable && onClose) || (() => {})}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="modal fixed inset-0 z-[2147483601] max-w-[100vw]">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'relative mx-auto transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:m-8 w-full sm:w-max sm:min-w-[20rem] sm:p-6',
                  className,
                )}
              >
                {!hideCloseBtn ? (
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => onClose && onClose(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                ) : null}
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-2 max-h-[80vh] overflow-y-auto">{children || content}</div>
                {footer === undefined ? (
                  <div className="mt-5 gap-4 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" className="btn-primary w-48" onClick={() => onClose && onClose(false)}>
                      Ok
                    </button>
                    <button type="button" className="btn-secondary w-48" onClick={() => onClose && onClose(false)}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  footer
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.success = (props: Omit<ModalProps, 'open'>) => {
  const modalContainer = document.createElement('div');
  document.body.appendChild(modalContainer);

  const handleModalClose = () => {
    ReactDOM.unmountComponentAtNode(modalContainer);
    document.body.removeChild(modalContainer);
    if (props.onClose) props.onClose(false);
  };

  const modal = <Modal open {...props} />;

  const modalRoot = ReactDOMClient.createRoot(modalContainer);
  modalRoot.render(modal);

  const modalElement = modalContainer.querySelector('.modal');
  console.log(modalElement);
  modalElement?.addEventListener('click', (event) => {
    console.log(event.target);
    if (event.target === modalElement) {
      handleModalClose();
    }
  });
};

Modal.error = (props: Omit<ModalProps, 'open'>) => {
  const modalContainer = document.createElement('div');
  document.body.appendChild(modalContainer);

  const handleModalClose = () => {
    ReactDOM.unmountComponentAtNode(modalContainer);
    document.body.removeChild(modalContainer);
    if (props.onClose) props.onClose(false);
  };

  const modal = <Modal open {...props} />;

  const modalRoot = ReactDOMClient.createRoot(modalContainer);
  modalRoot.render(modal);

  const modalElement = modalContainer.querySelector('.modal');
  modalElement?.addEventListener('click', (event) => {
    if (event.target === modalElement) {
      handleModalClose();
    }
  });
};

Modal.warning = (props: Omit<ModalProps, 'open'>) => {
  const modalContainer = document.createElement('div');
  document.body.appendChild(modalContainer);

  const handleModalClose = () => {
    ReactDOM.unmountComponentAtNode(modalContainer);
    document.body.removeChild(modalContainer);
    if (props.onClose) props.onClose(false);
  };

  const modal = <Modal open {...props} />;

  const modalRoot = ReactDOMClient.createRoot(modalContainer);
  modalRoot.render(modal);

  const modalElement = modalContainer.querySelector('.modal');
  modalElement?.addEventListener('click', (event) => {
    if (event.target === modalElement) {
      handleModalClose();
    }
  });
};

export default Modal;

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { TBookingAvailability } from '../../../../create-booking.type';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface RadioGroupProps {
  options: TBookingAvailability[];
  name: string;
  control: any;
  defaultValue?: string[];
  setValue: UseFormSetValue<FieldValues>;
}

const TimeSlotRadioGroup: FC<RadioGroupProps> = ({ options, name, control, defaultValue, setValue }) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: 'Please select a time slot' }}
        render={({ field }) => (
          <RadioGroup {...field} className="p-4 flex flex-col gap-4 overflow-y-auto h-[42vh]">
            {options.map((availability) => (
              <div
                className={`grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-4 ${
                  availability.booked ? 'opacity-60 cursor-not-allowed' : ''
                } `}
                key={availability.schedule.schedule_id}
              >
                <RadioGroup.Option
                  key={availability.schedule.schedule_id}
                  value={availability.schedule.schedule_id}
                  className={({ checked, active }) =>
                    classNames(
                      checked ? 'border-transparent' : 'border-gray-50',
                      active ? 'border-primary ring-2 ring-primary' : '',
                      'relative flex cursor-pointer rounded-lg border bg-gray-50 p-4 shadow focus:outline-none',
                      availability.booked ? 'opacity-60 cursor-not-allowed' : '',
                    )
                  }
                  onClick={() => {
                    if (setValue) {
                      setValue('merchantBookingScheduleId', availability.merchant_booking_schedule_id);
                      setValue('bookingStartTime', availability.schedule.from_time?.padStart(8, '0')?.slice(0, -3));
                      setValue('bookingEndTime', availability.schedule.to_time?.padStart(8, '0')?.slice(0, -3));
                    }
                  }}
                  disabled={availability.booked}
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex flex-1 gap-4">
                        <span className="flex flex-col">
                          <RadioGroup.Label as="span" className="block text-sm text-gray-900">
                            {availability.schedule.from_time} - {availability.schedule.to_time}
                          </RadioGroup.Label>
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-primary')}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-primary' : 'border-transparent',
                          'pointer-events-none absolute -inset-px rounded-lg',
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              </div>
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};

export default TimeSlotRadioGroup;

import React, { createContext, useMemo, useState } from 'react';
import { DataLoadingOverlay } from '../modules/common/components';

interface LoadingOverlayContextType {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

export const LoadingOverlayContext = createContext<LoadingOverlayContextType>({
  isLoading: false,
  setLoading: () => {},
});

export const LoadingOverlayProvider: React.FC<{ children: any }> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  return useMemo(
    () => (
      <LoadingOverlayContext.Provider value={{ isLoading, setLoading }}>
        <DataLoadingOverlay isLoading={isLoading} />
        {children}
      </LoadingOverlayContext.Provider>
    ),
    [children, isLoading],
  );
};

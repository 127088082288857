import api, { TApiResponse } from '../../../config/api/api.config';
import { TCustomerOrder } from '../../common/types';

export type TCustomerOrderStatus = {
  customer_order_id: string;
  order_status: string;
  order_cancelled_timestamp: string | null;
  order_accepted_timestamp: string | null;
  order_dispatched_timestamp: string | null;
  order_creation_timestamp: string | null;
};

const orderSummaryApi = {
  getCustomerOrder: (id: string) => api.get(`customer_order/code/${id}`),
  getCustomerOrderDetails: (orderId: number): TApiResponse<TCustomerOrder> => api.get(`customer-order/${orderId}`),
  getCustomerOrderStatus: (orderId: number): TApiResponse<TCustomerOrderStatus> =>
    api.get(`customer-order/${orderId}/status`),
};

export default orderSummaryApi;

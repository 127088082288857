/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-redundant-roles */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { TMerchantDashboard } from '../merchant.type';
import dashboardApi from './dashboard.api';
import { BookingListing, OrderListing } from './components';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type TMerchantStats = {
  name: string;
  value: string | number;
  unit?: string;
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [stats, setStats] = useState<TMerchantStats[]>([]);
  const [dashboardData, setDashboardData] = useState<TMerchantDashboard>();

  const getDashboardData = useQuery('merchant-dashboard', () => dashboardApi.getDashboard(1), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        setDashboardData(data.data);
        setStats([
          { name: 'Number of Bookings', value: data.data.today.total_booking_count },
          { name: 'Number of Orders', value: data.data.today.total_customer_order_count },
          { name: 'Number of Customer served', value: data.data.today.total_customer_served_count },
          { name: 'Revenue', value: data.data.today.total_revenue_amount },
        ]);
      }
    },
  });

  useEffect(() => {
    getDashboardData.refetch();
  }, []);

  return (
    <main className="h-full flex flex-col">
      <header>
        {/* Secondary navigation */}
        {/* <nav className="flex overflow-x-auto border-b border-white/10 py-4">
          <ul
            role="list"
            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
          >
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <a href={item.href} className={item.current ? 'text-primaryLight' : ''}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav> */}

        {/* Heading */}
        <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
          <div className="flex justify-between w-full">
            <div className="flex items-center gap-x-3">
              <h1 className="flex gap-x-3 text-3xl leading-7">
                <span className="font-semibold text-white">Dashboard</span>
              </h1>
            </div>
            <button
              type="button"
              className="order-first flex-none rounded-full bg-primaryLight/10 px-2 py-1 text-xs font-medium text-primaryLight ring-1 ring-inset ring-primaryLight/30 sm:order-none"
              onClick={() => navigate('/merchant/create-booking')}
            >
              New Booking
            </button>
          </div>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-1 bg-gray-700/10 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat, statIdx) => (
            <div
              key={stat.name}
              className={classNames(
                statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8',
              )}
            >
              <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-white">{stat.value}</span>
                {/* {stat.unit ? <span className="text-sm text-gray-400">{stat.unit}</span> : null} */}
              </p>
            </div>
          ))}
        </div>
      </header>

      {/* Activity list */}
      <div className="border-t border-white/10 pt-11 flex flex-col grow overflow-y-auto gap-2">
        <OrderListing />
        <BookingListing />
      </div>
    </main>
  );
};

export default Dashboard;

import api, { TApiResponse } from '../../config/api/api.config';
import { TUserDetails } from '../common/shared.api';

type TValidateOTPPayload = {
  phone_nr: string;
  email: null;
  otp: string;
  intent: 'customer_signup';
};

type TRequestOTPPayload = {
  phone_nr: string;
  email: null;
  intent: 'customer_signup'; // customer_signup
  contact_method: 'sms';
};

const sideMenuApi = {
  generateOTP: (payload: { phone_nr: string }) => api.post(`send-otp`, payload),
  validateOTP: (payload: TValidateOTPPayload): TApiResponse<TUserDetails> => api.post(`verify-otp`, payload),
  requestOPT: (payload: TRequestOTPPayload) => api.post(`request-otp`, payload),
};

export default sideMenuApi;

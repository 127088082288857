/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FC, useContext } from 'react';
import { useIsMount } from '../../hooks';

import './quantity-picker.css';
import { ThemeContext } from '../../../../contexts/theme.context';

type QuantityPickerProps = {
  min: number;
  max: number;
  // eslint-disable-next-line react/require-default-props
  defaultQuantity?: number;
  onChange: (quantity: number) => void;
};

const QuantityPicker: FC<QuantityPickerProps> = ({ min, max, defaultQuantity = min, onChange }) => {
  const { theme } = useContext(ThemeContext);
  const isMount = useIsMount();
  const [quantity, setQuantity] = useState(defaultQuantity);
  let defaultQuantityUpdated = false;

  const increment = () => {
    setQuantity((prev) => prev + 1);
  };

  const decrement = () => {
    setQuantity((prev) => prev - 1);
  };

  useEffect(() => {
    if (!isMount && !defaultQuantityUpdated) {
      onChange(quantity);
    }
    if (defaultQuantityUpdated) defaultQuantityUpdated = false;
  }, [quantity]);

  useEffect(() => {
    defaultQuantityUpdated = true;
    setQuantity(defaultQuantity);
  }, [defaultQuantity]);

  return (
    <span className="quantity-picker">
      <button
        type="button"
        className={`${quantity <= min ? 'mod-disable ' : ''}quantity-modifier modifier-left`}
        disabled={quantity <= min}
        onClick={decrement}
        style={{ backgroundColor: theme.general?.buttonColor || '' }}
      >
        &ndash;
      </button>
      <input className="quantity-display" type="text" value={quantity} readOnly />
      <button
        type="button"
        className={`${quantity >= max ? 'mod-disable ' : ''}quantity-modifier modifier-right`}
        disabled={quantity >= max}
        onClick={increment}
        style={{ backgroundColor: theme.general?.buttonColor || '' }}
      >
        &#xff0b;
      </button>
    </span>
  );
};

export default QuantityPicker;

/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, forwardRef, useImperativeHandle, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export type BookingConfirmationModalHandle = {
  toggleModal: () => void;
};

type BookingConfirmationModalProps = {};

const BookingConfirmationModal = forwardRef<BookingConfirmationModalHandle, BookingConfirmationModalProps>(
  ({}, ref) => {
    const [open, setOpen] = useState(false);

    const cancelButtonRef = useRef(null);

    useImperativeHandle(ref, () => ({
      toggleModal: (value = undefined) => {
        setOpen((prev) => value || !prev);
      },
    }));

    // const handleOk = (data: any) => {
    //   setOpen(false);
    // };

    // const handleCancel = () => {
    //   setOpen(false);
    // };

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-[60] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-slate-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-100">
                        Booking Created
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-sm text-gray-400">
                        Your booking has been created successfully for June 9, 2023 for Table 1.
                      </Dialog.Description>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    >
                      Ok
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  },
);

BookingConfirmationModal.displayName = 'BookingConfirmationModal';

export default BookingConfirmationModal;

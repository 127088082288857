import React, { createContext, useEffect, useMemo, useState } from 'react';
import { TTheme } from '../modules/common/types/common.type';

interface ThemeContextProps {
  theme: TTheme;
  setThemeObject: (pTheme: TTheme) => void;
}

const ThemeContext = createContext<ThemeContextProps>({
  theme: {},
  setThemeObject: () => {},
});

const ThemeContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [theme, setTheme] = useState<TTheme>({});

  // const toggleTheme = () => {
  //   setTheme((prevTheme) => {
  //     const newTheme = prevTheme === 'light' ? 'dark' : 'light';
  //     localStorage.setItem('theme', newTheme);
  //     return newTheme;
  //   });
  // };

  const setThemeObject = (pTheme: TTheme) => {
    localStorage.setItem('theme', JSON.stringify(pTheme));
    setTheme(pTheme);
  };

  useEffect(() => {
    setTheme(JSON.parse(localStorage.getItem('theme') || '{}'));
  }, []);

  return useMemo(
    () => <ThemeContext.Provider value={{ theme, setThemeObject }}>{children}</ThemeContext.Provider>,
    [children, theme],
  );
};

export { ThemeContext, ThemeContextProvider };

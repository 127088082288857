/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { TBookingResource } from '../../../../create-booking.type';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface RadioGroupProps {
  options: TBookingResource[];
  name: string;
  control: any;
  defaultValue?: string[];
}

const ResourceRadioGroup: FC<RadioGroupProps> = ({ options, name, control, defaultValue }) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup {...field} className="flex flex-col gap-4">
            {options.map((resource) => (
              <div key={resource.merchant_resource_id}>
                <RadioGroup.Label className="text-xl font-semibold leading-6 text-text">
                  {resource.merchant_resource_name}
                </RadioGroup.Label>

                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4 pt-2">
                  {resource.children.map((childResource) => (
                    <RadioGroup.Option
                      key={childResource.merchant_resource_id}
                      value={childResource.merchant_resource_id}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-gray-600',
                          active ? 'border-primaryDark ring-2 ring-primaryDark' : '',
                          'relative flex cursor-pointer rounded-lg bg-bgMain p-4 shadow-md focus:outline-none',
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1 gap-4">
                            {childResource?.merchant_resource_image_list?.length > 0 && (
                              <img
                                src={childResource.merchant_resource_image_list[0]}
                                alt=""
                                className="flex-shrink-0 w-28 h-24 bg-cover rounded shadow-md"
                              />
                            )}
                            <span className="flex flex-col gap-2">
                              <RadioGroup.Label as="span" className="block text-lg text-text capitalize">
                                {childResource.merchant_resource_name}
                              </RadioGroup.Label>
                              {childResource.merchant_resource_attribute_list &&
                                childResource.merchant_resource_attribute_list?.length > 0 && (
                                  <RadioGroup.Description as="span" className="block text-lg text-text capitalize">
                                    Seating Capacity:{' '}
                                    {
                                      childResource.merchant_resource_attribute_list.find(
                                        (item) => item.merchant_resource_attribute_name === 'seating_capacity',
                                      )?.merchant_resource_attribute_value
                                    }
                                  </RadioGroup.Description>
                                )}
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-primaryDark')}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-primaryDark' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg',
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </div>
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};

export default ResourceRadioGroup;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useEffect, useRef, useState } from 'react';
import {
  CalendarDaysIcon,
  // CogIcon,
  // DocumentCheckIcon,
  DocumentMagnifyingGlassIcon,
  // ShoppingBagIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  BookingConfirmation,
  BookingConfirmationModal,
  BookingConfirmationModalHandle,
  // BookingMenu,
  BookingResource,
  // BookingSummary,
  // BookingType,
  TimeSlotCalendar,
} from './components';
import createBookingApi from './create-booking.api';
import { LoadingOverlayContext } from '../../contexts/loading-overlay.context';

type TStep = {
  name: string;
  allowedTypes: number[];
  content: JSX.Element;
  icon: any;
};

const CreateBooking = () => {
  const { setLoading } = useContext(LoadingOverlayContext);
  const navigate = useNavigate();
  const bookingConfirmationModalRef = useRef<BookingConfirmationModalHandle>(null);
  const URLParams = new URLSearchParams(window.location.search);
  const paramsBookingType = Number(URLParams.get('bookingType'));
  const paramsCurrentStep = Number(URLParams.get('currentStep'));
  // const [isLoading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(paramsCurrentStep || 1);
  const [selectedBookingType, setSelectedBookingType] = useState(paramsBookingType || 2);

  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = useForm();
  const [stepList, setStepList] = useState<any[]>([]);

  const onSubmit = async (formData: any) => {
    setLoading(true);
    const { data, status } = await createBookingApi.createCustomer({
      customer_first_names: formData.firstName,
      customer_last_name: formData.lastName,
      customer_gender: null,
      date_of_birth: null,
      email: formData.email || null,
      phone_nr: formData.phoneNumber,
      verification_code: null,
      customer_image_bucket_name: null,
      customer_image_object_key: null,
    });

    if (status === 200 && data?.customer_id) {
      const { data: bookingData, status: bookingStatus } = await createBookingApi.createBooking({
        merchant_booking_schedule_id: formData.merchantBookingScheduleId,
        schedule_id: formData.scheduleId,
        customer_id: data?.customer_id,
        booking_date: formData.selectedDay,
        booking_start_time: formData.bookingStartTime,
        booking_end_time: formData.bookingEndTime,
        merchant_resource_id_list: formData.bookingResourceId === 0 ? [] : [formData.bookingResourceId],
        item_list: [],
        offer_list: [],
      });

      if (bookingStatus === 200 && bookingData?.status === 'successful') {
        message.success('Booking created successfully');
        navigate('/merchant/bookings');
      } else message.error('Something went wrong. Please try again after sometime or contact support for help.');
    }
    setLoading(false);
  };

  const onBookingTypeChange = (value: number) => {
    setSteps(stepList.filter((step) => step.allowedTypes.includes(value)));
    setSelectedBookingType(value);
  };

  useEffect(() => {
    if (stepList.length === 0 && register && control && setValue) {
      setValue('bookingResourceId', 0);
      setStepList([
        // {
        //   name: 'Booking Type',
        //   allowedTypes: [1, 2, 3, 4],
        //   content: <BookingType onBookingTypeChange={onBookingTypeChange} selectedBookingType={selectedBookingType} />,
        //   icon: CogIcon,
        // },
        // {
        //   name: 'Menu Item Booking',
        //   allowedTypes: [3, 4],
        //   content: <BookingMenu />,
        //   icon: ShoppingBagIcon,
        // },
        {
          name: 'Booking Resource',
          allowedTypes: [2, 4],
          content: <BookingResource control={control} />,
          icon: UserGroupIcon,
        },
        {
          name: 'Time Slot',
          allowedTypes: [1, 2, 3, 4],
          content: <TimeSlotCalendar control={control} setValue={setValue} watch={watch} trigger={trigger} />,
          icon: CalendarDaysIcon,
        },
        {
          name: 'Confirmation',
          allowedTypes: [1, 2, 3, 4],
          content: <BookingConfirmation register={register} control={control} />,
          icon: DocumentMagnifyingGlassIcon,
        },
        // { name: 'Summary', allowedTypes: [1, 2, 3, 4], content: <BookingSummary />, icon: DocumentCheckIcon },
      ]);
    }
  }, [register, control, setValue]);

  useEffect(() => {
    if (stepList) setSteps(stepList.filter((step) => step.allowedTypes.includes(selectedBookingType)));
  }, [stepList]);

  const [steps, setSteps] = useState<TStep[]>(
    stepList.filter((step) => step.allowedTypes.includes(selectedBookingType)),
  );

  const listClasses = (index: number) => {
    if (index === steps.length - 1) return 'flex items-center';
    if (index === steps.length) {
      if (index <= currentStep)
        return "flex items-center w-full before:content-[''] before:w-full before:h-1 before:border-b before:border-primaryDark before:border-4 before:inline-block";
      return "flex items-center w-full before:content-[''] before:w-full before:h-1 before:border-b before:border-primaryLight/20 before:border-4 before:inline-block";
    }
    if (index < currentStep)
      return "flex w-full items-center text-gray-600 dark:text-gray-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-primaryDark after:border-4 after:inline-block";
    return "flex w-full items-center text-gray-600 dark:text-gray-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-primaryLight/20 after:border-4 after:inline-block";
  };

  const spanClasses = (index: number) => {
    if (index <= currentStep)
      return 'flex items-center justify-center w-10 h-10 bg-primary rounded-full lg:h-12 lg:w-12 shrink-0';
    return 'flex items-center justify-center w-10 h-10 bg-primaryLight/20 rounded-full lg:h-12 lg:w-12 shrink-0';
  };

  useEffect(() => {
    if (watch('bookingResourceId') !== 0) setCurrentStep(currentStep + 1);
  }, [watch('bookingResourceId')]);

  return (
    <div className="bg-bgMain h-fill p-5">
      <form className="py-4 h-fill overflow-y-auto flex flex-col justify-between rounded bg-bgSidebar">
        <BookingConfirmationModal ref={bookingConfirmationModalRef} />
        <ol className="flex items-center w-full justify-between p-4">
          {steps.map((step, index) => (
            <li key={step.name} className={listClasses(index + 1)}>
              <span className={spanClasses(index + 1)}>
                <step.icon width={28} color="white" />
              </span>
            </li>
          ))}
        </ol>
        <div className="h-auto overflow-y-auto">{steps[currentStep - 1]?.content}</div>
        <div className="flex gap-2 justify-end p-4">
          <button
            type="button"
            className="rounded bg-slate-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={currentStep === 1}
            onClick={() => {
              if (currentStep === 2) onBookingTypeChange(selectedBookingType);
              setCurrentStep(currentStep - 1);
            }}
          >
            Back
          </button>
          <button
            type="button"
            className="rounded bg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => {
              if (currentStep !== steps.length) setCurrentStep(currentStep + 1);
              else {
                handleSubmit(onSubmit)();
                // setLoading(true);
                // setTimeout(() => {
                //   setLoading(false);
                //   bookingConfirmationModalRef.current?.toggleModal();
                // }, 1000);
              }
            }}
            disabled={!isValid}
          >
            {currentStep === steps.length ? 'Confirm' : 'Continue'}
          </button>
          <button
            type="button"
            className="rounded bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => navigate('/merchant/bookings')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateBooking;

import api from '../../config/api/api.config';

type TBookingAvailabilityPayload = {
  merchant_id: number;
  merchant_resource_id_list: number[];
  menu_item_id_list: number[];
  start_date: string | null;
  end_date: string | null;
  start_time: string | null;
  end_time: string | null;
};

type TCreateCustomerPayload = {
  customer_first_names: string;
  customer_last_name: string;
  customer_gender: string | null;
  date_of_birth: string | null;
  email: string | null;
  phone_nr: string;
  verification_code: string | null;
  customer_image_bucket_name: string | null;
  customer_image_object_key: string | null;
};

type TCreateBookingPayload = {
  merchant_booking_schedule_id: number;
  schedule_id: number;
  customer_id: number;
  booking_date: string;
  booking_start_time: string;
  booking_end_time: string;
  merchant_resource_id_list: number[];
  item_list: any[];
  offer_list: any[];
};

const createBookingApi = {
  getBookingResources: (merchantId: number) => api.get(`merchant/${merchantId}/merchant_resource`),
  getBookingAvailability: (payload: TBookingAvailabilityPayload) => api.post(`merchant_booking_availability`, payload),
  createCustomer: (payload: TCreateCustomerPayload) => api.post(`customer`, payload),
  createBooking: (payload: TCreateBookingPayload) => api.post(`booking`, payload),
};

export default createBookingApi;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PublicRestrictedRoutes, IBlinkMarketplaceRoutes, MerchantRoutes } from '..';
import {
  Basket,
  Brand,
  Checkout,
  CreateBooking,
  Landing,
  Main,
  OrderSummary,
  OrderType,
  ProductDetail,
} from '../../../modules';
import ErrorPage from '../../../modules/error-page/ErrorPage.component';
import {
  BookingCalendar,
  BookingDetail,
  BookingListing,
  BookingScheduleListing,
  CreateBookingSchedule,
  Dashboard,
  MerchantLogin,
  MerchantResourceListing,
  ModifyMerchantResource,
} from '../../../modules/merchant';
// import MyOrders from '../../../modules/orders/my-orders/my-orders.component';
// import Orders from '../../../modules/orders/track-orders/order.component';

const RoutesContainer: React.FC = () => {
  return (
    <Routes>
      <Route element={<PublicRestrictedRoutes />}>
        <Route element={<IBlinkMarketplaceRoutes />}>
          <Route path="/" element={<Landing />} />
          <Route path="/brands" element={<Main />} />
          <Route path="/menu" element={<Brand />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/basket" element={<Basket />} />
          <Route path="/checkout" element={<Checkout />} />
          {/* <Route path="/orders" element={<Orders />} /> */}
          {/* <Route path="/my-orders" element={<MyOrders />} /> */}
          <Route path="/order-summary" element={<OrderSummary />} />
          {/* <Route path="/payment" element={<Payment />} /> */}
        </Route>
        <Route path="/order-type" element={<OrderType />} />
        <Route path="/merchant" element={<MerchantLogin />} />
        <Route element={<MerchantRoutes />}>
          <Route path="/merchant/dashboard" element={<Dashboard />} />
          <Route path="/merchant/bookings" element={<BookingListing />} />
          <Route path="/merchant/booking/:id" element={<BookingDetail />} />
          <Route path="/merchant/booking-calendar" element={<BookingCalendar />} />
          <Route path="/merchant/resource-listing" element={<MerchantResourceListing />} />
          <Route path="/merchant/create-booking" element={<CreateBooking />} />
          <Route path="/merchant/booking-schedule-listing" element={<BookingScheduleListing />} />
          <Route path="/merchant/create-booking-schedule" element={<CreateBookingSchedule />} />
          <Route path="/merchant/modify-resource" element={<ModifyMerchantResource />} />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default RoutesContainer;

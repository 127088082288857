/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useState } from 'react';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type TBookingConfirmationProps = {
  register: UseFormRegister<FieldValues>;
  control: any;
};

const BookingConfirmation: FC<TBookingConfirmationProps> = ({ register, control }) => {
  const [phoneNumber, setPhoneNumber] = useState<any>();

  return (
    <div className="mx-auto p-5 w-1/2">
      <div className="border-b border-white/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-text">Customer Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">Use a valid phone number and email.</p>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-text">
              First names
            </label>
            <div className="mt-2">
              <input
                type="text"
                {...register('firstName', { required: true })}
                id="firstName"
                autoComplete="given-name"
                className="block w-full rounded-md border border-inputBorder bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-text">
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                {...register('lastName', { required: true })}
                id="last-name"
                autoComplete="family-name"
                className="block w-full rounded-md border border-inputBorder bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-text">
              Email address (optional)
            </label>
            <div className="mt-2">
              <input
                id="email"
                {...register('email')}
                type="email"
                autoComplete="email"
                className="block w-full rounded-md border border-inputBorder bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2 sm:col-start-1">
            <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-text">
              Phone number
            </label>
            <div className="mt-2">
              <PhoneInputWithCountry
                className="[&>input]:bg-white/5 [&>input]:block [&>input]:w-full [&>input]:rounded-md [&>input]:border [&>input]:border-inputBorder [&>input]:py-1.5 [&>input]:text-text [&>input]:shadow-sm [&>input]:ring-1 [&>input]:ring-inset [&>input]:ring-white/10 [&>input]:focus:ring-2 [&>input]:focus:ring-inset [&>input]:focus:!ring-primary [&>input]:sm:text-sm [&>input]:sm:leading-6"
                name="phoneNumber"
                control={control}
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry="AE"
                rules={{ required: true }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { DatePicker } from 'antd';
import { Controller } from 'react-hook-form';

const { RangePicker } = DatePicker;

interface DateRangePickerProps {
  name: string;
  control: any;
  defaultValue?: string[];
}

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({ name, control, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => <RangePicker {...field} />}
    />
  );
};

export default CustomDateRangePicker;

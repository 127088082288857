/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BrandsGrid from './components/brands-grid/brands-grid.component';
import { TMerchantBranch } from '../common/types';

const Main: React.FC = () => {
  const [brands, setBrands] = useState<TMerchantBranch[]>([]);

  useEffect(() => {
    setBrands(
      localStorage.getItem('merchantBranches') ? JSON.parse(localStorage.getItem('merchantBranches') || '[]') : [],
    );
  }, []);

  return (
    <div className="p-2 md:p-6 lg:p-6 bg-white">
      {/* Cuisines */}
      {/* <HorizontalSlider title="Cuisines" imagesArray={cuisines} /> */}
      {/* Special Offers */}
      {/* <HorizontalSlider
        title="Special Offers"
        spacing="snap-x touch-auto grid-cols-[repeat(auto-fill,minmax(210px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(220px,1fr))] grid-flow-col auto-cols-[minmax(210px,1fr)] md:auto-cols-[minmax(220px,1fr)]"
        imageContainerSize="snap-start h-36 w-52 md:h-40 md:w-56"
        imageSize="h-36 w-52 md:h-32 md:w-56"
        imagesArray={offers}
        showImageName
      /> */}
      {/* Brands */}
      <BrandsGrid title="Restaurants" gridData={brands} />
    </div>
  );
};

export default Main;

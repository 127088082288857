import { TApiResponse } from '../../common/types/common.type';
import { TMerchantBooking, TMerchantDashboard, TMerchantOrder } from '../merchant.type';

const dashboardApi = {
  // GET /merchant-dashboard/<merchant_id>
  getDashboard: (merchantId: number) => ({
    status: 200,
    data: {
      data: {
        currency_id: 1,
        currency_name: 'AED',
        today: {
          total_revenue_amount: 1234.05,
          total_customer_order_count: 1234,
          total_booking_count: 1234,
          total_customer_served_count: 1234,
        },
        last_month_today: {
          total_revenue_amount: 1234.05,
          total_customer_order_count: 1234,
          total_booking_count: 1234,
          total_customer_served_count: 1234,
        },
        month_to_date: {
          total_revenue_amount: 1234.05,
          total_customer_order_count: 1234,
          total_booking_count: 1234,
          total_customer_served_count: 1234,
        },
      },
      action: 'get_merchant_dashboard',
      status: 'successful',
    },
  }),
  // GET /bookings?merchant_id=1&limit=10&booking_date>=2023-02-01
  getBookings: (merchantId: number) => ({
    status: 200,
    data: {
      data: [] as TMerchantBooking[],
      action: 'search_bookings_by_filter',
      status: 'successful',
    },
  }),
  // GET /customer_orders?merchant_id=1&limit=10&
  getOrders: (merchantId: number) => ({
    status: 200,
    data: {
      data: [
        {
          customer_order_id: 1,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 10:00:00',
          order_status: 'approved',
          actual_payable_amount_total: 100,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
        {
          customer_order_id: 2,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 13:00:00',
          order_status: 'pending',
          actual_payable_amount_total: 150,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'partially_paid',
        },
        {
          customer_order_id: 3,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
        {
          customer_order_id: 4,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'unpaid',
        },
        {
          customer_order_id: 5,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
        {
          customer_order_id: 6,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
        {
          customer_order_id: 7,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
        {
          customer_order_id: 8,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
        {
          customer_order_id: 9,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
        {
          customer_order_id: 10,
          brand_id: 1,
          brand_name: 'brand 1',
          external_order_reference_nr: '1234567890',
          facility_id: 1,
          facility_name: 'facility 1',
          order_creation_timestamp: '2021-01-01 15:00:00',
          order_status: 'rejected',
          actual_payable_amount_total: 200,
          currency_id: 1,
          currency_name: 'AED',
          payment_status: 'paid',
        },
      ] as TMerchantOrder[],
      action: 'search_customer_order_by_filter',
      status: 'successful',
    },
  }),
};

export default dashboardApi;

/* eslint-disable no-nested-ternary */
import React, { useContext, useImperativeHandle, useState } from 'react';
import { Drawer } from 'antd';
import './sidemenu.css';
import { TrashIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { BasketView } from '../basket/components';
import { ThemeContext } from '../../contexts/theme.context';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';

export type DrawerHandle = {
  toggleDrawer: () => void;
};

const SideMenu = React.forwardRef<DrawerHandle>((_props, ref) => {
  const { theme } = useContext(ThemeContext);
  const [isVisible, setIsVisible] = useState(false);
  const { orderCreationStore, setOrderCreationStore } = useOrderCreationStore();
  useImperativeHandle(ref, () => ({
    toggleDrawer: () => {
      setIsVisible((prev) => !prev);
    },
  }));

  const onClose = () => {
    setIsVisible(false);
  };

  return (
    <Drawer
      title={
        <div
          className="flex gap-2 mr-[-1rem] justify-end"
          style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
        >
          <button
            type="button"
            className="px-2 capitalize w-1/2 py-2 m-1 text-white bg-colorPrimary rounded-md hover:bg-colorSecondary flex gap-1 justify-center items-center"
            onClick={() => {
              setOrderCreationStore({
                ...orderCreationStore,
                orderTicket: {
                  ...orderCreationStore.orderTicket,
                  item_list: [],
                },
              });
            }}
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
          >
            <TrashIcon width={16} />
            <span className="text-sm font-normal">Clear basket</span>
          </button>
        </div>
      }
      placement="right"
      onClose={onClose}
      open={isVisible}
      bodyStyle={{ padding: '0' }}
      style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
      width={500}
      closeIcon={<XMarkIcon width={32} color={theme?.general?.textColor || 'black'} />}
    >
      <BasketView hideDrawer={onClose} />
    </Drawer>
  );
});
SideMenu.displayName = 'SideMenu';

export default SideMenu;

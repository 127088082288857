/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, forwardRef, useImperativeHandle, Fragment, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';

type TResourceTimeSlot = {
  resource: {
    value: number;
    label: string | undefined;
  };
  timeSlots: { min: number; max: number; color: string }[];
};

export type AddTimeSlotModalHandle = {
  toggleModal: (resource: TResourceTimeSlot) => void;
};

type AddTimeSlotModalProps = {
  addTimeSlot: (resource: TResourceTimeSlot, timeSlot: { timeRangeStart: number; timeRangeEnd: number }) => void;
};

const AddTimeSlotModal = forwardRef<AddTimeSlotModalHandle, AddTimeSlotModalProps>(({ addTimeSlot }, ref) => {
  const [open, setOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState<TResourceTimeSlot>();
  const [existingTimeSlots, setExistingTimeSlots] = useState<number[]>([]);

  const cancelButtonRef = useRef(null);
  const { register, handleSubmit, watch, reset, setValue } = useForm();

  useImperativeHandle(ref, () => ({
    toggleModal: (resource, value = undefined) => {
      setSelectedResource(resource);
      setExistingTimeSlots(
        resource?.timeSlots.flatMap((timeSlot) =>
          Array.from({ length: timeSlot.max - timeSlot.min + 1 }, (_, i) => i + timeSlot.min),
        ),
      );
      setOpen((prev) => value || !prev);
    },
  }));

  const timeRangeStart = watch('timeRangeStart');
  // const nextUnavailableTimeSlot = existingTimeSlots?.find((slot) => slot > timeRangeStart);

  const handleOk = (data: any) => {
    if (selectedResource) addTimeSlot(selectedResource, data);
    reset();
    setOpen(false);
  };

  useEffect(() => {
    if (existingTimeSlots) {
      const availableTimeSlots = Array.from(Array(24).keys()).find(
        (i) => !(existingTimeSlots?.includes(i) && existingTimeSlots?.includes(i + 1)),
      );
      setValue('timeRangeStart', availableTimeSlots);
      setValue('timeRangeEnd', (availableTimeSlots || 0) + 1);
    }
  }, [existingTimeSlots]);

  useEffect(() => {
    if (timeRangeStart) {
      setValue('timeRangeEnd', (Number(timeRangeStart) || 0) + 1);
    }
  }, [timeRangeStart]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60] merchant-default-theme"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[60] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-bgSidebar px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form id="AddTimeSlotModalForm">
                  <div className="">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-text">
                        Add Time Slot
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="border-b border-white/10 pb-12">
                          <div className="mt-10 gap-x-6 gap-y-8 flex flex-col">
                            {/* <div className="flex w-fill gap-5 items-center">
                              <label htmlFor="slotType" className="block text-sm font-medium leading-6 text-text">
                                Time Slot Type
                              </label>
                              <div className="">
                                <select
                                  id="slotType"
                                  {...register('slotType', { required: true })}
                                  autoComplete="slotType"
                                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 [&_*]:text-black"
                                >
                                  <option>Fixed</option>
                                  <option>Relative</option>
                                  <option>Custom</option>
                                </select>
                              </div>
                            </div> */}
                            {/* <div className="flex w-fill gap-5 items-center">
                              <label
                                htmlFor="timeRangeValue"
                                className="block text-sm font-medium leading-6 text-text"
                              >
                                Time Range
                              </label>
                              <div className="flex gap-2">
                                <select
                                  id="timeRangeValue"
                                  {...register('timeRangeValue', { required: true })}
                                  autoComplete="timeRangeValue"
                                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 [&_*]:text-black"
                                >
                                  <option>15</option>
                                  <option>30</option>
                                  <option>45</option>
                                  <option>60</option>
                                </select>
                                <select
                                  id="timeRangeType"
                                  {...register('timeRangeType', { required: true })}
                                  autoComplete="timeRangeType"
                                  className="block w-fill rounded-md border-0 bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 [&_*]:text-black"
                                >
                                  <option>minute</option>
                                  <option>hour</option>
                                  <option>second</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="flex w-fill gap-5 items-center">
                              <label htmlFor="timeRangeValue" className="block text-sm font-medium leading-6 text-text">
                                Time Range
                              </label>
                              <div className="flex gap-0 justify-start h-9 items-center bg-white/5 rounded-md">
                                <select
                                  id="timeRangeStart"
                                  {...register('timeRangeStart', { required: true })}
                                  autoComplete="timeRangeStart"
                                  className="block w-auto border-0 rounded-md bg-white/5 py-1.5 text-text shadow-sm sm:text-sm sm:leading-6 [&_*]:text-black ring-0 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary"
                                >
                                  {Array.from(Array(24).keys()).map((i) => (
                                    <>
                                      <option
                                        key={i}
                                        value={i}
                                        disabled={existingTimeSlots?.includes(i) && existingTimeSlots?.includes(i + 1)}
                                        className="disabled:text-gray-500"
                                      >
                                        {i.toLocaleString('en-US', {
                                          minimumIntegerDigits: 2,
                                          useGrouping: false,
                                        })}{' '}
                                        : 00 -{' '}
                                        {(i + 1).toLocaleString('en-US', {
                                          minimumIntegerDigits: 2,
                                          useGrouping: false,
                                        })}{' '}
                                        : 00
                                      </option>
                                      {/* <option key={`${i}-30`}>
                                      {i.toLocaleString('en-US', {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false,
                                      })}{' '}
                                      : 30
                                    </option> */}
                                    </>
                                  ))}
                                </select>
                                {/* <div className="bg-white/5 h-fill flex items-center">
                                  <ChevronRightIcon width={48} height={20} className="text-text" />
                                </div>
                                <select
                                  id="timeRangeEnd"
                                  {...register('timeRangeEnd', { required: true })}
                                  autoComplete="timeRangeEnd"
                                  className="block w-auto border-0 bg-white/5 py-1.5 text-text shadow-sm sm:text-sm sm:leading-6 [&_*]:text-black rounded-r-md ring-0 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary"
                                >
                                  {Array.from(Array(25).keys()).map((i) => (
                                    <option
                                      key={i}
                                      value={i}
                                      disabled={
                                        Number(timeRangeStart) >= i ||
                                        (existingTimeSlots?.includes(i) && existingTimeSlots?.includes(i - 1)) ||
                                        (nextUnavailableTimeSlot || i) < i
                                      }
                                      className="disabled:text-gray-500"
                                    >
                                      {i.toLocaleString('en-US', {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false,
                                      })}{' '}
                                      : 00
                                    </option>
                                  ))}
                                </select> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      form="AddTimeSlotModalForm"
                      type="button"
                      onClick={handleSubmit(handleOk)}
                      className="inline-flex w-full justify-center rounded-md bg-primaryDark px-3 py-2 text-sm font-semibold text-textLight shadow-sm hover:bg-primary sm:ml-3 sm:w-auto"
                    >
                      Add Time Slot
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

AddTimeSlotModal.displayName = 'AddTimeSlotModal';

export default AddTimeSlotModal;

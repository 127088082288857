/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { FC, useContext, useEffect } from 'react';
import { Checkbox } from 'antd';
import { MenuItem } from '../../product-detail.type';
import { QuantityPicker } from '../../../common/components';
import { TMenuItem, TModifierChoice, TModifierSection } from '../../../common/types';
import utils from '../../../common/services/utils.service';
import { ThemeContext } from '../../../../contexts/theme.context';

type Props = {
  containerMargin?: string;
  item: TMenuItem;
  modifierSections: TModifierSection[];
  setModifierSections: React.Dispatch<React.SetStateAction<TModifierSection[]>> | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  setOfferSelectedMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>> | undefined;
  setRequiredModifierSelected: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line react/no-unused-prop-types
  offerMenuItemIndex: number | undefined;
};

const Modifier: FC<Props> = ({
  containerMargin,
  item,
  modifierSections,
  setModifierSections,
  setRequiredModifierSelected,
}) => {
  const { theme } = useContext(ThemeContext);
  const handleCheckboxChange = (
    checked: boolean,
    modifierSection: TModifierSection,
    modifierChoice: TModifierChoice,
  ) => {
    // If checked, add the modifier choice to the selectedModifierSections array
    if (checked) {
      const existingModifierSection = modifierSections?.find(
        (ms) => ms.modifier_section_id === modifierSection.modifier_section_id,
      );
      if (existingModifierSection) {
        const existingModifierChoice = existingModifierSection.modifier_choices.find(
          (choice) => choice.modifier_choice_id === modifierChoice.modifier_choice_id,
        );
        if (existingModifierChoice) existingModifierChoice.choiceCount = (existingModifierChoice.choiceCount || 0) + 1;
        modifierSection.totalChoiceCount = existingModifierSection.modifier_choices
          .map((mc) => mc.choiceCount || 0)
          .reduce((a, b) => a + b, 0);
      }
      if (setModifierSections)
        setModifierSections(
          (prevModifierSections: TModifierSection[] | undefined) =>
            prevModifierSections?.map((ms) =>
              ms.modifier_section_id === modifierSection.modifier_section_id ? modifierSection : ms,
            ) || [],
        );
    } else {
      const existingModifierSection = modifierSections?.find(
        (ms) => ms.modifier_section_id === modifierSection.modifier_section_id,
      );
      if (existingModifierSection) {
        const existingModifierChoice = existingModifierSection.modifier_choices.find(
          (choice) => choice.modifier_choice_id === modifierChoice.modifier_choice_id,
        );
        if (existingModifierChoice) existingModifierChoice.choiceCount = 0;
        modifierSection.totalChoiceCount = existingModifierSection.modifier_choices
          .map((mc) => mc.choiceCount || 0)
          .reduce((a, b) => a + b, 0);
      }
      if (setModifierSections)
        setModifierSections(
          (prevModifierSections: TModifierSection[] | undefined) =>
            prevModifierSections?.map((ms) =>
              ms.modifier_section_id === modifierSection.modifier_section_id ? modifierSection : ms,
            ) || [],
        );
    }
  };

  const updateQuantity = (modifierSection: TModifierSection, modifierChoice: TModifierChoice, quantity: number) => {
    const existingModifierSection = modifierSections?.find(
      (ms) => ms.modifier_section_id === modifierSection.modifier_section_id,
    );
    if (existingModifierSection) {
      const existingModifierChoice = existingModifierSection.modifier_choices.find(
        (choice) => choice.modifier_choice_id === modifierChoice.modifier_choice_id,
      );
      if (existingModifierChoice) existingModifierChoice.choiceCount = quantity;
      modifierSection.totalChoiceCount = existingModifierSection.modifier_choices
        .map((mc) => mc.choiceCount || 0)
        .reduce((a, b) => a + b, 0);
    }
    if (setModifierSections)
      setModifierSections(
        (prevModifierSections: TModifierSection[] | undefined) =>
          prevModifierSections?.map((ms) =>
            ms.modifier_section_id === modifierSection.modifier_section_id ? modifierSection : ms,
          ) || [],
      );
  };

  const quantitySelectorMaxValue = (section: TModifierSection, modifierChoice: TModifierChoice, choiceMax: number) => {
    const choiceSelectedCount = modifierChoice?.choiceCount || 0;
    const totalChoices =
      section.modifier_choices
        .filter((modifier) => modifier?.choiceCount && modifier?.choiceCount > 0)
        .map((modifier) => modifier.choiceCount)
        .reduce((a, b) => Number(a) + Number(b), 0) || 0;
    const choicesLeft = (section.max_choice_count || 0) - totalChoices;
    return choiceMax < choicesLeft || choicesLeft + choiceSelectedCount > choiceMax
      ? choiceMax
      : choicesLeft + choiceSelectedCount;
  };

  useEffect(() => {
    if (setModifierSections)
      setModifierSections(
        item?.modifier_sections.map((modifier) => ({
          ...modifier,
          totalChoiceCount: modifier.modifier_choices
            .map((mc) => Number(mc.choiceCount || mc.default_quantity || 0))
            .reduce((a, b) => a + b, 0),
          modifier_choices: modifier.modifier_choices.map((modifierChoice) => ({
            ...modifierChoice,
            choiceCount: modifierChoice.choiceCount || modifierChoice.default_quantity || 0,
          })),
        })),
      );
  }, [item]);

  useEffect(() => {
    if (modifierSections && modifierSections?.length > 0) {
      const modifiersRequired = modifierSections?.find(
        (modifierSection) => (modifierSection.totalChoiceCount || 0) < Number(modifierSection.min_choice_count),
      );
      if (modifiersRequired) setRequiredModifierSelected(true);
      else setRequiredModifierSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifierSections]);

  return (
    <div className={containerMargin}>
      {modifierSections?.map((section) => (
        <div key={section.modifier_section_id}>
          <div className="p-4 font-bold text-lg capitalize">
            {section.modifier_section_name_en} (select from {section.min_choice_count} to {section.max_choice_count} -{' '}
            {section.section_type})
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {section.modifier_choices.map((modifierChoice) => (
              <div
                key={modifierChoice.modifier_choice_id}
                className="bg-white rounded-lg cursor-pointer text-left w-full flex relative"
                style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
              >
                <button
                  type="button"
                  className="text-left w-full p-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCheckboxChange(
                      modifierChoice.choiceCount ? !modifierChoice?.choiceCount : true,
                      section,
                      modifierChoice,
                    );
                  }}
                  disabled={
                    (section.totalChoiceCount || 0) >= Number(section.max_choice_count) && !modifierChoice.choiceCount
                  }
                >
                  <Checkbox
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // handleCheckboxChange(e.target.checked, section, modifierChoice);
                    }}
                    checked={!!modifierChoice.choiceCount}
                    disabled={
                      (section.totalChoiceCount || 0) >= Number(section.max_choice_count) && !modifierChoice.choiceCount
                    }
                  >
                    <div>
                      <div className="">
                        <span className="capitalize" style={{ color: theme?.general?.textColor || '' }}>
                          {modifierChoice.modifier_choice_name_en}
                        </span>
                        <span
                          className="text-gray-500 text-sm"
                          style={{ color: theme.general?.descriptionTextColor || '' }}
                        >
                          {' '}
                          {![0, null].includes(modifierChoice.one_unit_price) ? (
                            <>+ {utils.formatCurrency(modifierChoice.one_unit_price, item.currency_alpha_3)}</>
                          ) : (
                            <>Free</>
                          )}
                        </span>
                      </div>
                    </div>
                  </Checkbox>
                </button>
                {modifierChoice?.choiceCount &&
                modifierChoice?.choiceCount > 0 &&
                modifierChoice.max_choice_count !== 1 ? (
                  <div className="absolute right-2 top-1 bg-gray-100 p-1 rounded-full">
                    <QuantityPicker
                      min={1}
                      max={quantitySelectorMaxValue(section, modifierChoice, modifierChoice.max_choice_count)}
                      onChange={(quantity) => {
                        updateQuantity(section, modifierChoice, quantity);
                      }}
                    />
                  </div>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

Modifier.defaultProps = {
  containerMargin: '',
};

export default Modifier;

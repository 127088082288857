import { create } from 'zustand';
import { TUserDetails } from '../common/shared.api';
import api from '../../config/api/api.config';
import { xAccessToken, xApiKey, xUserId } from '../../config/variables/system.variable';

interface CurrentUserState {
  currentUser?: TUserDetails | null;
  setCurrentUser: (currentUser: TUserDetails | undefined) => void;
}

const useCurrentUser = create<CurrentUserState>((set) => {
  const existingUser = localStorage.getItem('customerDetails');
  const iBlinkMarketplaceUser = localStorage.getItem('iBlinkMarketplaceUser');

  if (iBlinkMarketplaceUser) {
    api.setAuthenticationHeader({
      token: JSON.parse(iBlinkMarketplaceUser).token,
      id: JSON.parse(iBlinkMarketplaceUser).id,
      apiKey: JSON.parse(iBlinkMarketplaceUser).apiKey,
    });
  } else {
    api.setAuthenticationHeader({ token: xAccessToken || '', id: xUserId || '', apiKey: xApiKey || '' });
  }
  return {
    currentUser: existingUser ? JSON.parse(existingUser || '{}') : null,
    setCurrentUser: (currentUser: TUserDetails | undefined) => set((_state) => ({ currentUser })),
  };
});

window.addEventListener('currentUserUpdated', (event: any) => {
  const eventData = event.detail;
  if (JSON.stringify(eventData) === JSON.stringify(useCurrentUser.getState().currentUser)) return;
  useCurrentUser.setState({ currentUser: eventData });
});

export default useCurrentUser;

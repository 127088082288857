/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import dashboardApi from '../../dashboard.api';
import { TMerchantBooking } from '../../../merchant.type';
import utils from '../../../../common/services/utils.service';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const BookingListing = () => {
  const [bookings, setBookings] = useState<TMerchantBooking[]>([]);

  const statuses = {
    confirmed: 'text-green-400 bg-green-400/10',
    pending: 'text-yellow-400 bg-yellow-400/10',
    rejected: 'text-rose-400 bg-rose-400/10',
    cancelled: 'text-rose-400 bg-rose-400/10',
    availed: 'text-green-400 bg-green-400/10',
    expired: 'text-rose-400 bg-rose-400/10',
  };

  const fetchBookings = useQuery('fetchBookings', () => dashboardApi.getBookings(1), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        setBookings(data.data);
      }
    },
  });

  useEffect(() => {
    fetchBookings.refetch();
  }, []);

  return (
    <div className="h-1/2 overflow-y-auto">
      <h2 className="px-4 text-lg font-semibold leading-7 text-white sm:px-6 lg:px-8">Latest bookings</h2>
      <table className="mt-2 w-full whitespace-nowrap text-left">
        <colgroup>
          <col className="w-full sm:w-4/12" />
          <col className="lg:w-4/12" />
          <col className="lg:w-2/12" />
          <col className="lg:w-1/12" />
          <col className="lg:w-1/12" />
        </colgroup>
        <thead className="border-b border-white/10 text-sm leading-6 text-white">
          <tr>
            <th scope="col" className="hidden py-2 pl-8 pr-8 font-semibold md:table-cell lg:pr-20">
              Booking Id
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Resource
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Customer Name
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Booking Time
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Amount
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Advance Amount
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-white/5">
          {bookings.map((item) => (
            <tr key={item.booking_id}>
              <td className="py-4 pl-8 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">{item.booking_id}</div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-1">
                  <div className="font-mono text-sm leading-6 text-gray-400">
                    {/* {item.merchant_resource.merchant_resource_name} */}
                  </div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">
                    {/* {item.customer.customer_first_names_en} {item.customer.customer_last_name_en} */}
                  </div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3 text-gray-400">
                  {/* <time dateTime={item.booking_time.booking_date}>{item.booking_time.booking_date}</time> */}
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">
                    {/* {utils.formatCurrency(item.payment.booking_amount, item.payment.currency_alpha_3)} */}
                  </div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">
                    {/* {utils.formatCurrency(
                      item.payment.advance_booking_amount,
                      item.payment.advance_booking_currency_alpha_3,
                    )} */}
                  </div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                  <div className={classNames(statuses[item.booking_status], 'flex-none rounded-full p-1')}>
                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                  </div>
                  <div className="text-white sm:block">{item.booking_status}</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BookingListing;

import api from '../../../config/api/api.config';

type TCancelBookingPayload = {
  booking_cancellation_reason_id: number;
  booking_cancellation_notes: string;
};

const bookingListingApi = {
  getBookings: () => api.get('bookings'),
  cancelBooking: (bookingId: number, payload: TCancelBookingPayload) =>
    api.delete(`booking/${bookingId}`, { data: payload }),
  updateBookingStatus: (bookingId: number, status: string) =>
    api.put(`booking/${bookingId}/status`, { booking_status: status }),
};

export default bookingListingApi;

import api, { TApiResponse } from '../../config/api/api.config';
import { TBrandDetails, TMenuCategory, TMerchantDetails } from '../common/types';

const headerApi = {
  searchMarket: (query: string, facilityId: number, brandId: number, limit = 10) =>
    api.get(`search`, {
      params: {
        search_string: query,
        facility_id: facilityId,
        brand_id: brandId,
        limit,
      },
    }),
  getDineInDetails: (tableId: number) => api.get(`dining_table/${tableId}`),
  getMarketplaceConfig: (facilityCode: string, brandCode: string) =>
    api.get(`marketplace_config`, {
      params: {
        facility_code: facilityCode,
        brand_code: brandCode,
        service_id: 2,
        // dinning_table_id: tableId,
      },
    }),
  getMenu: (branchId: number): TApiResponse<TMenuCategory[]> => api.get(`branch/${branchId}/menu-v2?public_images_p=1`),
  getBrandDetailsById: (brandId: number): TApiResponse<TBrandDetails> => api.get(`brand/${brandId}`),
  getBrandDetails: (brandCode: string): TApiResponse<TBrandDetails> =>
    api.get(`brand/${brandCode}/iblinkmarketplace-config`),
  getMerchantDetails: (merchantCode: string): TApiResponse<TMerchantDetails> =>
    api.get(`merchant/${merchantCode}/iblinkmarketplace-config`),
};

export default headerApi;

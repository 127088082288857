import React from 'react';
import { Carousel } from 'antd';

type Props = {
  carouselWidth?: string;
  imageSize?: string;
  imageData: Array<string>;
  shouldAutoplay?: boolean;
};

const ImagesCarousel = ({ carouselWidth, imageSize, imageData, shouldAutoplay }: Props) => {
  return (
    <Carousel className={carouselWidth} autoplay={shouldAutoplay}>
      {imageData.map((image, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${image}-${index}`}>
          <img src={image} alt="Product" className={`${imageSize} object-cover rounded-lg`} />
        </div>
      ))}
    </Carousel>
  );
};

ImagesCarousel.defaultProps = {
  carouselWidth: 'w-96',
  imageSize: 'w-96 h-64',
  shouldAutoplay: true,
};

export default ImagesCarousel;

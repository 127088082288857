/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { TrashIcon } from '@heroicons/react/24/outline';
import { TMerchantResource } from '../merchant.type';
import modifyMerchantResourceApi from './modify-merchant-resource.api';
import { DataLoadingOverlay, Modal } from '../../common/components';
import { TMerchantResourceAttribute } from '../../common/types/common.type';

/* eslint-disable jsx-a11y/label-has-associated-control */
const ModifyMerchantResource = () => {
  const URLParams = new URLSearchParams(window.location.search);
  const resourceType: 'section' | 'table' = (URLParams.get('type') || 'section') as 'section' | 'table';
  const paramsMerchantResourceId = Number(URLParams.get('merchantResourceId'));
  const [initialFileList, setInitialFileList] = useState<FileList>();
  const [initialSeatingCapacity, setInitialSeatingCapacity] = useState<TMerchantResourceAttribute>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      merchantResourceName: '',
      merchantResourceDesc: '',
      merchantResourceProviderType: 'provides-service-itself',
      merchantResourceType: '',
      merchantResourceImage: [] as any,
      parentMerchantResourceId: null,
      merchantResourceHoldingTime: 15,
      merchantResourceSeatingCapacity: 4,
    },
  });
  // const [merchantResourceTypes, setMerchantResourceTypes] = useState<TMerchantResourceType[]>([]);
  const [merchantResourceSections, setMerchantResourceSections] = useState<TMerchantResource[]>([]);

  const merchantResourceImage = watch('merchantResourceImage');

  const fetchMerchantResourceSections = useQuery(
    'merchantResourceSections',
    () => modifyMerchantResourceApi.getMerchantResources({ 'mr.parent_merchant_resource_id': '=null' }),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200 && data.result_list) {
          setMerchantResourceSections(data.result_list);
          if (data.result_list.length > 0)
            setValue('parentMerchantResourceId', data.result_list[0].merchant_resource_id);
          else
            Modal.error({
              title: 'Error',
              children: '',
              content: 'No section found, please add a section first before adding tables.',
              onClose: () => navigate('/merchant/resource-listing'),
            });
        } else if (data.status === 'no rows') {
          Modal.error({
            title: 'Error',
            children: '',
            content: 'No section found, please add a section first before adding tables.',
            onClose: () => navigate('/merchant/resource-listing'),
          });
        }
      },
    },
  );

  // const fetchMerchantResourceTypes = useQuery(
  //   'merchantResourceTypes',
  //   () => modifyMerchantResourceApi.getMerchantResourceTypes(),
  //   {
  //     enabled: false,
  //     onSuccess: ({ data, status }) => {
  //       if (status === 200 && data.data) {
  //         setMerchantResourceTypes(data.data);
  //         setValue('merchantResourceType', data.data[0].merchant_resource_type_id);
  //       }
  //     },
  //   },
  // );

  const fetchMerchantResource = useQuery(
    'merchantResource',
    () => modifyMerchantResourceApi.getMerchantResource(paramsMerchantResourceId),
    {
      enabled: false,
      onSuccess: async ({ data, status }) => {
        if (status === 200 && data.data) {
          setValue('merchantResourceName', data.data.merchant_resource_name);
          setValue('merchantResourceDesc', data.data.merchant_resource_description);
          setValue('merchantResourceProviderType', data.data.merchant_resource_provider_type);
          setValue('merchantResourceType', data.data.merchant_resource_type.merchant_resource_type_id);
          setValue('merchantResourceHoldingTime', data.data.merchant_resource_holding_time);
          setValue('parentMerchantResourceId', data.data.parent_merchant_resource?.merchant_resource_id || null);

          const imageUrl = data.data.merchant_resource_image_list[0];

          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: blob.type });

          const fileList = new DataTransfer();
          fileList.items.add(file);

          setValue('merchantResourceImage', fileList.files);

          const seatingCapacity: TMerchantResourceAttribute = data.data.merchant_resource_attribute_list?.find(
            (item: TMerchantResourceAttribute) => item.merchant_resource_attribute_name === 'seating_capacity',
          );

          if (seatingCapacity) {
            setInitialSeatingCapacity(seatingCapacity);
            setValue('merchantResourceSeatingCapacity', Number(seatingCapacity.merchant_resource_attribute_value));
          }

          const inputTag = document.querySelector<HTMLInputElement>('#merchantResourceImage');
          if (inputTag) {
            inputTag.files = fileList.files;
          }
          setInitialFileList(fileList.files);
          trigger();
        }
      },
    },
  );

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (paramsMerchantResourceId) {
      let updateFailed = false;
      const payload = {
        parent_merchant_resource_id: data.parentMerchantResourceId,
        merchant_resource_type_id: resourceType === 'table' ? 1 : 2,
        merchant_resource_provider_type: data.merchantResourceProviderType,
        merchant_resource_name: data.merchantResourceName,
        merchant_resource_description: data.merchantResourceDesc,
        merchant_resource_holding_time: data.merchantResourceHoldingTime,
      };

      const { data: editMerchantResponseData, status } = await modifyMerchantResourceApi.updateMerchantResource(
        paramsMerchantResourceId,
        payload,
      );

      if (status !== 200 || editMerchantResponseData?.status !== 'successful') updateFailed = true;

      if (initialFileList !== data.merchantResourceImage) {
        // update image
        const formData = new FormData();
        formData.append('merchant_resource_image', data.merchantResourceImage[0]);

        const { data: editMerchantImageResponseData, status: editMerchantImageResponseStatus } =
          await modifyMerchantResourceApi.updateMerchantResourceImage(paramsMerchantResourceId, formData);

        if (editMerchantImageResponseStatus !== 200 || editMerchantImageResponseData?.status !== 'successful')
          updateFailed = true;
      }

      if (
        initialSeatingCapacity &&
        Number(initialSeatingCapacity?.merchant_resource_attribute_value) !== data.merchantResourceSeatingCapacity
      ) {
        const { data: editMerchantAttributeResponseData, status: editMerchantAttributeResponseStatus } =
          await modifyMerchantResourceApi.updateMerchantResourceSeatingCapacity(
            initialSeatingCapacity?.merchant_resource_attribute_id,
            data.merchantResourceSeatingCapacity,
          );

        if (editMerchantAttributeResponseStatus !== 200 || editMerchantAttributeResponseData?.status !== 'successful')
          updateFailed = true;
      }

      if (updateFailed)
        message.error('Something went wrong. Please try again after sometime or contact support for help.', 1.5);
      else message.success(<span className="capitalize">{resourceType} Updated Successfully</span>, 1.5);

      navigate('/merchant/resource-listing');
      // Edit {resourceType}
    } else {
      const formData = new FormData();
      formData.append('merchant_resource_name', data.merchantResourceName);
      formData.append('merchant_resource_description', data.merchantResourceDesc || ' ');
      formData.append('merchant_resource_provider_type', data.merchantResourceProviderType);
      formData.append('merchant_resource_type_id', String(resourceType === 'table' ? 1 : 2));
      formData.append('merchant_resource_image', data.merchantResourceImage[0]);
      formData.append('parent_merchant_resource_id', String(data.parentMerchantResourceId));
      formData.append('merchant_resource_holding_time', String(data.merchantResourceHoldingTime));
      formData.append(
        'merchant_resource_attribute_list',
        JSON.stringify([
          {
            merchant_resource_attribute_name: 'seating_capacity',
            merchant_resource_attribute_value: String(data.merchantResourceSeatingCapacity),
          },
        ]),
      );
      const { data: addMerchantData, status } = await modifyMerchantResourceApi.addMerchantResource(formData);
      if (status === 200 && addMerchantData.status === 'successful') {
        message.success(<span>{resourceType} Added Successfully</span>, 1.5, () => {
          navigate('/merchant/resource-listing');
        });
      } else {
        message.error('Something went wrong. Please try again after sometime or contact support for help.');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    // fetchMerchantResourceTypes.refetch();
    if (resourceType === 'table') fetchMerchantResourceSections.refetch();
  }, []);

  useEffect(() => {
    if (paramsMerchantResourceId) fetchMerchantResource.refetch();
  }, [paramsMerchantResourceId]);

  return (
    <div className="h-fill w-1/2 mx-auto flex flex-col justify-between p-10 capitalize bg-bgSidebar rounded">
      <DataLoadingOverlay isLoading={isLoading || fetchMerchantResource.isFetching} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-b border-white/10 pb-4">
          <h2 className="text-3xl font-semibold leading-7 text-text">
            {paramsMerchantResourceId ? 'Edit' : 'Add'} {resourceType}
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="merchantResourceName" className="block text-sm font-medium leading-6 text-text">
                {resourceType} Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register('merchantResourceName', { required: true })}
                  id="merchantResourceName"
                  className="block w-full rounded-md bg-white/5 py-1.5 text-text border border-inputBorder shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
                <p className="text-red-500">{errors.merchantResourceSeatingCapacity?.message}</p>
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="merchantResourceDesc" className="block text-sm font-medium leading-6 text-text">
                {resourceType} Description (Optional)
              </label>
              <div className="mt-2">
                <textarea
                  rows={3}
                  {...register('merchantResourceDesc')}
                  id="merchantResourceDesc"
                  className="block w-full rounded-md border border-inputBorder bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 pl-3"
                />
              </div>
            </div>

            {resourceType === 'table' && (
              <div className="sm:col-span-4">
                <label htmlFor="section" className="block text-sm font-medium leading-6 text-text">
                  {resourceType} Section
                </label>
                <div className="mt-2">
                  <select
                    id="parentMerchantResourceId"
                    {...register('parentMerchantResourceId', { required: true })}
                    autoComplete="parentMerchantResourceId"
                    className="block w-full rounded-md border border-inputBorder bg-white/5 py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 [&_*]:text-black capitalize"
                  >
                    {merchantResourceSections.map((merchantResourceTypeOption) => (
                      <option
                        key={merchantResourceTypeOption.merchant_resource_id}
                        value={merchantResourceTypeOption.merchant_resource_id}
                      >
                        {merchantResourceTypeOption.merchant_resource_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {resourceType === 'table' && (
              <div className="sm:col-span-4">
                <label
                  htmlFor="merchantResourceSeatingCapacity"
                  className="block text-sm font-medium leading-6 text-text"
                >
                  {resourceType} Seating Capacity
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md border border-inputBorder shadow-sm bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary">
                    <input
                      type="number"
                      id="merchantResourceSeatingCapacity"
                      {...register('merchantResourceSeatingCapacity', {
                        required: true,
                        valueAsNumber: true,
                        validate: (value) => Number(value) > 0,
                      })}
                      className="flex-1 border-0 bg-transparent py-1.5 pl-3 text-text focus:ring-0 sm:text-sm sm:leading-6"
                      min={0}
                    />
                    <p className="text-red-500">{errors.merchantResourceSeatingCapacity?.message}</p>
                  </div>
                </div>
              </div>
            )}
            {resourceType === 'table' && (
              <div className="sm:col-span-4">
                <label htmlFor="merchantResourceHoldingTime" className="block text-sm font-medium leading-6 text-text">
                  {resourceType} Holding Time
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md border border-inputBorder shadow-sm bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary">
                    <input
                      type="number"
                      id="merchantResourceHoldingTime"
                      {...register('merchantResourceHoldingTime', {
                        required: true,
                        valueAsNumber: true,
                        validate: (value) => Number(value) > 0,
                      })}
                      className="flex-1 border-0 bg-transparent py-1.5 pl-3 text-text focus:ring-0 sm:text-sm sm:leading-6"
                      min={0}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="sm:col-span-4">
              <label className="block text-sm font-medium leading-6 text-text" htmlFor="merchantResourceImage">
                {resourceType} Image
              </label>
              {merchantResourceImage?.length > 0 && (
                <div className="relative w-fit">
                  <img
                    className="h-28 border rounded shadow-lg"
                    src={merchantResourceImage[0] && URL.createObjectURL(merchantResourceImage[0] as File)}
                    alt="resource"
                  />
                  <TrashIcon
                    className="h-7 w-7 text-red-500 cursor-pointer right-0 absolute top-0 mt-2"
                    onClick={() => setValue('merchantResourceImage', [])}
                  />
                </div>
              )}
              <input
                className="block w-full text-sm text-text border border-gray-300 rounded-lg cursor-pointer bg-bgMain focus:outline-none mt-1"
                id="merchantResourceImage"
                {...register('merchantResourceImage')}
                type="file"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-4">
          <div className="flex items-center justify-center gap-x-6">
            <button type="button" className="text-sm font-semibold leading-6 text-text" onClick={() => navigate(-1)}>
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-textLight shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!isValid}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ModifyMerchantResource;

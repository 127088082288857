/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import { Bike, ChevronLeft, ShoppingBag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { isMobile } from 'react-device-detect';
import { Delivery, Pickup } from './components';
import { ThemeContext } from '../../contexts/theme.context';
import utils, { cn } from '../common/services/utils.service';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';
import { xUserId } from '../../config/variables/system.variable';
import useCurrentUser from '../hooks/useCurrentUser.hook';
import { TCity } from '../common/types';
import sharedApi from '../common/shared.api';
import { OtpModal, PhoneModal } from '../sidemenu/components';
import { ModalHandle } from '../sidemenu/components/phone-modal/phone-modal.component';

const OrderType = () => {
  const { theme } = useContext(ThemeContext);
  const { currentUser } = useCurrentUser();
  const { orderCreationStore, setOrderCreationStore, updateUserConfig } = useOrderCreationStore();
  const { availableFulfillmentTypes } = orderCreationStore;
  const navigate = useNavigate();
  const orderType = localStorage.getItem('orderType') as 'delivery' | 'pickup' | null;
  const [cities, setCities] = useState<TCity[]>([]);
  const [currentPage, setCurrentPage] = useState<'delivery' | 'pickup'>(orderType || 'delivery');
  const iBlinkMarketplaceUser = JSON.parse(localStorage.getItem('iBlinkMarketplaceUser') || 'null');
  const phoneModalRef = useRef<ModalHandle>(null);
  const otpModalRef = useRef<ModalHandle>(null);

  const fetchCities = useQuery('cities', () => sharedApi.getCities(1), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        setCities(data.data);
      }
    },
  });

  const updateMenuUserConfig = (
    branchId: number,
    facilityId: number,
    marketplaceId: number,
    brandId: number,
    fulfillmentId: number,
  ) => {
    updateUserConfig(
      {
        user_id: Number(iBlinkMarketplaceUser?.id || xUserId),
        branch_id: branchId,
        facility_id: facilityId,
        brand_id: brandId,
        marketplace_id: marketplaceId,
        fulfillment_type_id: fulfillmentId,
        fulfillment_type_name: fulfillmentId === 3 ? 'restaurant-delivery' : 'pickup',
        logistic_provider_id: null,
        business_priority: 0,
        order_placement_channel_id: 4,
        default_delivery_charges: 7,
        allowed_fulfillment_type_list: [],
        allowed_marketplace_list: [],
      },
      {
        customerDetails: true,
        customerAddressDetails: true,
      },
    );

    if (!isMobile) {
      navigate(-1);
    } else {
      navigate('/checkout');
    }
  };

  const fetchBranchAndUpdateMenu = async (
    branchId: number,
    facilityId: number,
    marketplaceId: number,
    brandId: number,
    fulfillmentId: number,
    customerAddressId?: number,
    customerAddress?: string,
  ) => {
    setOrderCreationStore({
      ...orderCreationStore,
      orderTicket: {
        ...orderCreationStore.orderTicket,
        customer_details: {
          external_customer_id: null,
          customer_id: currentUser?.customer.customer_id.toString() || null,
          first_name_en: currentUser?.customer.customer_first_name || null,
          last_name_en: currentUser?.customer.customer_last_name || null,
          email: null,
          phone_nr: currentUser?.customer.customer_phone_nr || null,
          customer_contact_method: 'phone',
        },
        integration_details: {
          ...orderCreationStore.orderTicket.integration_details,
          fulfillment_type: fulfillmentId === 3 ? 'restaurant-delivery' : 'pickup',
          fulfillment_type_id: fulfillmentId,
        },
        customer_address_details:
          fulfillmentId === 3
            ? {
                customer_address: customerAddress,
                customer_address_id: customerAddressId || 0,
              }
            : undefined,
      },
      address: {
        order_address: customerAddress || '',
      },
    });

    updateMenuUserConfig(branchId, facilityId, marketplaceId, brandId, fulfillmentId);
  };

  const phoneContinuePressed = (userAlreadyExists = false) => {
    if (userAlreadyExists) {
      window.location.reload();
    } else otpModalRef.current?.toggleModal();
  };

  const otpContinuePressed = () => {
    window.location.reload();
  };

  const triggerMobileValidation = () => {
    const iBlinkMarketplaceCustomer = localStorage.getItem('iBlinkMarketplaceUser');

    if (!iBlinkMarketplaceCustomer) phoneModalRef.current?.toggleModal();
  };

  useEffect(() => {
    fetchCities.refetch();

    setTimeout(() => {
      triggerMobileValidation();
    }, 10);
  }, []);

  useEffect(() => {
    if (orderCreationStore.orderTicket.integration_details.fulfillment_type_id === 4) {
      setCurrentPage('pickup');
    } else {
      setCurrentPage('delivery');
    }
  }, [orderCreationStore.orderTicket.integration_details.fulfillment_type_id]);

  useEffect(() => {
    if (availableFulfillmentTypes) {
      if (currentPage === 'delivery' && !availableFulfillmentTypes.find((type) => type.fulfillment_type_id === 3)) {
        setCurrentPage('pickup');
      } else if (
        currentPage === 'pickup' &&
        !availableFulfillmentTypes.find((type) => type.fulfillment_type_id === 4)
      ) {
        setCurrentPage('delivery');
      }
    }
  }, [availableFulfillmentTypes]);

  return (
    <div className="h-full">
      <PhoneModal ref={phoneModalRef} continuePressed={phoneContinuePressed} />
      <OtpModal ref={otpModalRef} continuePressed={otpContinuePressed} />
      <div
        className={cn('flex justify-center flex-col p-2', {
          'bg-secondary-foreground': !theme?.header?.backgroundColor,
        })}
        style={{
          backgroundColor: theme?.header?.backgroundColor || '',
          color: theme?.header?.textColor || '',
        }}
      >
        <div className="relative">
          <button
            type="button"
            className="absolute top-0 -left-1 pt-1.5 flex gap-0 text-white text-base font-semibold items-center"
            onClick={() => navigate(isMobile ? '/checkout' : '/checkout')}
          >
            <ChevronLeft color="white" size={22} />
            Back
          </button>
          <h1 className="text-xl md:text-2xl font-semibold text-center text-white">Select Order Type</h1>
        </div>
        <div className="flex justify-center">
          <button
            type="button"
            className={utils.cn(
              'w-28 text-base uppercase border shadow-xl py-2 m-1 text-white bg-colorPrimary rounded-full flex flex-col justify-center items-center',
              {
                '!bg-gray-300 text-gray-800 opacity-80': currentPage !== 'delivery',
                '!font-semibold': currentPage === 'delivery',
              },
            )}
            onClick={() => setCurrentPage('delivery')}
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
            disabled={!availableFulfillmentTypes?.find((type) => type.fulfillment_type_id === 3)}
          >
            <Bike size={20} />
            Delivery
          </button>
          <button
            type="button"
            className={utils.cn(
              'w-28 text-base uppercase border shadow-xl py-2 m-1 text-white bg-colorPrimary rounded-full flex flex-col justify-center items-center',
              {
                '!bg-gray-300 text-gray-800 opacity-80': currentPage !== 'pickup',
                'font-semibold': currentPage === 'pickup',
              },
            )}
            onClick={() => setCurrentPage('pickup')}
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
            disabled={!availableFulfillmentTypes?.find((type) => type.fulfillment_type_id === 4)}
          >
            <ShoppingBag size={20} />
            Pickup
          </button>
        </div>
      </div>
      <div className="md:mx-[30%]">
        {currentPage === 'delivery' && (
          <Delivery
            fetchBranchAndUpdateMenu={fetchBranchAndUpdateMenu}
            cities={cities}
            triggerMobileValidation={triggerMobileValidation}
          />
        )}
        {currentPage === 'pickup' && (
          <Pickup
            fetchBranchAndUpdateMenu={fetchBranchAndUpdateMenu}
            cities={cities}
            triggerMobileValidation={triggerMobileValidation}
          />
        )}
      </div>
    </div>
  );
};

export default OrderType;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Modal, message } from 'antd';
import { ArrowTopRightOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { format, parse, parseISO } from 'date-fns';
import { TMerchantBooking } from '../merchant.type';
import bookingListingApi from './booking-listing.api';
import { DataLoadingOverlay } from '../../common/components';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const BookingListing = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [bookings, setBookings] = useState<TMerchantBooking[]>([]);

  const statuses = {
    confirmed: 'text-blue-600 bg-blue-600/10',
    pending: 'text-yellow-400 bg-yellow-400/10',
    rejected: 'text-red-600 bg-red-600/10',
    cancelled: 'text-red-600 bg-red-600/10',
    availed: 'text-green-600 bg-green-600/10',
    expired: 'text-red-600 bg-red-600/10',
  };

  const fetchBookings = useQuery('fetchBookings', () => bookingListingApi.getBookings(), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200 && data.data) {
        setBookings(
          data.data
            .map((item: TMerchantBooking) => ({
              ...item,
              bookingTime: `${format(parse(item.booking_start_time, 'H:mm:ss', new Date()), 'hh:mm a')} - ${format(
                parse(item.booking_end_time, 'H:mm:ss', new Date()),
                'hh:mm a',
              )}`,
            }))
            .sort((a: { booking_id: number }, b: { booking_id: number }) => b.booking_id - a.booking_id),
        );
      }
    },
  });

  const updateBookingStatus = async (bookingId: number, bookingStatus: string) => {
    const { data, status } = await bookingListingApi.updateBookingStatus(bookingId, bookingStatus);
    if (status === 200 && data.status === 'successful') {
      message.success('Booking status updated successfully');
      fetchBookings.refetch();
    } else {
      message.error('Something went wrong. Please try again after sometime or contact support for help.');
    }
  };

  useEffect(() => {
    fetchBookings.refetch();
  }, []);

  return (
    <div className="h-fill overflow-y-auto p-5 flex flex-col gap-2">
      <DataLoadingOverlay isLoading={isLoading || fetchBookings.isFetching} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-bold text-text mb-2">Bookings</h1>
          <p className="text-gray-600 w-full">Manage all your bookings here.</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex gap-2">
          <button
            type="button"
            className="block rounded-md bg-primaryDark px-3 py-2 text-center text-sm font-semibold text-textLight shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryDark"
            onClick={() => navigate('/merchant/create-booking')}
          >
            Add New
          </button>
        </div>
      </div>
      <div className="h-fill overflow-y-auto bg-bgSidebar rounded p-2 px-4">
        <table className="w-full whitespace-nowrap text-left">
          <colgroup>
            <col className="" />
            <col className="" />
            <col className="" />
            <col className="" />
            <col className="" />
          </colgroup>
          <thead className="border-b border-black/10 text-sm leading-6 text-text">
            <tr>
              {/* <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                Resource
              </th> */}
              <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                Customer Name
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                Phone Number
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                Email
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                Booking Date
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                Booking Time
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                Status
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-white/5">
            {bookings.map((item) => (
              <tr key={item.booking_id}>
                {/* <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div className="flex gap-x-1">
                      <div className="font-mono text-sm leading-6 text-textListing">{item.merchant_resource_name}</div>
                    </div>
                  </td> */}
                <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-x-3">
                    <div className="font-mono text-sm leading-6 text-textListing">
                      {item.customer_first_names} {item.customer_last_name}
                    </div>
                  </div>
                </td>
                <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-x-3 text-textListing">{item.phone_nr}</div>
                </td>
                <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-x-3 text-textListing">{item.email}</div>
                </td>
                <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-x-3 text-textListing">
                    <time dateTime={item.booking_date}>{format(parseISO(item.booking_date), 'dd, MMM yy')}</time>
                  </div>
                </td>
                <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-x-3">
                    <div className="font-mono text-sm leading-6 text-textListing">{item.bookingTime}</div>
                  </div>
                </td>
                <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20 text-right">
                  <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                    <div className={classNames(statuses[item.booking_status], 'flex-none rounded-full p-1')}>
                      <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className="text-text sm:block capitalize">{item.booking_status}</div>
                  </div>
                </td>
                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div className="flex gap-1 justify-end">
                    {!['cancelled', 'availed', 'expired', 'closed'].includes(item.booking_status) && (
                      <button
                        type="button"
                        className="block rounded-md bg-primaryDark px-2 py-0 text-center text-sm font-semibold text-textLight shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryDark"
                        onClick={() => updateBookingStatus(item.booking_id, 'availed')}
                      >
                        Customer Arrived
                      </button>
                    )}
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md px-2.5 py-1.5 text-sm font-semibold text-textListing shadow-sm hover:scale-110 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      onClick={() => navigate(`/merchant/booking/${item.booking_id}`)}
                    >
                      <ArrowTopRightOnSquareIcon width={24} />
                    </button>
                    {item.booking_status !== 'cancelled' && (
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md px-2.5 py-1.5 text-sm font-semibold text-textListing shadow-sm hover:scale-110 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                        onClick={() =>
                          Modal.confirm({
                            title: 'Bookings',
                            content: 'Are you sure you want to cancel the booking?',
                            okText: 'Cancel Booking',
                            okButtonProps: {
                              danger: true,
                            },
                            onOk: async () => {
                              setIsLoading(true);
                              const { data, status } = await bookingListingApi.cancelBooking(item.booking_id, {
                                booking_cancellation_reason_id: 1,
                                booking_cancellation_notes: 'cancelled',
                              });
                              if (status === 200 && data.status === 'successful') {
                                message.success('Booking cancelled successfully');
                                setBookings((prev) =>
                                  prev.map((prevBooking) =>
                                    prevBooking.booking_id === item.booking_id
                                      ? { ...prevBooking, booking_status: 'cancelled' }
                                      : prevBooking,
                                  ),
                                );
                              } else
                                message.error(
                                  data.message ||
                                    'Something went wrong. Please try again after sometime or contact support for help.',
                                );
                              setIsLoading(false);
                            },
                          })
                        }
                      >
                        <TrashIcon width={24} />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingListing;

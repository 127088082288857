/* eslint-disable no-return-assign */
import { Fragment, useState, forwardRef, useImperativeHandle, useEffect, useContext } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { TSearchResult } from '../../../common/types/common.type';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import utils from '../../../common/services/utils.service';
import { ThemeContext } from '../../../../contexts/theme.context';

export type SearchPaletteHandle = {
  toggleSearch: () => void;
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

// eslint-disable-next-line @typescript-eslint/ban-types
type SearchPaletteProps = {};

// eslint-disable-next-line no-empty-pattern
const SearchPalette = forwardRef<SearchPaletteHandle, SearchPaletteProps>(({}, ref) => {
  const { theme } = useContext(ThemeContext);
  const { orderCreationStore } = useOrderCreationStore();
  const { menu } = orderCreationStore;
  const [query, setQuery] = useState('');
  const [items, setItems] = useState<TSearchResult>();
  const navigate = useNavigate();

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleSearch: (value = undefined) => {
      setIsSearchVisible((prev) => value || !prev);
    },
  }));

  const searchMarket = async (searchText: string) => {
    const searchResults = {
      menu_categories: [],
      brands: [],
      menu_items: menu
        .flatMap((item) => item.menu_items)
        .filter((menuItem) => menuItem.menu_item_name_en.toLowerCase().includes(searchText.toLowerCase())),
    };

    setItems(searchResults);
  };

  const [timer, setTimer] = useState<any>(null);
  const debounceSearch = (text: string, timeout = 1000) => {
    clearTimeout(timer);
    if (text !== '')
      setTimer(
        setTimeout(() => {
          searchMarket(text);
        }, timeout),
      );
  };

  useEffect(() => {
    debounceSearch(query);
    setItems(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Transition.Root show={isSearchVisible} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-[60]" onClose={setIsSearchVisible}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    style={{
                      backgroundColor: theme?.general?.backgroundColor || '',
                      color: theme?.general?.textColor || '',
                    }}
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>
                <div
                  className="max-h-[50vh] overflow-y-auto"
                  style={{
                    backgroundColor: theme?.general?.backgroundColor || '',
                    color: theme?.general?.textColor || '',
                  }}
                >
                  {/* {items && items?.menu_categories.length > 0 && (
                    <div className="scroll-py-3 overflow-x-auto p-3 flex flex-row gap-2">
                      {items?.menu_categories.map((menuCategory) => (
                        <div
                          className=" min-w-[8rem] p-2 rounded bg-gray-100 capitalize flex justify-center items-center text-center cursor-pointer hover:bg-gray-200"
                          key={menuCategory.menu_category_id}
                        >
                          {menuCategory.category_name}
                        </div>
                      ))}
                    </div>
                  )} */}

                  {/* {items && items?.brands.length > 0 && (
                    <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                      <h1>Brands</h1>
                      {items?.brands.map((brand) => (
                        <Combobox.Option
                          key={brand.brand_id}
                          value={brand}
                          className={({ active }) =>
                            classNames('flex cursor-pointer select-none rounded-xl p-3', active && 'bg-gray-100')
                          }
                          onClick={() => {
                            navigate(`/brand/${brand.brand_id}`);
                            setIsSearchVisible(false);
                          }}
                        >
                          {({ active }) => (
                            <>
                              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg">
                                <img src={brand.brand_image[0]?.url || 'assets/brand.png'} alt={brand.brand_name} />
                              </div>
                              <div className="ml-4 flex-auto">
                                <p
                                  className={classNames(
                                    'text-sm font-medium capitalize',
                                    active ? 'text-gray-900' : 'text-gray-700',
                                  )}
                                >
                                  {brand.brand_name}
                                </p>
                                <p className={classNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
                                  {brand.brand_description}
                                </p>
                              </div>
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )} */}

                  {items && items?.menu_items.length > 0 && (
                    <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                      {/* <h1>Menu Items</h1> */}
                      <div className="flex flex-col gap-1.5">
                        {items?.menu_items.map((menuItem) => (
                          <Combobox.Option
                            key={menuItem.menu_item_external_id}
                            value={menuItem}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-pointer select-none rounded-md border p-3 shadow-md',
                                active && 'opacity-70',
                              )
                            }
                            onClick={() => {
                              navigate(`/product/${menuItem.item_id}`);
                              setIsSearchVisible(false);
                            }}
                          >
                            {({ active }) => (
                              <div className="flex cursor-pointer w-full">
                                <div className="flex flex-none justify-center rounded-lg">
                                  <img
                                    className="rounded h-14 w-14 object-cover"
                                    src={
                                      menuItem?.menu_item_images && menuItem?.menu_item_images.length > 0
                                        ? menuItem?.menu_item_images[0]?.menu_item_image_url
                                        : `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
                                    }
                                    alt={menuItem.menu_item_name_en}
                                  />
                                </div>
                                <div className="ml-4 flex flex-col gap-0 w-fill">
                                  <div
                                    className={classNames(
                                      'text-sm font-medium capitalize flex justify-between',
                                      active ? 'text-gray-900' : 'text-gray-700',
                                    )}
                                    style={{ color: theme?.general?.textColor || '' }}
                                  >
                                    <span>{menuItem.menu_item_name_en}</span>
                                    <div className="flex w-max text-right justify-end items-center">
                                      {menuItem.menu_item_price !== -1 && (
                                        <div className="font-bold">
                                          {utils.formatCurrency(menuItem.menu_item_price, menuItem.currency_alpha_3)}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {/* <div className="capitalize">{menuItem.brand_name}</div> */}
                                  <div
                                    className={classNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}
                                    style={{ color: theme?.general?.descriptionTextColor || '' }}
                                  >
                                    {menuItem.menu_item_description_en}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Combobox.Option>
                        ))}
                      </div>
                    </Combobox.Options>
                  )}
                </div>

                {query !== '' &&
                  items?.brands &&
                  items.brands.length === 0 &&
                  items?.menu_items &&
                  items.menu_items.length === 0 && (
                    <div
                      className="py-14 px-6 text-center text-sm sm:px-14"
                      style={{
                        backgroundColor: theme?.general?.backgroundColor || '',
                        color: theme.general?.textColor || '',
                      }}
                    >
                      <ExclamationCircleIcon
                        type="outline"
                        name="exclamation-circle"
                        className="mx-auto h-6 w-6 text-gray-400"
                      />
                      <p
                        className="mt-4 font-semibold text-gray-900"
                        style={{ color: theme?.general?.textColor || '' }}
                      >
                        No results found
                      </p>
                      <p className="mt-2 text-gray-500" style={{ color: theme?.general?.descriptionTextColor || '' }}>
                        No Items found for this search term. Please try a different query.
                      </p>
                    </div>
                  )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

SearchPalette.displayName = 'SearchPalette';

export default SearchPalette;

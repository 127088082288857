import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

const utils = {
  // eslint-disable-next-line no-promise-executor-return
  sleep: (interval: number) => new Promise((resolve) => setTimeout(resolve, interval)),
  getViewPort: () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return { vw, vh };
  },
  formatCurrency: (
    value: number,
    currency = 'AED',
    displayStyle: 'standard' | 'scientific' | 'engineering' | 'compact' | undefined = 'standard',
  ) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      notation: displayStyle,
    });
    return formatter.format(value);
  },
  getCurrencySymbol: (currency = 'AED') => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });
    return formatter.format(0).replace(/\d./g, '').trim();
  },
  cn: (...inputs: ClassValue[]) => {
    return twMerge(clsx(inputs));
  },
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export function debounce<F extends (...args: any[]) => any>(func: F, wait: number): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout | null;
  return (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), wait);
  };
}

export const hexToRgba = (hex: string, alpha: number) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${alpha})`;
};

export default utils;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { DataLoadingOverlay } from '../../common/components';
import { TMerchantBooking } from '../merchant.type';
import bookingCalendarApi from './booking-calendar.api';
import { BookingEventCalendar } from './components';

const BookingCalendar = () => {
  const [bookings, setBookings] = useState<TMerchantBooking[]>([]);

  const fetchBookings = useQuery('fetchBookings', () => bookingCalendarApi.getBookings(), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200 && data.data) {
        setBookings(data.data);
      }
    },
  });

  useEffect(() => {
    fetchBookings.refetch();
  }, []);
  return (
    <div className="h-fill bg-bgMain p-5">
      <DataLoadingOverlay isLoading={fetchBookings.isFetching} />
      <BookingEventCalendar bookings={bookings} />
    </div>
  );
};

export default BookingCalendar;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { cn } from '../../services/utils.service';

export type SelectOption = {
  label: string;
  value: number | string;
};

type CustomSelectOption = SelectOption & { description?: string };

type SelectProps = {
  options: CustomSelectOption[];
  value?: number | string;
  defaultValue?: number | string;
  onChange?: (value: number | string) => void;
  className?: string;
  optionClassName?: string;
  filterIndicator?: boolean;
  placeholder?: string;
  disabled?: boolean;
  showCheckbox?: boolean;
  searchable?: boolean;
  placement?: 'right' | 'left';
  verticalPlacement?: 'top' | 'bottom';
  footer?: ReactNode;
  allowClear?: boolean;
};

const Select: FC<SelectProps> = ({
  options,
  value,
  defaultValue,
  onChange,
  className,
  optionClassName,
  filterIndicator = false,
  placeholder,
  disabled,
  showCheckbox = true,
  searchable = false,
  placement = 'left',
  verticalPlacement = 'bottom',
  footer,
  allowClear = false,
}) => {
  const [selected, setSelected] = useState<CustomSelectOption>();
  const [search, setSearch] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<any>(null);

  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().includes(search.toLowerCase()) ||
      option.description?.toLowerCase().includes(search.toLowerCase()),
  );

  useEffect(() => {
    setSelected(options.find((option) => option.value === value));
  }, [value]);

  useEffect(() => {
    if (defaultValue !== undefined) setSelected(options.find((option) => option.value === defaultValue));
  }, [defaultValue]);

  useEffect(() => {
    if (searchable) {
      inputRef?.current?.focus();
    }
  }, [searchable]);

  useEffect(() => {
    setTimeout(() => {
      if (isMenuOpen && menuRef.current) {
        menuRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
      }
    }, 50);
  }, [isMenuOpen, menuRef.current]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => {
        if (open !== isMenuOpen) {
          setIsMenuOpen(open);
        }

        return (
          <>
            <div className="">
              <Menu.Button
                className={cn(
                  'relative flex w-full justify-between gap-x-1.5 truncate rounded-md bg-white px-3 py-2 text-sm font-semibold capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
                  className,
                  disabled && 'cursor-not-allowed opacity-50',
                  {
                    'justify-end': !selected?.label && !placeholder,
                  },
                )}
                disabled={disabled}
                onClick={() => {
                  if (searchable) {
                    setSearch('');
                    setTimeout(() => {
                      inputRef?.current?.focus();
                    }, 50);
                  }
                }}
              >
                {selected?.label || placeholder}
                {allowClear && selected ? (
                  <button
                    type="button"
                    className="z-10 flex h-5 w-5 items-center justify-center rounded-full text-rose-400 hover:scale-110 hover:text-red-700"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelected(undefined);
                      if (onChange) onChange(0);
                    }}
                  >
                    <span className="sr-only">Clear</span>
                    <XMarkIcon className="-mr-1 h-5 w-5" aria-hidden="true" />
                  </button>
                ) : open ? (
                  <ChevronUpIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                ) : (
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                )}
              </Menu.Button>
              {filterIndicator && selected && (
                <span className="absolute left-0.5 top-0.5 z-20 h-2.5 w-2.5 rounded-full bg-[#8645FE] neon-purple" />
              )}
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={menuRef}
                className={cn(
                  'absolute z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  {
                    'pt-9': searchable,
                    'right-0': placement === 'right',
                    'left-0': placement === 'left',
                    'top-full': verticalPlacement === 'bottom',
                    'bottom-full': verticalPlacement === 'top',
                  },
                )}
              >
                <div className="relative">
                  {searchable ? (
                    <div className="fixed top-0 z-20 flex w-full items-center gap-1.5 rounded-t-md border bg-white px-1 shadow">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <input
                        ref={inputRef}
                        type="text"
                        className="form-input my-1 w-[90%] border-0 bg-white px-0 py-1 placeholder:opacity-50 focus:outline-none focus:ring-0"
                        placeholder={`Search ${placeholder}`}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="relative flex max-h-56 flex-col gap-2 overflow-y-auto p-3">
                  {filteredOptions.map((option) => (
                    <Menu.Item key={option.value}>
                      {({ active }) => (
                        <button
                          type="button"
                          className={cn(
                            active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                            selected?.value === option.value ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                            'w-full cursor-pointer items-center justify-between rounded-md px-4 py-2 text-left font-semibold',
                            optionClassName,
                          )}
                          onClick={() => {
                            setSelected(option);
                            if (onChange) onChange(option.value);
                          }}
                        >
                          <div className="flex text-sm capitalize">
                            {option.label}
                            {showCheckbox && (
                              <CheckIcon
                                className={cn(
                                  selected?.value === option.value ? 'text-gray-900' : 'text-transparent',
                                  'ml-3 h-4 w-4',
                                )}
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          {option.description && <div className="text-sm text-gray-500">{option.description}</div>}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
                {footer}
              </Menu.Items>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
};

export default Select;

import { Modal, notification } from 'antd';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Countdown from 'antd/es/statistic/Countdown';
import api from '../../../../config/api/api.config';
import sideMenuApi from '../../sidemenu.api';
import { ModalHandle } from '../phone-modal/phone-modal.component';
import { ThemeContext } from '../../../../contexts/theme.context';
import { DataLoadingOverlay } from '../../../common/components';

type OtpModalProps = {
  continuePressed: () => void;
};

const OtpModal = React.forwardRef<ModalHandle, OtpModalProps>(({ continuePressed }, ref) => {
  const { theme } = useContext(ThemeContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const phoneNumber = localStorage.getItem('phoneNumber') || '';
  const [otp, setOtp] = useState('');
  const [deadline, setDeadline] = useState<number | undefined>();
  const [resendOTP, setResendOTP] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal: () => {
      setIsModalVisible((prev) => !prev);
    },
  }));
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = async () => {
    setIsLoading(true);
    const { data, status, headers } = await sideMenuApi.validateOTP({
      phone_nr: phoneNumber,
      email: null,
      otp,
      intent: 'customer_signup',
    });

    if (status === 200) {
      localStorage.setItem('customerDetails', JSON.stringify(data.data));
      if (headers['x-access-token'] && headers['x-user-id']) {
        const iBlinkMarketplaceCustomer = {
          token: headers['x-access-token'],
          id: headers['x-user-id'],
          apiKey: data.data.merchant.merchant_api_key || '',
          phoneNumber,
        };
        localStorage.setItem('iBlinkMarketplaceUser', JSON.stringify(iBlinkMarketplaceCustomer));
        api.setAuthenticationHeader(iBlinkMarketplaceCustomer);
      }
      const currentUserUpdated = new CustomEvent('currentUserUpdated', { detail: data.data });
      window.dispatchEvent(currentUserUpdated);
      setIsModalVisible(false);
      continuePressed();
    } else {
      setInvalidOtp(true);
    }
    setIsLoading(false);
  };

  const handleResendOTP = async () => {
    setIsLoading(true);
    const { data: requestData, status: requestStatus } = await sideMenuApi.requestOPT({
      phone_nr: phoneNumber,
      email: null,
      intent: 'customer_signup', // customer_signup
      contact_method: 'sms',
    });
    if (requestData.status === 'successful' && requestStatus === 200) {
      notification.success({
        message: 'OTP Sent',
        description: 'OTP has been sent to your phone number',
        placement: isMobile ? 'bottom' : 'topRight',
        duration: 1,
      });
      setResendOTP(false);
      setDeadline(Date.now() + 60000);
    } else {
      notification.error({
        message: 'Something went wrong',
        description: 'Unable to send OTP, please try again later',
        placement: isMobile ? 'bottom' : 'topRight',
        duration: 1.5,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isModalVisible) setOtp('');
    setDeadline(Date.now() + 60000);
    setResendOTP(false);
  }, [isModalVisible]);
  return (
    <Modal
      title={null}
      centered
      width={300}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      styles={{ body: { borderRadius: '10px' } }}
    >
      <DataLoadingOverlay isLoading={isLoading} />
      <div className="mt-6">
        <h1 className="text-center font-bold text-lg">Enter OTP</h1>
        <div className="text-center bg-white p-2 mt-4 mb-4 rounded-lg">
          <input
            type="number"
            placeholder="OTP"
            className="w-full h-8 outline-none text-center"
            onChange={(e) => setOtp(e.target.value)}
          />
          {invalidOtp && <div className="text-left text-red-500 pt-1">Invalid OTP</div>}
        </div>
        <div className="flex justify-center">
          <button
            type="button"
            className="disabled:opacity-50 flex gap-2 !text-base items-center"
            disabled={!resendOTP}
            onClick={() => handleResendOTP()}
          >
            Resend OTP
            {!resendOTP ? (
              <div className="flex items-center gap-0.5">
                in (
                <Countdown
                  format="s"
                  valueStyle={{ fontSize: 14 }}
                  value={deadline}
                  onFinish={() => setResendOTP(true)}
                />
                Seconds)
              </div>
            ) : (
              ''
            )}
          </button>
        </div>
        <div className="flex justify-center my-4">
          <button
            type="button"
            disabled={otp.length < 6}
            onClick={handleOk}
            className="bg-colorPrimary text-center rounded-full w-[90%] py-2 text-textPrimary text-lg font-semibold disabled:opacity-50"
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
});
OtpModal.displayName = 'OtpModal';

export default OtpModal;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  CalendarDaysIcon,
  CalendarIcon,
  DocumentIcon,
  DocumentPlusIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../config/api/api.config';
import { TCurrentUser } from '../../common/types/common.type';
import sideBarApi from './side-bar.api';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type SideBarProps = {
  children: React.ReactNode;
};

const SideBar: FC<SideBarProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<TCurrentUser>();
  const navigation = [
    // {
    //   name: 'Dashboard',
    //   href: '/merchant/dashboard',
    //   icon: HomeIcon,
    //   current: location.pathname === '/merchant/dashboard',
    // },
    {
      name: 'Bookings',
      href: '/merchant/bookings',
      icon: DocumentIcon,
      current: location.pathname === '/merchant/bookings',
    },
    {
      name: 'Create Booking',
      href: '/merchant/create-booking',
      icon: DocumentPlusIcon,
      current: location.pathname === '/merchant/create-booking',
    },
    {
      name: 'Booking Calendar',
      href: '/merchant/booking-calendar',
      icon: CalendarIcon,
      current: location.pathname === '/merchant/booking-calendar',
    },
    {
      name: 'Resources',
      href: '/merchant/resource-listing',
      icon: UsersIcon,
      current: location.pathname === '/merchant/resource-listing',
    },
    {
      name: 'Booking Schedule',
      href: '/merchant/create-booking-schedule',
      icon: CalendarDaysIcon,
      current: location.pathname === '/merchant/create-booking-schedule',
    },
  ];

  const fetchCurrentUser = useQuery('fetchCurrentUser', () => sideBarApi.getCurrentUser(), {
    enabled: false,
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        setCurrentUser(data);
      }
    },
  });

  const setAuthentication = () => {
    const iBlinkMarketplaceUser = localStorage.getItem('iBlinkMarketplaceUser');
    // if ((JSON.parse(iBlinkMarketplaceUser)?.role || '') === 'merchant') navigate('/merchant/bookings');

    if (iBlinkMarketplaceUser) {
      api.setAuthenticationHeader({
        token: JSON.parse(iBlinkMarketplaceUser).token,
        id: JSON.parse(iBlinkMarketplaceUser).id,
        apiKey: JSON.parse(iBlinkMarketplaceUser).apiKey,
      });
      setIsLoggedIn(true);
    } else {
      navigate('/404');
    }
  };

  useEffect(() => {
    setAuthentication();
    fetchCurrentUser.refetch();
  }, []);

  return (
    <div className="merchant-default-theme">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-bgSidebar px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-8 w-auto" src="/logo.png" alt="iBlinkX" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                onClick={() => navigate(item.href)}
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-800 text-white'
                                    : 'text-gray-400 hover:text-white hover:bg-gray-200',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-bgSidebar px-6">
          <div className="flex h-16 shrink-0 items-center">
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-primary capitalize">
              {currentUser?.merchant.merchant_name_en}
            </h1>
            {/* <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
              alt="Your Company"
            /> */}
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        onClick={() => navigate(item.href)}
                        className={classNames(
                          item.current
                            ? 'bg-primary text-white hover:text-white'
                            : 'text-text hover:text-text hover:bg-gray-200',
                          'group flex items-center gap-x-3 rounded-md pl-12 p-3 text-sm leading-6 font-semibold',
                        )}
                      >
                        <item.icon className="h-4 w-4 shrink-0" aria-hidden="true" />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <a
                  href="#"
                  className="flex items-center gap-2 px-6 py-3 text-sm font-semibold leading-6 text-text hover:bg-gray-200 capitalize"
                >
                  <UserIcon className="h-8 w-8 font-thin" aria-hidden="true" />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">
                    {currentUser?.first_names_en} {currentUser?.last_name_en}
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-bgSidebar px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        <a href="#">
          <span className="sr-only">Your profile</span>
          <UserIcon className="h-8 w-8 font-thin" aria-hidden="true" />
        </a>
      </div>

      <main className="lg:pl-72">
        <div className="bg-bgMain h-[100vh]">{isLoggedIn && children}</div>
      </main>
    </div>
  );
};

export default SideBar;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CustomRadioGroup, ResourceTimeSlots } from './components';
import { TBookingSchedule, TMerchantResource } from '../merchant.type';
import createBookingScheduleApi from './create-booking-schedule.api';
import { DataLoadingOverlay } from '../../common/components';

type TResourceTimeSlot = {
  resource: {
    value: number;
    label: string | undefined;
  };
  days: { day: string; timeSlots: { min: number; max: number; color: string }[] }[];
};

const days = [
  { value: '0', label: 'Monday' },
  { value: '1', label: 'Tuesday' },
  { value: '2', label: 'Wednesday' },
  { value: '3', label: 'Thursday' },
  { value: '4', label: 'Friday' },
  { value: '5', label: 'Saturday' },
  { value: '6', label: 'Sunday' },
];

function convertTimeToDecimal(time: string): number {
  const [hours, minutes] = time.split(':').map(Number);
  return hours + minutes / 60;
}

/* eslint-disable jsx-a11y/label-has-associated-control */
const CreateBookingSchedule = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMerchantBookingSchedule, setSelectedMerchantBookingSchedule] = useState<number>();
  const [resourceWithTimeSlots, setResourceWithTimeSlots] = useState<TResourceTimeSlot[]>([]);
  const [merchantResources] = useState<TMerchantResource[]>([
    {
      merchant_resource_id: 0,
      parent_merchant_resource_id: null,
      parent_merchant_resource_name: null,
      merchant_resource_code: '',
      merchant_resource_name: 'All Resources',
      merchant_resource_description: '',
      merchant_resource_holding_time: 15,
      merchant_resource_provider_type: 'merchant',
      merchant_id: 1,
      merchant_name_en: '',
      merchant_resource_type_id: 0,
      merchant_resource_type_name: '',
      merchant_resource_status: 'active',
      status: 'active',
      modification_timestamp: '',
      insertion_timestamp: '',
      merchant_resource_image_list: [],
    },
  ]);
  const [selectedResource, setSelectedResource] = useState<
    {
      value: number;
      label: string | undefined;
    }[]
  >([]);
  const [bookingSchedule, setBookingSchedule] = useState<TBookingSchedule>();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    trigger,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      scheduleName: 'normal',
      bookingPrice: '',
      email: '',
      resource: [0],
      scheduleType: 'single',
      advancePaymentType: 'percent',
      advancePaymentAmount: '10',
      days: ['0', '1', '2', '3', '4'],
      sharedSchedule: false,
      requiresConfirmation: false,
      schedule: '0',
      bookingPriceCurrency: 'AED',
      advancePaymentCurrency: 'AED',
    },
  });

  // const advancePaymentType = watch('advancePaymentType');
  const sharedSchedule = watch('sharedSchedule');
  const schedule = watch('schedule');
  const selectedDays = watch('days');
  const selectedDaysOptions = days.filter((day) => watch('days').includes(day.value));
  const resources = watch('resource');

  // const merchantResourceOptions = merchantResources.map((resource) => ({
  //   value: resource.merchant_resource_id,
  //   label: resource.merchant_resource_name,
  // }));

  // const fetchMerchantResources = useQuery(
  //   'fetchMerchantResources',
  //   () => createBookingScheduleApi.getMerchantResources(),
  //   {
  //     enabled: false,
  //     onSuccess: ({ data, status }) => {
  //       if (status === 200 && data.result_list) {
  //         setMerchantResources(data.result_list);
  //       }
  //     },
  //   },
  // );

  const fetchMerchantBookingSchedule = useQuery(
    'fetchMerchantBookingSchedule',
    () => createBookingScheduleApi.getMerchantBookingSchedule(selectedMerchantBookingSchedule || 0),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200 && data.data) {
          setBookingSchedule(data.data);
          const timeSlotDays = [
            ...Array.from(Array(7)).map((_, index) => ({
              day: String(index),
              timeSlots: data.data.schedule_list
                .filter((daySchedule: any) => daySchedule.days.includes(String(index)))
                .map((daySchedule: any) => ({
                  min: convertTimeToDecimal(daySchedule.from_time),
                  max: convertTimeToDecimal(daySchedule.to_time),
                  color: 'bg-primary',
                })),
            })),
          ];

          const scheduleDays = timeSlotDays.filter((item) => item.timeSlots.length > 0);
          const isSharedSchedule = scheduleDays.every(
            (item) => JSON.stringify({ ...item, day: 0 }) === JSON.stringify({ ...timeSlotDays[0], day: 0 }),
          );

          if (isSharedSchedule) setValue('sharedSchedule', true);
          setValue(
            'days',
            scheduleDays.map((item) => item.day),
          );
          setValue('scheduleName', data.data.merchant_booking_schedule_name);

          trigger('scheduleName');

          setResourceWithTimeSlots([
            {
              resource: {
                value: 0,
                label: 'All Resources',
              },
              days: timeSlotDays,
            },
          ]);
        }
      },
    },
  );

  const fetchBookingSchedules = useQuery(
    'fetchBookingSchedules',
    () => createBookingScheduleApi.getBookingSchedules(),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200 && data.data?.length > 0) {
          setSelectedMerchantBookingSchedule(data.data[0].merchant_booking_schedule_id);
        }
      },
    },
  );

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (resourceWithTimeSlots) {
      const utcTimeZone = `UTC ${format(new Date(), 'xxx')}`;
      const transformedResources: any[] = [];
      resourceWithTimeSlots.forEach((resource) => {
        resource.days.forEach((day) => {
          day.timeSlots.forEach((timeSlot) => {
            const timeSlotMatches = transformedResources.find(
              (item) =>
                item.resource[0] === resource.resource && item.min === timeSlot.min && item.max === timeSlot.max,
            );
            if (timeSlotMatches) timeSlotMatches?.day.push(day.day);
            else
              transformedResources.push({
                resource: [resource.resource.value],
                day: [day.day],
                min: timeSlot.min,
                max: timeSlot.max,
              });
          });
        });
      });

      const scheduleList = transformedResources
        .reduce((acc, item) => {
          const resourceMatches = acc.find(
            (resource: { resource: any[]; day: any[]; min: any; max: any }) =>
              resource.day.join(',') === item.day.join(',') && resource.min === item.min && resource.max === item.max,
          );
          if (resourceMatches) {
            resourceMatches.resource.push(item.resource[0]);
          } else {
            acc.push({
              resource: item.resource,
              day: item.day,
              min: item.min,
              max: item.max,
            });
          }
          return acc;
        }, [])
        .map((item: { resource: any[]; day: string[]; min: number; max: number }) => ({
          merchant_resource_id_list: [], // ? item.resource
          merchant_booking_schedule_type: 'fixed',
          all_days_p: item.day.length === 7,
          all_hours_p: false,
          from_time: `${item.min}:00`,
          to_time: `${item.max}:00`,
          timezone: utcTimeZone,
          days: sharedSchedule === true ? selectedDays.join(',') : item.day.join(','),
        }));

      if (scheduleList.length === 0) {
        setIsLoading(false);
        return message.error('Please select at least one time slot');
      }

      const payload = {
        merchant_booking_schedule_name: data.scheduleName,
        schedule_start_date: null,
        schedule_end_date: null,
        auto_confirm_p: true,
        booking_amount: {
          booking_amount: 0,
          currency_id: 1,
        },
        advance_booking_amount: {
          advance_booking_amount: 0.0,
          advance_booking_type: 'percent',
          currency_id: 1,
        },
        schedule_list: sharedSchedule
          ? scheduleList.flatMap((item: any) =>
              item.days.split(',').map((aItem: any) => ({
                ...item,
                days: aItem,
              })),
            )
          : scheduleList,
      };

      if (bookingSchedule) {
        const newAdditions: any = payload.schedule_list.filter(
          (item: any) =>
            !bookingSchedule?.schedule_list.find(
              (sItem) =>
                sItem.days === item.days &&
                sItem.from_time === `${item.from_time}:00` &&
                sItem.to_time === `${item.to_time}:00`,
            ),
        );

        const deletedItems = bookingSchedule?.schedule_list.filter(
          (item: any) =>
            !payload.schedule_list.find(
              (sItem: any) =>
                sItem.days === item.days &&
                item.from_time === `${sItem.from_time}:00` &&
                item.to_time === `${sItem.to_time}:00`,
            ),
        );

        if (bookingSchedule.merchant_booking_schedule_name !== payload.merchant_booking_schedule_name) {
          const { data: updateScheduleData, status: updateScheduleStatus } =
            await createBookingScheduleApi.updateMerchantBookingSchedule({
              ...payload,
              merchant_booking_schedule_id: bookingSchedule.merchant_booking_schedule_id,
              schedule_list: undefined,
              merchant_resource_list: [],
            });
          if (updateScheduleStatus === 200 && updateScheduleData?.status === 'successful')
            message.success('Booking Schedule updated successfully', 1.5);
          else {
            message.error('Something went wrong. Please try again after sometime or contact support for help.');
            setIsLoading(false);
          }
        }

        if (newAdditions.length > 0) {
          const { data: addScheduleData, status: addScheduleStatus } =
            await createBookingScheduleApi.bulkAddBookingScheduleSchedules(
              bookingSchedule.merchant_booking_schedule_id,
              { merchant_booking_schedule_schedule_map_list: newAdditions },
            );
          if (addScheduleStatus === 200 && addScheduleData?.status === 'successful')
            message.success('Booking Schedule updated successfully', 1.5);
          else {
            message.error('Something went wrong. Please try again after sometime or contact support for help.');
            setIsLoading(false);
          }
        }

        if (deletedItems.length > 0) {
          const { data: deleteScheduleData, status: deleteScheduleStatus } =
            await createBookingScheduleApi.bulkDeleteBookingScheduleSchedules({
              merchant_booking_schedule_schedule_map_id_list: deletedItems.map(
                (item: any) => item.merchant_booking_schedule_schedule_map_id,
              ),
            });
          if (deleteScheduleStatus === 200 && deleteScheduleData?.status === 'successful')
            message.success('Booking Schedule updated successfully', 1.5);
          else {
            message.error('Something went wrong. Please try again after sometime or contact support for help.');
            setIsLoading(false);
          }
        }
        navigate('/merchant/bookings');
      } else {
        const { data: createBookingScheduleData, status } = await createBookingScheduleApi.createBookingSchedule(
          payload,
        );

        if (status === 200 && createBookingScheduleData?.status === 'successful')
          message.success('Booking Schedule created successfully', 1.5, () => {
            return navigate('/merchant/bookings');
          });
        else {
          message.error('Something went wrong. Please try again after sometime or contact support for help.');
          setIsLoading(false);
        }
      }
    }
    setIsLoading(false);
    return null;
  };

  useEffect(() => {
    // fetchMerchantResources.refetch();
    fetchBookingSchedules.refetch();
  }, []);

  useEffect(() => {
    if (selectedMerchantBookingSchedule) fetchMerchantBookingSchedule.refetch();
  }, [selectedMerchantBookingSchedule]);

  useEffect(() => {
    setValue('schedule', '0');
    if (sharedSchedule)
      setResourceWithTimeSlots((prev) => prev.map((resource) => ({ ...resource, days: [resource.days[0]] })));
    else
      setResourceWithTimeSlots((prev) =>
        prev.map((resource) => ({
          ...resource,
          days: selectedDays.map((day) => ({ day, timeSlots: resource.days[0] ? resource.days[0].timeSlots : [] })),
        })),
      );
  }, [sharedSchedule]);

  useEffect(() => {
    setSelectedResource(
      resources.map((resource) => ({
        value: resource,
        label: merchantResources.find((item) => item.merchant_resource_id === resource)?.merchant_resource_name,
      })),
    );
  }, [resources]);

  return (
    <div className="p-5 h-fill overflow-y-auto mx-auto">
      <DataLoadingOverlay
        isLoading={isLoading || fetchMerchantBookingSchedule.isFetching || fetchBookingSchedules.isFetching}
      />
      <form id="createBookingScheduleForm" className=" p-2 rounded h-fill">
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-2xl font-semibold leading-7 text-text">
              {bookingSchedule ? 'Modify' : 'Create'} Booking Schedule
            </h2>

            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* <div className="sm:col-span-3">
                <label htmlFor="scheduleName" className="block text-sm font-medium leading-6 text-text">
                  Schedule Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    {...register('scheduleName', {
                      required: 'Schedule name is required!',
                      minLength: {
                        value: 3,
                        message: 'Schedule name must be at least 3 characters long!',
                      },
                    })}
                    id="scheduleName"
                    className="block w-full rounded-md border-0 bg-white py-1.5 text-text shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div> */}

              {/* <div className="sm:col-span-4">
                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-text">Schedule Type</legend>
                  <div className="flex justify-between">
                    <div className="flex gap-4">
                      <div className="flex items-center gap-x-3">
                        <input
                          id="single"
                          {...register('scheduleType', { required: true })}
                          type="radio"
                          value="single"
                          className="h-4 w-4 border-white/10 bg-white/5 text-primaryDark focus:ring-primaryDark focus:ring-offset-gray-900"
                        />
                        <label htmlFor="single" className="block text-sm font-medium leading-6 text-text">
                          Date Range
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="repetitive"
                          {...register('scheduleType', { required: true })}
                          type="radio"
                          value="repetitive"
                          className="h-4 w-4 border-white/10 bg-white/5 text-primaryDark focus:ring-primaryDark focus:ring-offset-gray-900"
                        />
                        <label htmlFor="repetitive" className="block text-sm font-medium leading-6 text-text">
                          Repetitive
                        </label>
                      </div>
                    </div>
                    <fieldset className="flex">
                      <legend className="text-sm font-semibold leading-6 text-text flex gap-4">
                        <div className="relative flex gap-x-3">
                          <div className="flex h-6 items-center mt-2">
                            <input
                              id="requiresConfirmation"
                              {...register('requiresConfirmation')}
                              type="checkbox"
                              className="h-4 w-4 rounded border-white/10 bg-white/5 text-primaryDark focus:ring-primaryDark focus:ring-offset-gray-900"
                            />
                          </div>
                        </div>
                        <span className="mt-2">Booking Requires Confirmation</span>
                      </legend>
                    </fieldset>
                  </div>
                </fieldset>
              </div> */}

              {/* <div className="sm:col-span-4">
                <label htmlFor="resource" className="block text-sm font-medium leading-6 text-text">
                  Select Resource
                </label>
                <div className="mt-2">
                  <CustomSelect options={merchantResourceOptions} name="resource" control={control} defaultValue={[]} />
                </div>
              </div> */}

              {/* <div className="sm:col-span-4">
                <label htmlFor="bookingPrice" className="block text-sm font-medium leading-6 text-text">
                  Booking Price
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary">
                    <span className="flex select-none items-center text-gray-500 sm:text-sm">
                      <div className="">
                        <select
                          id="bookingPriceCurrency"
                          {...register('bookingPriceCurrency', { required: true })}
                          autoComplete="bookingPriceCurrency"
                          className="block w-full rounded-md border-0 bg-inherit active:ring-0 focus:ring-0 py-1.5 text-text shadow-sm sm:text-sm sm:leading-6 [&_*]:text-black"
                        >
                          <option>AED</option>
                          <option>SAR</option>
                          <option>USD</option>
                        </select>
                      </div>
                    </span>
                    <input
                      type="number"
                      id="bookingPrice"
                      {...register('bookingPrice', {
                        required: true,
                        valueAsNumber: true,
                        validate: (value) => Number(value) > 0,
                      })}
                      className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-text focus:ring-0 sm:text-sm sm:leading-6"
                      min={0}
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="sm:col-span-3 flex justify-between">
                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-text">Advance Payment</legend>
                  <div className="flex gap-4">
                    <div className="flex items-center gap-x-3">
                      <input
                        id="percent"
                        {...register('advancePaymentType', { required: true })}
                        type="radio"
                        value="percent"
                        className="h-4 w-4 border-white/10 bg-white/5 text-primaryDark focus:ring-primaryDark focus:ring-offset-gray-900"
                      />
                      <label htmlFor="percent" className="block text-sm font-medium leading-6 text-text">
                        Percentage
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <input
                        id="value"
                        {...register('advancePaymentType', { required: true })}
                        type="radio"
                        value="value"
                        className="h-4 w-4 border-white/10 bg-white/5 text-primaryDark focus:ring-primaryDark focus:ring-offset-gray-900"
                      />
                      <label htmlFor="value" className="block text-sm font-medium leading-6 text-text">
                        Value
                      </label>
                    </div>
                  </div>
                </fieldset>
                <div className="">
                  <label htmlFor="advancePaymentAmount" className="block text-sm font-medium leading-6 text-text">
                    Advance Payment Amount
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary">
                      {advancePaymentType === 'value' && (
                        <span className="flex select-none items-center text-gray-500 sm:text-sm">
                          <div className="">
                            <select
                              id="advancePaymentCurrency"
                              {...register('advancePaymentCurrency', { required: true })}
                              autoComplete="advancePaymentCurrency"
                              className="block w-full rounded-md border-0 bg-inherit active:ring-0 focus:ring-0 py-1.5 text-text shadow-sm sm:text-sm sm:leading-6 [&_*]:text-black"
                            >
                              <option>AED</option>
                              <option>SAR</option>
                              <option>USD</option>
                            </select>
                          </div>
                        </span>
                      )}
                      <input
                        type="number"
                        id="advancePaymentAmount"
                        {...register('advancePaymentAmount', {
                          required: true,
                          valueAsNumber: true,
                          validate: (value) => Number(value) > 0,
                        })}
                        className="flex-1 border-0 bg-transparent ml-2 py-1.5 pl-1 text-text focus:ring-0 sm:text-sm sm:leading-6"
                        min={0}
                      />
                      {advancePaymentType === 'percent' && (
                        <span className="flex select-none items-center pr-3 text-gray-500 sm:text-sm">%</span>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="sm:col-span-4">
                <label htmlFor="dateRange" className="block text-sm font-medium leading-6 text-text">
                  Date Range
                </label>
                <div className="mt-2">
                  <CustomDateRangePicker name="dateRange" control={control} />
                </div>
              </div> */}

              <fieldset className="sm:col-span-6">
                <legend className="text-sm font-semibold leading-6 text-text">Working Days</legend>
                <div className="flex gap-6">
                  {days.map((day) => (
                    <div
                      className="relative flex gap-x-3 cursor-pointer"
                      key={day.value}
                      onClick={() =>
                        setValue(
                          'days',
                          selectedDays.includes(day.value)
                            ? selectedDays.filter((selectedDay) => selectedDay !== day.value)
                            : [...selectedDays, day.value],
                        )
                      }
                    >
                      <div className="flex h-6 items-center">
                        <input
                          id="days"
                          {...register('days', { required: true })}
                          type="checkbox"
                          value={day.value}
                          className="h-4 w-4 rounded border-black/10 bg-bgSidebar text-primaryDark focus:ring-primaryDark cursor-pointer"
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor={day.value} className="font-medium text-text cursor-pointer">
                          {day.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>

              <fieldset className="sm:col-span-6 flex">
                <legend className="text-sm font-semibold leading-6 text-text flex gap-4">
                  <div
                    className="relative flex gap-x-3 cursor-pointer"
                    onClick={() => setValue('sharedSchedule', !sharedSchedule)}
                  >
                    <div className="flex h-6 items-center">
                      <input
                        id="sharedSchedule"
                        {...register('sharedSchedule')}
                        type="checkbox"
                        className="h-4 w-4 rounded border-black/10 bg-bgSidebar text-primaryDark focus:ring-primaryDark cursor-pointer"
                      />
                    </div>
                    <span>Shared Schedule For All Days</span>
                  </div>
                </legend>
              </fieldset>

              <div className="sm:col-span-6">
                <label htmlFor="schedule" className="block text-sm font-medium leading-6 text-text">
                  Schedule
                </label>
                {!sharedSchedule && (
                  <div className="mt-2">
                    <CustomRadioGroup options={selectedDaysOptions} name="schedule" control={control} />
                  </div>
                )}
              </div>
              {schedule && (
                <div className="sm:col-span-6">
                  <div className="w-full">
                    <ResourceTimeSlots
                      resources={selectedResource}
                      day={schedule}
                      availableDays={selectedDays}
                      resourceWithTimeSlots={resourceWithTimeSlots}
                      setResourceWithTimeSlots={setResourceWithTimeSlots}
                      showAutoFill={!sharedSchedule}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold leading-6 text-text" onClick={() => navigate(-1)}>
            Cancel
          </button>
          <button
            type="button"
            form="createBookingSchedule"
            className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-textLight shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || selectedResource.length === 0 || selectedDays.length === 0}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateBookingSchedule;
